<template>
    <div id="app">
        <ConfirmDialog />
        <Toast />
        <topDiv />
        <div class="container">
            <ContactUs ref="contactUs" @hide="$refs.contactUs.$el.style.visibility = 'hidden'" />
            <router-view />
            <Footer @contact_us="$refs.contactUs.$el.style.visibility = 'visible'" />
        </div>
    </div>
</template>

<script>
import ContactUs from './components/home_components/ContactUs.vue'
import ConfirmDialog from 'primevue/confirmdialog';

import { fb, analytics } from './main.js'

export default {
    name: 'App',
    components: {
        ContactUs,
        ConfirmDialog
    },
    created() {
        console.log("created APP")
        this.expandListener()
        fb.auth().onIdTokenChanged(user => {
            if (user) {
                const client = localStorage.getItem('client') ?? 'not logged in'
                const role = localStorage.getItem('role') ?? 'not logged in'
                analytics.setUserProperties({ client: client, role: role })
                analytics.setUserId(user.uid)
            }
        })
    }
}
</script>

<style lang="scss" scoped>
#app{
    height:max-content;
    font-family:'Montserrat', sans-serif;
}

.container{
    width:95%;
    max-width: 95%;
    margin:0 auto;
}
</style>