<template>
<div>
    <div class="background-cover" v-if="show">
        <div class="comp-container flex flex-col space-between gap-4">
            <h3 class="m-0 p-0">{{trans('delete_image')}}?</h3>
            <div class="flex space-between gap-4">
                <div @click="show = false; remove_image = ''" class="bg-gray-500 px-4 py-2 rounded-full text-white cursor-pointer">{{trans('cancel')}}</div>
                <div @click="removeImg()" class="bg-red px-4 py-2 rounded-full text-white cursor-pointer">{{trans('delete')}}</div>
            </div>
        </div>
    </div>
    <div class="installation">
        <h3>{{trans('Installation')}}</h3>
        <!-- before install -->
        <div class="comp-container pre-install flex d-col gap-1" v-if="!unit.install?.date && ['admin','craftsman'].includes(role)">
        <label>{{trans('mimbox_address')}}</label>
            <div class="flex d-col gap-05"><label for="phone">{{trans('address')}}</label><input type="text" name="phone" id="phone" v-model="install.address" ref="address" @input="removeRequired($event)"></div>
            <label class="install-partner">{{trans('installation_partner')}}</label>
            <div class="flex d-col gap-05"><label for="name">{{trans('name')}}</label><input type="text" name="name" id="name" v-model="install.name" ref="name" @input="removeRequired($event)"></div>
            <div class="flex d-col gap-05"><label for="phone">{{trans('phone_number')}}</label><input type="text" name="phone" id="phone" v-model="install.phone" ref="phone" @input="removeRequired($event)"></div>
            <div class="installation flex gap-05 items-center"><input v-model="install.installed" value="true" type="radio" name="installed" id="installed"><label id="installed" for="installed" ref="installed" @input="removeRequired($event)">{{trans('Installation completed')}}</label></div>
            <date-picker id="datepicker" v-model="install.date" placeholder="date" ref="date" @input="removeRequired($event)"></date-picker>
            <button 
            @click="installUnit"
            class="btn save">{{trans('save')}}</button> <!-- Set mimbox to installed -->
        </div>
        <!-- after install-->
        <div class="comp-container post-install" v-else>
            <div class="flex justify-between">
                <div class="section flex items-center gap-4">
                    <span class="header">{{trans('installation_done')}}:</span>
                        <svg width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="8" :fill="this.install.installed ? 'var(--green)' : 'var(--red)'"/>
                        </svg>
                    <span class="date">{{unit.install ? displayDate(unit.install.date, 'date') : ""}}</span> <!-- installation date -->
                </div>
                <div v-if="['admin','craftsman'].includes(role)">
                    <InputSwitch v-model="install.installed" @change="handleToggleInstall" />
                </div>
            </div>
            <div class="mb-4 flex gap-4">
                <span class="header">{{trans('address')}}:</span>
                <span>{{unit.address ? unit.address : ""}}</span> <!-- address -->
            </div>
            <div class="section flex items-center gap-4">
                <span class="header">{{trans('site_photos')}}:</span>
                <!-- Upload -->
                <button class="btn flex items-center gap-05">
                    <input 
                        @change="uploadImageFunc($event)"
                        type="file" name="img" id="img" accept=".jpeg,.jpg,.png">
                    <div class="icon_text">
                        <div class="icon upload h-5 w-5"></div><span class="upload_text">{{trans('upload')}}</span>
                    </div>
                </button>
            </div>
            <!-- Image list -->
            <div class="section images">
                    <img class="placeholder-image" v-if="!unit.images" src="../../assets/no-photos.png" alt="no images to show">
                    <div v-else id="fullscreen-wrapper" v-for="url in unit.images" :key="url" class="mb-4" @mouseover="removeImageButton('remove_image' + url, true)" @mouseleave="removeImageButton('remove_image' + url, false)">
                        <div class="fs-close" @click="toggle($event)" v-if="fullscreen"></div>
                        <div class="flex flex-col relative">
                            <div @click="show = true" class="remove-icon absolute cursor-pointer opacity-zero" :ref="`remove_image${url}`" v-if="!fullscreen && role == 'admin'" />
                            <img @click="toggle($event)" :class="fullscreen ? 'w-full h-full m-auto' : ''" :src="url" alt="image" class=" w-48">
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { api as fullscreen } from 'vue-fullscreen'
import InputSwitch from 'primevue/inputswitch';

export default {
    name: 'Installation',
    props: ['unit', 'role'],
    components: {
        InputSwitch
    },
    data() {
        return {
            show: false,
            removeURL: '',
            install: {
                address: '',
                date: undefined,
                name: undefined,
                installed: undefined,
                phone: undefined
            },
            fullscreen: false,
            teleport: true,
        }
    },
    watch: {
        show(){
            if(this.show) document.querySelector('body').classList.add('overflow-hidden')
            else document.querySelector('body').classList.remove('overflow-hidden')
        }
    },
    methods: {
        async toggle (e) {
            await fullscreen.toggle(e.target.closest('#fullscreen-wrapper'))
            this.fullscreen = !this.fullscreen
        },
        removeRequired(event){
            if(!event) return
            if(event.toString() === '[object InputEvent]') event.target.classList.remove('red')
            else this.$refs.date.$el.classList.remove('red')
        },
        async uploadImageFunc(e){
            var files = e.target.files
            var image = files[0]
            let n = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
            let url =  await this.uploadImage(this.unit.id, n, image)
            let images = this.unit.images || []
            images.push(url)
            await this.updateUnit(this.unit.id, {images: images});
        },
        async removeImg(){
            this.show = false
            let images = this.unit.images
            images.splice(images.indexOf(this.remove_image), 1)
            await this.updateUnit(this.unit.id, {images: images});
            this.remove_image = ''
        },
        async installUnit(){
            var filled = true
            Object.keys(this.install).forEach((e) => {
                if(this.install[e] == undefined || this.install[e] == '') {
                    filled = false
                    
                    if(this.$refs[e].classList) { this.$refs[e].classList.add('red'); return }
                    if(this.$refs[e].$el.classList) { this.$refs[e].$el.classList.add('red'); return }
                    return
                }
            })
            if (!filled) return
            await this.updateUnit(this.unit.id, {status:"installed", install: { ...this.install, installed: true }, address: this.install.address});
            this.install.installed = true
        },
        removeImageButton(ref, show){
            if(!this.$refs[ref]) return
            show ? this.$refs[ref][0].classList.remove('opacity-zero') : this.$refs[ref][0].classList.add('opacity-zero')
        },
        handleToggleInstall() {
            this.updateUnit(this.unit.id, { install: { installed: this.install.installed }, status: this.install.installed ? 'installed' : 'deinstalled' });
        }
    },
    mounted(){
        this.install.date = new Date()
        this.install.installed = this.unit?.install?.installed
    }
}
</script>

<style>
.overflow-hidden {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.opacity-zero {
    opacity: 0;
}

.remove-icon {
    width:30px;
    height:30px;
    background-image:url('../../assets/remove-filled.svg');
    background-size: cover;
    border-radius: var(--border-radius);
    transition: 250ms ease-in-out all;
}

.installation {
    text-align: left;
    padding-top:1rem;
}
.comp-container {
    padding: {
        top: 30px;
        bottom: 30px;
        left: 20px;
        right: 20px;
    }
    height:max-content;
    box-sizing:border-box;
    position: relative;

    overflow-x:hidden;
    overflow-y:auto;
}

.error-note {
    margin-top:0;
}

.pre-install {
    label {
        font-weight:700;
    }
    input[type="text"] {
        width:100%;
        text-transform:none
    }
    input[type="radio"] {
        margin:0;
        transform: scale(1.2);
        cursor:pointer;
    }
    h3 {
        margin-top:1rem;
        margin-bottom:.5rem;
    }
    h3:first-of-type {
        margin-top:0;
    }
}

#installed {
    font-weight: inherit;
    color: var(--blue);
    cursor:pointer;
}

#datepicker {
    background-color: var(--lightgray)
}

.post-install {
    height:300px;
    ::v-deep .simplebar-content {
        overflow-x: hidden;
    }
    ::v-deep .simplebar-track.simplebar-horizontal {
        display: none;
    }
}

.header {
    font-weight:700;
    color: var(--black);
}

.images {
    line-height: 0;       
    -webkit-column-count: 3;    
    -webkit-column-gap: 1rem;
    -webkit-row-gap:1rem;    
    -moz-column-count: 3;
    -moz-column-gap: 1rem;
    -moz-row-gap: 1rem;
    row-gap: 1rem;
    column-count: 3;    
    column-gap: 1rem;    
    img {
        cursor:pointer;
        max-width:100%,;
        width: 100% !important;    
        height: auto !important;
        object-fit:contain;
        border-radius: var(--border-radius);
        overflow:hidden;
    }
}

.placeholder-image {
    width:120px;
    aspect-ratio: 1;
}

#fullscreen-wrapper {
    display: flex;
    justify-content: center;
}

.fs-close {
    width:20px;
    height:20px;
    position:absolute;
    right:2rem;
    top:2rem;
    mask-image: url('../../assets/cross.svg');
    mask-size:cover;
    background-color:white;
    cursor:pointer;
}

button {
    box-sizing: border-box;
    background:var(--lightblue);
    padding:.25rem;
    width:170px;
    border-radius:2rem;
    color: var(--white);
    font-weight:400;
    text-transform: capitalize;
    height:32px;
    position:relative;
    input {
        position:absolute;
        left:0;
        right:0;
        width:100%;
        top:0;
        bottom:0;
    }
}

.save {
    width: 120px;
}

input[type="file"] {
    opacity: 0;
    cursor: pointer;
}

.icon_text {
    width:100%;
    height:100%;
    box-sizing:border-box;
    padding-left:20px;
    padding-right:20px;
    display:flex;
    justify-content: space-between;
    align-items:center;
    div, span {
        display:block;
    }
}

/* DROPDOWN STYLES */

.filter {
    cursor:pointer;
}

.filter.active {
    max-height:max-content;
}

.label, .icon {
    pointer-events:none;
    text-transform: none;
}
.label {
    padding-left:.5rem;
    position:relative;
    width:300px;
}

.items {
    transform:translateY(-1rem);
    transform-origin: top;
    pointer-events:none;
    transition: max-height 200ms linear;
    max-height:0;
}

.items.active {
    transform:translateY(-1rem);
    max-height:15rem;
}

.items.active filter {
    color:red;
}

.item:first-of-type {
    padding-top:1.5rem;
}
.item{
    color: var(--blue);
    position:relative;
    width: 300px;
    box-sizing:border-box;
    height:max-content;
    overflow:hidden;
    padding:1rem 1rem;
    border: {
        left: calc(var(--border-width)/2) solid var(--blue);
        right: calc(var(--border-width)/2) solid var(--blue);
        bottom: calc(var(--border-width)/2) solid var(--blue);
    }
}

.item:last-of-type{
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.items {
    overflow:hidden;
    position:relative;
}

.items.active{
    height:auto;
}

.list-item:first-of-type {
    margin-top:.5rem;
}

.section {
    margin-bottom:20px;
}

/* Inner text */
.li-span {
    color: var(--black);
    font-weight:700;
}

.phone-email > span {
    color: inherit;
}

/* ########## ICONS ########### */
.icon {
    position:absolute;
    right:0rem;
}

.phone {
    position:relative;
    left:0;
    margin-right:1rem;
    background-image: url('../../assets/phone.svg')
}

.edit {
    background-image: url('../../assets/edit-mimbox.svg');
    right:1rem;
}

.download {
    position:absolute;
    left:1rem;
    background-color: var(--white);
    mask-image: url('../../assets/download.svg');
    mask-repeat: no-repeat;
}

.upload {
    position: relative;
}

.upload {
    background-color: var(--white);
    mask-repeat: no-repeat;
    mask-image: url('../../assets/upload.svg');
}

.install-partner {
    margin-top:1rem;
}
</style>