<template>
	<div class="home-view">
		<AdjustCalculation v-if="!loading_prices" ref="adjustCalculation" class="adjust-calculation" @adjustCalculation="calculation($event)" @hide="$refs.adjustCalculation.$el.style.visibility = 'hidden'"/>
		<!-- desktop -->
        <show-at breakpoint="mediumAndAbove">

		<div class="desktop-container">
			<unitFilter v-if="['admin','craftsman','customer'].includes(role)" :possibleFilter="possibleFilters()" @filtered="setFilterValue"></unitFilter>
			<!-- Admin and craftsman -->
			<div class="grid" v-if="['admin','craftsman'].includes(role)">
				<div class="row-one">
					<unitList :role="role" :filter="unitFilter" :possibleUnits="units" @filterd="unitListHasFiltered" class="filter" />
					<div v-if="client == 'mimbly'" class="w-full flex justify-center mt-4 flex-col items-center gap-2">
						<router-link class="admin-link hover:bg-blue-500 transition duration-200 ease-in-out" :to="'/admin/stats'">Beta admin page</router-link>
					</div>
				</div>
				<div class="row-two">
					<unitSavings @filterTime="filterTime" :client="client" :role="role" v-if="!loading_prices" ref="unitSavings" :selectedUnits="filteredUnits" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'" @showSavings="showSavings = !showSavings" />
				</div>
				<div class="row-three">
					<allNotifications v-if="role == 'admin'" :filtered_units="filteredUnits"/>
					<getSupport v-if="role == 'craftsman'"/>
					<addNew v-if="role == 'admin'" :possible_companies="possibleClients"  :possible_client_names="possibleClients" :possible_user_types="this.possibleUserTypes" />
					<!-- <Performance :perfUnits="filteredUnits"/> -->
				</div>
			</div>
			<!-- customer -->
			<div class="grid" v-if="role == 'customer'">
				<div class="row-one">
					<unitList :role="role" :filter="unitFilter" :possibleUnits="units" @filterd="unitListHasFiltered" class="filter"></unitList>
				</div>
				<div class="row-two">
					<unitSavings @filterTime="filterTime" :client="client" :role="role" v-if="!loading_prices" ref="unitSavings" :selectedUnits="filteredUnits" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'" @showSavings="showSavings = !showSavings"></unitSavings>
				</div>
				<div class="row-three customer">
					<getSupport />
					<!-- <achievements :saved_data="total_saved_data" /> -->
				</div>
			</div>
			<!-- Manufacturer -->
			<div class="grid-center" v-else-if="role == 'manufacturer'">
				<simpleAddNew/>
			</div>
		</div>
		</show-at>

		<!-- mobile -->
		<hide-at breakpoint="mediumAndAbove">
			<div class="mobile-container">
				<div class="admin flex d-col gap-1" v-if="role == 'admin'">
					<MobileFilter v-if="['admin','craftsman','customer'].includes(role)" :possibleFilter="possibleFilters()" @filtered="setFilterValue"></MobileFilter>
					<unitList :role="role" :filter="unitFilter" :possibleUnits="units" @filterd="unitListHasFiltered" class="filter" />
					<addNew :possible_companies="possibleClients"  :possible_client_names="possibleClients" :possible_user_types="this.possibleUserTypes" />
					<unitSavings @filterTime="filterTime" :client="client" v-if="!loading_prices" ref="unitSavings" :selectedUnits="filteredUnits" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'"/>
					<!-- <Performance :chartUnits="filteredUnits" :hidden="showSavings" v-if="showSavings == false"/> -->
				</div>
				<div class="craftsman flex d-col gap-1" v-if="role == 'craftsman'">
					<MobileFilter v-if="['admin','craftsman','customer'].includes(role)" :possibleFilter="possibleFilters()" @filtered="setFilterValue"></MobileFilter>
					<unitList :role="role" :filter="unitFilter" :possibleUnits="units" @filterd="unitListHasFiltered" class="filter" />
					<unitSavings @filterTime="filterTime" :client="client" v-if="!loading_prices" ref="unitSavings" :selectedUnits="filteredUnits" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'"/>
					<getSupport />
				</div>
				<div class="customer flex d-col gap-1" v-if="role == 'customer'">
					<MobileFilter v-if="['admin','craftsman','customer'].includes(role)" :possibleFilter="possibleFilters()" @filtered="setFilterValue"></MobileFilter>
					<unitList :role="role" :filter="unitFilter" :possibleUnits="units" @filterd="unitListHasFiltered" class="filter" />
					<unitSavings @filterTime="filterTime" :client="client" v-if="!loading_prices" ref="unitSavings" :selectedUnits="filteredUnits" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'"/>
					<getSupport />
					<achievements :saved_data="total_saved_data"/>
				</div>
                <div class="manufacturer" v-else-if="role == 'manufacturer'">
                    <simpleAddNew/>
                </div>
			</div>
		</hide-at>
	</div>
</template>

<style lang="scss" scoped>

.unitFilter {
	width:100%;
	height:5rem;
	display:flex;
	position:relative;
	z-index:10;
}

.adjust-calculation {
	visibility: hidden;
}

.home-view {
	position:relative;
}

.grid {
	display:grid;
	grid-template-areas:
	'one space two gap three';
	grid-template-columns:minmax(300px, auto) minmax(1rem, 1fr) minmax(1.6rem, 1000px) minmax(1rem, 1fr) 1fr;
	justify-content: space-between;
	@media screen and (max-width: 1200px) {
        grid-template-columns:.5fr 1.6fr .5fr;
    }
	height:max-content;
	.row-one{
		grid-area: one;
		margin-top:2rem;
		max-width:400px;
	}
	.row-two{
		grid-area:two;
		margin-top:2rem;
	}
	.row-three{
		grid-area:three;
		margin-top:1rem;
		display:flex;
		flex-direction: column;
		gap:1rem;
		width:375px;
	}

	.row-three.customer {
		gap:30px;
		margin-top: 2rem !important;
	}
}

.grid-center {
	margin-top:3rem;
}

.manufacturer {
	padding-top:2rem;
}

.admin-link {
	padding: 1rem;
	background: var(--blue);
	border-radius: .5rem;
	color: var(--white)
}

</style>

<script>
import getSupport from '../components/getSupport.vue'
import AdjustCalculation from '../components/adjustCalculation.vue'
import simpleAddNew from '../components/home_components/simpleAddNew.vue'
import MobileFilter from '../components/mobile_specific/MobileFilter.vue'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default
{
  components: { getSupport, AdjustCalculation, simpleAddNew, MobileFilter },
	name: 'Home',
	data()
	{
		return {
			loading_prices: true,
			headerKeys: ["mimbox_id","address", "last_alive"],
			prevRoute: "null",
			// sort_on: false,
			role: false,
			client: undefined,
            unitFilter: {},
            filteredUnits: [],
            show_calculation: false,
			showSavings: false,
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from.name
		})
	},
	computed: {
		headerKeyValues() {
			if(this.units) {
				return this.units;
			} 
			else {
				return null
			}
		},
	},
	methods:
	{
        possibleFilters(){
			let possibleFilterArr = []
			if(['admin','craftsman'].includes(this.role)) possibleFilterArr = ["company","tags","address","technician","status"]
			if(this.role == 'customer') possibleFilterArr = ["address"]
            let fil = {}
            // IF YOU UN COMMENT THIS, HTE FILTER OPTIONS WILL CHANGE ON YOUR INPUT
            // for(let uu of this.filteredUnits ? this.filteredUnits : this.units){
            for(let uu of this.units){
                for(let f of possibleFilterArr){
                    let u = uu.data
                    if(!u[f])
                        continue;
                    if(fil[f])
                        fil[f].push(u[f])
                    else{
                        fil[f] = [u[f]]
                    }
                    fil[f] = fil[f].flat()
                }
            }
            let re = []
            for(let f of possibleFilterArr){
                let op = []
                if(fil[f])
                    op = [...new Set(fil[f])]
                re.push({key:f,options:op})
            }
            return re
        },
        unitListHasFiltered(event){
            this.filteredUnits = event
        },
        setFilterValue(event){
            this.unitFilter = event
        },
		async calculation({water_price, energy_price}) {
			water_price = parseFloat(water_price)
			energy_price = parseFloat(energy_price)
			await this.adjust_company_calculation({ water_price, energy_price })
			this.$refs.unitSavings.filter()
		},
		async getMimboxPerformanceData(){
			try {
				const IDTOKEN = await firebase.auth().currentUser.getIdTokenResult()
                const response = await fetch(`http://127.0.0.1:8080/list`,{
                    method: 'GET',
                    contentType: 'application/json',
                    headers: {
                        'Authorization': IDTOKEN.token
                    }}
                )
				const data = await response.json()
				if(data == "Not allowed") return
                localStorage.setItem("perf_data", JSON.stringify(data))
            }
            catch(e){
                console.log(e)
            }
		},
		async filterTime(range){
			if(range === null) {
				this.filteredUnits.map(unit => {
					unit.data.savings_for_range = null;
				})
				this.$refs.unitSavings.filter()
				return
			}
			const unitswWithSavings = await this.getUnitSavingsForTime(range);
			this.filteredUnits.map(unit => {
				const unitWithSavings = unitswWithSavings.find(u => u.id == unit.id);
				const water_saved = unitWithSavings?.data?.water_saved ? unitWithSavings?.data?.water_saved : 0;
				const water_used = unitWithSavings?.data?.water_used ? unitWithSavings?.data?.water_used : 0;
				if(unitWithSavings) {
					unit.data.savings_for_range = {water_saved, water_used};
				}
			})
			this.$refs.unitSavings.filter()
		}
	},
	async mounted()	{
		if(this.prevRoute == "login") await new Promise(res => setTimeout(res, 1000)) //waits a bit for data catch up on phones

        this.metaInfo("Home", this.$route.path)

		this.role = localStorage.getItem("role")
		this.client = localStorage.getItem("client")
		// reload page if client is girbau
		if(this.prevRoute == "login" && this.client === 'Girbau') window.location.reload()
		await this.get_company_prices()
		this.loading_prices = false
		this.requestUnits()
		this.requestAllPossibleCompanies()
		// this.requestTech()
		this.requestCompanies()
		this.requestErrorCodeList()
	},
	beforeDestroy(){
		this.unsubscribeAll();
	}
}
</script>