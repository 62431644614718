<template>
    <div class="a w-full h-full flex flex-col">
        <AdminSidebar :visibleLeft.sync="visibleLeft" @hide="visibleLeft = !visibleLeft" />
        <OverlayPanel ref="op" class="flex flex-col !rounded-mimbly overflow-hidden">
            <Divider>
                Emails
            </Divider>
            <button @click="() => sendReminder('service', selected.muid)" :class="`hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center flex-row gap-1 ${this.selected.service ? 'opacity-100' : 'opacity-25'}`">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.349 1.5L11.162 4.75603M7.26172 8.74074L9 6.96483" stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.9997 6H9.89844L13.8249 11.9174V16L16.1267 14.9918V11.9174L19.9997 6Z" fill="#4A4A4A"/>
                    <path d="M13.1928 5L14.5396 1L1 5.92593L7.09283 8.74074L9.80075 15.0741L11.4968 10.037" stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>         
                <span>filter bag expired</span>
            </button>
            <button @click="() => sendReminder('maintenance', selected.muid)" :class="`hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center flex-row gap-1 ${this.selected.maintenance ? 'opacity-100' : 'opacity-25'}`">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.0728 1L7.33203 8.85717" stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.0889 3.85715L15.0741 1L1 6.00002L7.33333 8.85717L10.1481 15.2858L10.8519 13.1429" stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.8492 9.8427L19.9537 14.0088C20.0449 14.194 20.0632 14.7309 19.4065 15.3975C18.7497 16.0641 18.221 16.0456 18.0386 15.953L13.9341 11.7869C13.2044 11.972 11.4714 12.0646 10.3768 10.9536C9.28229 9.84265 9.3735 8.45393 9.55593 7.89844L11.4713 9.5649C11.7449 9.6575 12.4561 9.67604 13.1129 9.00942C13.7696 8.34279 13.7514 7.62068 13.6601 7.34295L12.0183 5.3988C12.5656 5.21364 13.9338 5.12106 15.0283 6.23203C16.1228 7.343 16.0316 9.10205 15.8492 9.8427Z" fill="#4A4A4A"/>
                </svg>
                <span>maintenance</span>
            </button>
            <Divider>
                Data
            </Divider>
            <button @click="download_recycling_rate_graphs(selected.muid)" class="hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center gap-1">
                <i class="pi pi-chart-bar"></i>
                <span>recycling rate graphs</span>
            </button>
            <button @click="download_all_notifications(selected.muid)" class="hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center gap-1"><i class="pi pi-list"></i><span>error log</span></button>
            <a :href="`/#/unit/${selected.muid}`" target="_blank"><button class="hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center gap-1"><i class="pi pi-external-link"></i><span>go to mimbox</span></button></a>
            <Divider>
                Actions
            </Divider>
            <button @click="() => remoteCommand('calibrate', { name: selected.name, muid: selected.muid})" class="hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center gap-1"><i class="pi pi-sync"></i><span>Calibrate</span></button>
            <button @click="() => remoteCommand('drain', { name: selected.name, muid: selected.muid})" class="hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center gap-1"><i class="pi pi-eject"></i><span>Drain</span></button>
            <button @click="() => remoteCommand('restart', { name: selected.name, muid: selected.muid})" class="hover:bg-lightGray transition-all ease-in-out duration-100 w-full py-2 px-4 flex items-center gap-1"><i class="pi pi-power-off"></i><span>Restart</span></button>
        </OverlayPanel>
        <Dialog dismissableMask header="Error codes" modal :visible.sync="codes_expalined_modal">
            <TabView>
                <TabPanel header="Error codes">
                    <DataTable :value="error_codes_explained" :scrollable="true" scrollHeight="600px">
                        <Column field="code" header="Code">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.code }}</span>
                            </template>
                        </Column>
                        <Column field="label" header="Label">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.label }}</span>
                            </template>
                        </Column>
                        <Column field="description" header="Description">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.description }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </TabPanel>
                <TabPanel header="Error Graphs">
                    <ScrollPanel style="width: 100%; height: 700px">
                        <div class="flex flex-col gap-2 items-center">
                            <span style="width: 700px" class="my-2">
                                The two graphs at the top are split into "last codes in time period" and "total codes for time period". If you're interested in what the most common "most recent" error code is for all the mimboxes that had errors in the time period, you look at the "last codes in time period graph". If you're more interested in the total amount of codes, i.e how many times a specific error code has occured in the time period over all mimboxes, you look at the "total codes for time period" graph.
                            </span>
                            <h2>"Last" codes in time period</h2>
                            <img :src="last_codes_graph_explained" />
                            <h2>Total codes for time period</h2>
                            <img :src="total_codes_explained" />
                        </div>
                    </ScrollPanel>
                </TabPanel>
                <TabPanel header="Recycling rate graphs">
                    <ScrollPanel style="width: 100%; height: 700px">
                        <div class="flex flex-col gap-2 items-center">
                            <span style="width: 700px" class="my-2">
                                For every mimbox in the list you can download a compound graph with plots for visualizing the recycling rate over time. This is meant to provide you with insights into how the mimbox is performing which is more usable than a simple all-time recycling rate percentage. <span class="font-bold">Keep in mind that generating this graph is an expensive operation for the database.</span>
                            </span>
                            <img :src="recycling_rate_graphs_explained" />
                        </div>
                    </ScrollPanel>
                </TabPanel>
            </TabView>
        </Dialog>
        <div class="h flex flex-col bg-gray-100 overflow-hidden rounded-t-lg">
            <div class="w-full flex flex-row px-4 pt-4 gap-6">
                <Button class="e w-12 h-12 flex justify-center items-center" @click="visibleLeft = !visibleLeft">
                    <i class="pi pi-bars text-3xl text-white"></i>
                </Button>
                <Button style="padding: 0 !important;" class="e rounded bg-primary text-white w-12 h-12 flex justify-center items-center" @click="codes_expalined_modal = !codes_expalined_modal">
                    <i class="pi pi-question-circle text-3xl text-white"></i>
                </Button>
                <Button style="padding: 0 !important;" class="e rounded bg-primary text-white w-12 h-12 flex justify-center items-center" @click="run()">
                    <div v-if="loading" class="w-6 h-6 flex items-center justify-center">
                        <Loading color="#FFFFFF" />
                    </div>
                    <i v-else class="pi pi-refresh text-3xl text-white"></i>
                </Button>
            </div>
            <General 
                :time="time"
                :error_codes_list="error_codes"
                :information="information"
                :errors_listed="errors_listed"
                @sortOn="/* sortOn('error_code', $event)*/"
                :loading="loading"
            />
            <DataTable 
                class="p-datatable-sm"
                :loading="loading"
                ref="dt" :value="mimboxes" showGridlines paginator :rows="25" :expandedRows.sync="expandedRows" :filters.sync="filters" sortField="general.active_errors" :sortOrder="-1">
                <template #header>
                    <div class="flex justify-between w-full">
                        <div class="flex gap-2">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter()"/>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                            </span>
                            <MultiSelect :value="selectedColumns" :options="columns" optionLabel="header" @input="e => onToggle(e)"
                            placeholder="Select Columns" style="width: 20em"/>
                        </div>
                        <div class="flex items-center px-4 py-2 border-2 border-solid border-primary text-primary rounded-mimbly hover:bg-primary hover:text-white duration-200 cursor-pointer" @click="exportCSV()">Export</div>
                    </div>
                </template>
                <Column :expander="true" :headerStyle="{'width': '3rem'}"></Column>
                <Column field="buttons">
                    <template #body="slotProps">
                        <div class="flex justify-center">
                            <i class="pi pi-bars cursor-pointer bg-no-repeat bg-contain bg-center w-5 h-5" @click="(e) => show(e, getMimboxInfo(slotProps.data.general))"></i>
                        </div>
                    </template>
                </Column>
                <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index" sortable>
                    <template #header>
                        <div v-if="col.field == 'general.active_errors'">
                            <div v-tooltip.top="tooltips[col.field]" class="error-icon bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5" />
                        </div>
                        <div v-else-if="col.field == 'errors.error_history'">
                            <div v-tooltip.top="tooltips[col.field]" class="bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5 items-center">
                                <i class="pi pi-clock"></i>
                            </div>
                        </div>
                        <div v-else-if="col.field == 'general.water_processed_since_service'">
                            <div v-tooltip.top="tooltips[col.field]" class="bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5 items-center">
                                <i class="pi pi-clock"></i>
                            </div>
                        </div>
                        <div v-else-if="col.field == 'general.days_since_service'">
                            <div v-tooltip.top="tooltips[col.field]" class="bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5 items-center">
                                <i class="pi pi-clock"></i>
                            </div>
                        </div>
                        <div v-else-if="col.field == 'general.total_water_processed'">
                            <div v-tooltip.top="tooltips[col.field]" class="water-icon bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5" />
                        </div>
                        <div v-else-if="col.field == 'general.is_alive'">
                            <div v-tooltip.top="tooltips[col.field]" class="alive-icon bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5" />
                        </div>
                        <div v-else>
                            <div v-tooltip.top="tooltips[col.field]" class="bg-no-repeat bg-contain flex justify-center bg-center w-5 h-5 items-center">
                                <i class="pi pi-info-circle"></i>
                            </div>
                        </div>
                    </template>
                    <template #body="slotProps">
                        <div v-if="col.field == 'general.active_errors'">
                            <div class="h-8 flex justify-center items-center rounded-mimbly text-white" :class="slotProps.data.general.active_errors.length > 0 ? slotProps.data.general.active_errors.every(code => code === 17) ? 'bg-orange-400' : 'bg-darkRed' : 'bg-transparent'">
                                {{slotProps.data.general.active_errors}}
                            </div>
                        </div>
                        <div v-else-if="col.field == 'general.standby'">
                            <div v-if="slotProps.data.general.standby" class="w-8 h-8 rounded-full flex justify-center items-center bg-darkRed text-white">
                                <i class="pi pi-exclamation-triangle text-white" />
                            </div>
                        </div>
                        <div v-else-if="col.field == 'errors.error_history'">
                            {{ slotProps.data.errors.error_history?.length }}
                        </div>
                        <div v-else-if="col.field == 'errors.last_error_timestamp'">
                            {{ slotProps.data?.errors?.last_error_timestamp ? displayDate(slotProps.data.errors.last_error_timestamp) : '' }}
                        </div>
                        <div v-else-if="col.field == 'errors.general_error_info.average_days_between_errors'">
                            {{  slotProps.data?.errors?.general_error_info?.average_days_between_errors ? slotProps.data?.errors?.general_error_info?.average_days_between_errors + ' days' : '' }}
                        </div>
                        <div v-else-if="col.field == 'general.installed_timestamp'">
                            {{ displayDate(slotProps.data.general.installed_timestamp, "medium") }}
                        </div>
                        <div v-else-if="col.field == 'general.last_service_reminder'">
                            {{ displayDate(slotProps.data.general.last_service_reminder, "medium") }}
                        </div>
                        <div v-else-if="col.field == 'general.last_maintenance_reminder'">
                            {{ displayDate(slotProps.data.general.last_maintenance_reminder, "medium") }}
                        </div>
                        <div v-else-if="col.field == 'general.most_recent_maintenance'">
                            {{ displayDate(slotProps.data.general.most_recent_maintenance, "medium") }}
                        </div>
                        <div v-else-if="col.field == 'general.most_recent_service'">
                            {{ displayDate(slotProps.data.general.most_recent_service, "medium") }}
                        </div>
                        <div v-else-if="col.field == 'general.days_since_service'">
                            {{ slotProps.data.general.days_since_service ? slotProps.data.general.days_since_service + ' days' : '' }}
                        </div>
                        <div v-else-if="col.field == 'general.water_processed_since_service'">
                            {{slotProps.data.general.water_processed_since_service ? Math.round(slotProps.data.general.water_processed_since_service).toLocaleString() + ' l' : ''}}
                        </div>
                        <div v-else-if="col.field == 'general.water_saved'">
                            {{slotProps.data.general.water_saved ? slotProps.data.general.water_saved.toLocaleString() + ' l' : ''}}
                        </div>
                        <div v-else-if="col.field == 'general.total_water_processed'">
                            {{slotProps.data.general.total_water_processed.toLocaleString()}} l
                        </div>
                        <div v-else-if="col.field == 'general.recycling_rate'">
                            <span :class="slotProps.data.general.recycling_rate * 100 > 30 ? 'text-green-500' : 'text-darkRed'">{{ (slotProps.data.general.recycling_rate * 100).toFixed(2) }}%</span>
                        </div>
                        <div v-else-if="col.field == 'general.is_alive'">
                            <div v-if="slotProps.data.general.is_alive" class="w-16 h-8 rounded-full flex justify-center items-center bg-green-500 text-white" />
                            <div v-else class="w-16 h-8 rounded-full flex justify-center items-center bg-darkRed text-white" />                        
                        </div>
                        <div v-else>{{slotProps.data[col.field.split('.')[0]][col.field.split('.')[1]]}}</div>
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div class="bg-gray-100 flex gap-2 p-2 px-4 h-full">
                        <div class="flex flex-col gap-1 w-1/4">
                            <span class="text-lg font-semibold">General info</span>
                            <span><span class="opacity-50">muid:</span> {{slotProps.data.general.muid}}</span>
                            <span><span class="opacity-50">installed:</span> {{displayDate(slotProps.data.general.installed_timestamp)}}</span>
                            <span><span class="opacity-50">days since install:</span> {{Math.round((Date.now() - slotProps.data.general.installed_timestamp) / (1000 * 60 * 60 * 24))}}</span>
                            <span><span class="opacity-50">average days between errors:</span> {{ slotProps.data.errors.general_error_info.average_days_between_errors }}</span>
                            <span><span class="opacity-50">last filter bag expired reminder email:</span> {{slotProps.data.general.last_service_reminder ? displayDate(slotProps.data.general.last_service_reminder) : 'none on file'}}</span>
                            <span><span class="opacity-50">last maintenance reminder email:</span> {{slotProps.data.general.last_maintenance_reminder ? displayDate(slotProps.data.general.last_maintenance_reminder) : 'none on file'}}</span>
                            <span class="font-bold">Contact</span>
                            <span><span class="opacity-50">Name:</span> {{ slotProps.data.general?.contact?.name }}</span>
                            <span><span class="opacity-50">Email:</span> {{ slotProps.data.general?.contact?.email }}</span>
                            <span><span class="opacity-50">Phone:</span> {{ slotProps.data.general?.contact?.phone }}</span>
                        </div>
                        <div class="w-1/4">
                            <div class="flex flex-col gap-1">
                                <span class="text-lg font-semibold">Most common codes in timeframe</span>
                                <div v-if="slotProps.data.errors?.most_common_errors?.length > 0" class="flex flex-col gap-1">
                                    <span v-for="error in slotProps.data.errors?.most_common_errors" :key="error.code">{{error.code}} <span class="opacity-50">({{error_codes[error.code]['en']['label']}})</span>: {{error.count}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="w-1/4 flex-grow" style="max-width: max-content">
                            <div class="flex flex-col gap-1">
                                <span class="text-lg font-semibold">10 most recent errors in timeframe</span>
                                <span v-for="error in slotProps.data.errors.error_history?.filter((_, index) => index < 11)" :key="error.timestamp + error.code"><span>{{displayDate(error.timestamp)}}</span> - {{error.code}} <span class="text-gray-500">({{error_codes[error.code]['en']['label']}})</span></span>
                            </div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import Mimbox from '@/components/admin_components/Mimbox.vue'
import General from '@/components/admin_components/General.vue'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Loading from '@/components/Loading.vue'
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ScrollPanel from 'primevue/scrollpanel';
import ProgressBar from 'primevue/progressbar';

import last_codes_graph_explained from '@/assets/guides/last_codes_graph_explained.png'
import total_codes_explained from '@/assets/guides/total_codes_explained.png'
import recycling_rate_graphs_explained from '@/assets/guides/test.png'

import Sidebar from 'primevue/sidebar';
import AdminSidebar from '../components/admin_components/AdminSidebar.vue';

const ObjectsToCsv = require('objects-to-csv');

export default
{
    name: "Admin",
    //eslint-disable-next-line
    components: { Sidebar, Mimbox, Loading, General, OverlayPanel, Divider, DataTable, Column, InputText, Button, MultiSelect, Dialog, TabView, TabPanel, ScrollPanel, ProgressBar, AdminSidebar },
    data() {
        return {
            codes_expalined_modal: false,
            error_codes_explained: [],
            loading: true,
            visibleLeft: false,
            progress: 0,
            last_codes_graph_explained,
            total_codes_explained,
            recycling_rate_graphs_explained,
            expandedRows: [],
            columnOrder: [
                'E. !',
                'E. #',
                'E. last',
                'E. Avg. days between',
                'T. !',
                'Company',
                'Name',
                'MUID',
                'Installation',
                'Software',
                'Hardware',
                'Filter Email',
                'Maintenance Email',
                'Maintenance',
                'Service Date',
                'Service',
                'P ',
                'P',
                'Recycled',
                '%R',
                'A'
            ],
            selectedColumns: [
                {field: 'general.active_errors', header: 'E. !'},
                {field: 'general.standby', header: 'T. !'},
                {field: 'general.company', header: 'Company'},
                {field: 'general.name', header: 'Name'},
                {field: 'general.software', header: 'Software'},
                {field: 'general.hardware', header: 'Hardware'},
                {field: 'general.most_recent_maintenance', header: 'Maintenance'},
                {field: 'general.days_since_service', header: 'Service'},
                {field: 'general.water_processed_since_service', header: 'P '},
                {field: 'general.water_saved', header: 'Recycled'},
                {field: 'general.recycling_rate', header: '%R'},
                {field: 'general.is_alive', header: 'A'}
            ],
            tooltips: {
                "general.active_errors": "Currently active errors",
                "errors.error_history": "Errors in the last 7 days",
                "errors.last_error_timestamp": "Date of last error",
                "errors.general_error_info.average_days_between_errors": "Average days between errors",
                "general.standby": "A warning triangle means the mimbox is in standby mode because of a dirty sensor house",
                "general.company": "Company name",
                "general.name": "Mimbox name",
                "general.muid": "Mimbox unique ID",
                "general.installed_timestamp": "Date of installation",
                "general.software": "Software version",
                "general.hardware": "Hardware version",
                "general.last_service_reminder": "Date of last filter bag expired reminder email",
                "general.last_maintenance_reminder": "Date of last maintenance reminder email",
                "general.most_recent_maintenance": "Date of last maintenance",
                "general.most_recent_service": "Date of last service",
                "general.days_since_service": "Days since last service",
                "general.water_processed_since_service": "Water processed since last service",
                "general.total_water_processed": "Total water processed",
                "general.water_saved": "Total recycled litres",
                "general.recycling_rate": "Total recycling rate",
                "general.is_alive": "Connection status"
            },
            columns: [
                {field: 'general.active_errors', header: 'E. !'},
                {field: 'errors.error_history', header: 'E. #'},
                {field: 'general.standby', header: 'T. !'},
                {field: 'errors.last_error_timestamp', header: 'E. last'},
                {field: 'errors.general_error_info.average_days_between_errors', header: 'E. Avg. days between'},
                {field: 'general.company', header: 'Company'},
                {field: 'general.name', header: 'Name'},
                {field: 'general.muid', header: 'MUID'},
                {field: 'general.installed_timestamp', header: 'Installation'},
                {field: 'general.software', header: 'Software'},
                {field: 'general.hardware', header: 'Hardware'},
                {field: 'general.last_service_reminder', header: 'Filter Email'},
                {field: 'general.last_maintenance_reminder', header: 'Maintenance Email'},
                {field: 'general.most_recent_maintenance', header: 'Maintenance'},
                {field: 'general.most_recent_service', header: 'Service Date'},
                {field: 'general.days_since_service', header: 'Service'},
                {field: 'general.water_processed_since_service', header: 'P '},
                {field: 'general.total_water_processed', header: 'P'},
                {field: 'general.water_saved', header: 'Recycled'},
                {field: 'general.recycling_rate', header: '%R'},
                {field: 'general.is_alive', header: 'A'}
            ],
            filters: {
                "global": {
                    "value": ""
                }
            },
            selected: {},
            mimboxes_unfiltered: [],
            mimboxes: [],
            errors: [],
            unitialized: true,
            ignore_codes: [],
            sorting_on: '',
            information: {
                average_days_since_install: undefined,
                average_recycle_rate: undefined,
                average_water_processed: undefined,
                total_mimboxes: undefined,
                total_water_processed: undefined
            },
            time: undefined,
            errors_listed: {
                latest_codes_by_occurrences: [],
                total_codes_by_occurrences: []
            }
        }
    },
    watch: {
        error_codes:{
            handler(val){
                if(this.error_codes_explained.length > 0 || !val) return
                this.error_codes_explained = Object.keys(val).map((key) => {
                    return {
                        code: parseInt(key),
                        label: val[key].en.label,
                        description: val[key].en.description
                    }
                })
            },
            deep: true
        },
        visibleLeft: {
            handler(val){
                console.log(val)
            }
        }
    },
    methods: {
        async sendSms(muid, command) {
            try {
                await this.send_sms(muid, command);
                this.$toast.add({ severity: 'success', summary: 'Success', detail: `Sent ${command} command to ${muid}`, life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: `Failed to send ${command} command to ${muid}`, life: 3000 });
            }
        },
        remoteCommand(command, { muid, name }) {
            this.$confirm.require({
                message: `Are you sure you want to send a ${command} command to ${name}?`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => this.sendSms(muid, command)
            })
        },
        runProgress(){
            this.progress = 0
            const interval = setInterval(() => {
                if(this.progress < 60 && this.loading) this.progress += 1
                else clearInterval(interval)
                console.log(this.progress / 60 * 100)
            }, 1000)
        },
        async download_recycling_rate_graphs(muid){
            const IDTOKEN = await firebase.auth().currentUser.getIdTokenResult()
            const res = await this.fetchData(`recycling?muid=${muid}`, IDTOKEN.token)
            const base64 = res.figure
            const blob = this.base64ToBlob(base64)
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = `${this.selected.name}_recycling_rate_graph.png`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        },
        base64ToBlob(base64) {
            const binary = atob(base64)
            const array = []
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }
            return new Blob([new Uint8Array(array)], { type: 'image/png' })
        },
        show(e, mimbox) {
            this.$refs.op.toggle(e);
            this.selected = mimbox
        },
        getMimboxInfo(general) {
            let service = false
            let maintenance = false

            if(general.active_errors.length > 0 && general.active_errors.every(code => code == 17)) service = true
            if((general?.most_recent_service && (Math.round((Date.now() - general.most_recent_service.timestamp) / (1000 * 60 * 60 * 24)) > 7)) || !general.most_recent_service) maintenance = true
            return {
                muid: general.muid,
                name: general.name,
                service,
                maintenance,
            }
        },
        sendReminder(type, muid){
            if(type === 'service') this.sendServiceReminder(muid)
            else this.sendMaintReminder(muid)
        },
        async hasContact (muid) {
            const unit_ref = firebase.firestore().collection('mimboxes').doc(muid)
            const unit = await unit_ref.get()
            const data = unit.data()
            if(!data?.contacts?.[0].email || !data?.contacts?.[0].name) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No contact information on file. Add a contact with an email address in the mimbox page', life: 3000 });
                return false
            }
            return true
        },
        async sendMaintReminder(muid){
            if(!await this.hasContact(muid)) return

            const send_data = await this.check_reminder_email(muid, 'maintenance')
            send_data.muid = muid
            this.checkLastEmail(send_data, 'maintenance')
        },
        async sendServiceReminder(muid) {
            if(!await this.hasContact(muid)) return

            const send_data = await this.check_reminder_email(muid, 'filter bag expired')
            send_data.muid = muid
            this.checkLastEmail(send_data, 'filter bag expired')
        },
        async checkLastEmail(data, type) {
            const { last_sent_days } = data
            if(last_sent_days !== 'none' && last_sent_days < 7) {
                this.$confirm.require({
                    message: `This contact last recieved a ${type} email from us ${last_sent_days} days ago. Are you sure you want to send another?`,
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        setTimeout(() => {
                            this.confirmEmailModal(data, type)
                        }, 500)
                    }
                })
            } else {
                this.confirmEmailModal(data, type)
            }
        },
        confirmEmailModal(send_data, type) {
            const { installed_days, microplastic_g, address, city, name, send_to } = send_data
            send_data.type = type
            this.$confirm.require({
                message: `Sending ${type} email to "${name}" with email "${send_to}", \n installed at "${address}" in "${city}" for "${installed_days}" days, "${microplastic_g}" g microplastics saved`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    const functions = firebase.functions()
                    // if(window.location.hostname === 'localhost') functions.useEmulator("127.0.0.1", 5001);
                    const send_reminder_server = functions.httpsCallable("send_reminder_email")
                    await send_reminder_server(send_data)
                    this.addEmailToServiceLog(type, {name, send_to})
                    this.$toast.add({ severity:'success', summary: 'Email sent', detail: `Sent a ${type} email and added a note about it in the mimbox's service log.`, life: 3000 });
                }
            });
        },
        async addEmailToServiceLog(type, {name, send_to: email}) {
            const user = firebase.auth().currentUser.email
            await this.pushNewServiceLog(this.selected.muid, {
                timestamp: Date.now(),
                message: `${type} email sent to ${name} with email ${email}`,
                type: 'note',
                author: user ?? 'unknown',
            })
        },
        async fetchData(endpoint, token) {
            try {
                const response = await fetch(this.apiHost + endpoint, {
                    method: 'GET',
                    contentType: 'application/json',
                    headers: {
                        'Authorization': token
                    }}
                )
                return await response.json()
            }
            catch(e) { console.warn(e) }
        },
        async run() {
            const codes = this.requestErrorCodeList()
            this.loading = true
            this.progress = 0
            this.runProgress()
            const [mimboxes, errors] = await this.adminPage()
            this.information = mimboxes.general
            Object.keys(errors.total).map(key => {
                if(key.includes('occurrences')) {
                    this.errors_listed[key] = errors.total[key]
                } else {
                    this.information[key] = errors.total[key]
                }
            })
            this.mimboxes = mimboxes.list.map((mimbox, index) => {
                if(index == 0) this.time = errors[mimbox.muid]?.time
                const most_common_errors = errors[mimbox.muid]?.all_errors_by_code.map((error, index) => { return { code: index, ...error }}).sort((a,b) => b.count - a.count).filter(error => error.count != 0)
                return {
                    general: mimbox,
                    errors: { most_common_errors, ...errors?.[mimbox.muid]},
                }
            })
            this.mimboxes_unfiltered = JSON.parse(JSON.stringify(this.mimboxes))
            await codes
            this.loading = false
        },
        clearFilter() {
            this.filters.global.value = ''
        },
        onToggle(value) {
            this.selectedColumns = this.columnOrder.map(col => value.find(column => column.header == col)).filter(col => col);
        },
        async exportCSV() {
            const mergedMimbox = this.mimboxes.map(mimbox => {
                const allData = {
                    "general.active_errors": mimbox.general.active_errors,
                    "errors.error_history": mimbox.errors.error_history.length,
                    "errors.last_error_timestamp": mimbox.errors.last_error_timestamp ? new Date(mimbox.errors.last_error_timestamp).toLocaleDateString() : '',
                    "errors.general_error_info.average_days_between_errors": mimbox.errors.general_error_info.average_days_between_errors,
                    "general.company": mimbox.general.company,
                    "general.name": mimbox.general.name,
                    "general.muid": mimbox.general.muid,
                    "general.installed_timestamp": mimbox.general.installed_timestamp ? new Date(mimbox.general.installed_timestamp).toLocaleDateString() : '',
                    "general.software": mimbox.general.software,
                    "general.hardware": mimbox.general.hardware,
                    "general.last_service_reminder": mimbox.general.last_service_reminder ? new Date(mimbox.general.last_service_reminder).toLocaleDateString() : '',
                    "general.last_maintenance_reminder": mimbox.general.last_maintenance_reminder ? new Date(mimbox.general.last_maintenance_reminder).toLocaleDateString() : '',
                    "general.most_recent_maintenance": mimbox.general.most_recent_maintenance ? new Date(mimbox.general.most_recent_maintenance).toLocaleDateString() : '',
                    "general.most_recent_service": mimbox.general.most_recent_service ? new Date(mimbox.general.most_recent_service).toLocaleDateString() : '',
                    "general.days_since_service": mimbox.general.days_since_service,
                    "general.water_processed_since_service": mimbox.general.water_processed_since_service,
                    "general.total_water_processed": parseFloat((mimbox.general.total_water_processed).toFixed(2)),
                    "general.water_saved": parseFloat((mimbox.general.water_saved).toFixed(2)),
                    "general.recycling_rate": parseInt(mimbox.general.recycling_rate * 100),
                    "general.is_alive": mimbox.general.is_alive ? 'Connected' : 'Disconnected'
                }
                const retObj = this.selectedColumns.filter(({field}) => Object.keys(allData).join("").includes(field)).map(({ field }) => allData[field])
                return retObj
            })
            const csvWithHeader = [
                this.selectedColumns.map(({ field }) => field === 'general.last_service_reminder' ? 'last_filter_change_reminder' : field.replace("general.", "")),
                ...mergedMimbox
            ]
            const csv = new ObjectsToCsv(csvWithHeader)
            const a = await csv.toString(false)
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(a));
            element.setAttribute('download', `${this.displayDate(Date.now(), 'medium')}_Mimbly_admin - mimboxes.csv`);   
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
    },
    async mounted() {
        this.metaInfo("Admin", this.$route.path)
        this.run()
    },
    beforeDestroy(){
        this.unsubscribeAll();
	}
}
</script>

<style scoped>
.p-divider-solid::before {
    border-top:1px solid #E1DFE0 !important
}

.alive-icon {
    background-image: url('../assets/admin_icons/wifi.svg');
    background-position: center;
}

.water-icon {
    background-image: url('../assets/admin_icons/droplet.svg');
}

.error-icon {
    background-image: url('../assets/admin_icons/alert-triangle.svg');
    background-position: center;
}

.maint-icon {
    background-image: url('../assets/wrench.svg');
}

.recycle-icon {
    background-image: url('../assets/admin_icons/refresh-ccw.svg');
}

.a {
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
}

.c {
    width: 100%;
    display: flex;
    gap: .25rem;
    justify-content: space-around;

}

.d {
    width: 2.5rem;
    border-radius: 0.25rem;
    color: white;
    border: 2px solid black;
}

.e {
    border-radius: 0.25rem;
    background: var(--blue);
    color: white;    
}

.e2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.f {
    background: rgb(75,85,99);
    color: white;
    width: 16rem;
    padding: .5rem 0;
    border-radius: 0.25rem;
}

.g {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.i {
    position: relative;
}

.j {
    position: absolute;
    top: -1.6rem;
    z-index: 10;
}
</style>