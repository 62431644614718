<template>
    <div>
        <Button class="e w-12 h-12 flex justify-center items-center" @click="visibleLeft = !visibleLeft">
            <i class="pi pi-bars text-3xl text-white"></i>
        </Button>
        <div class="w-full flex flex-col items-center gap-2">
            <AdminSidebar :visibleLeft.sync="visibleLeft" @hide="visibleLeft = !visibleLeft" />
            <h1>Event log discards</h1>
            <div class="flex gap-4 items-center">
                <div class="flex flex-col gap-2">
                    <span>Upload a file</span>
                    <input type="file" @change="onFileChange" />
                </div>
                <!-- <div class="flex flex-col gap-2">
                    <span>Or select a mimbox and date range</span>
                    <div class="flex gap-4 items-center">
                        <Dropdown v-model="selectedMimbox" :options="mimboxes[0]" :filter="onFilter" showClear />
                        <Calendar v-model="dateRange" selectionMode="range" />
                    </div>
                </div> -->
            </div>
            <!-- <div class="flex gap-2">
                <Dropdown v-model="selected_company" :options="companies" :filter="true" filterPlaceholder="Select company" />
                <Dropdown v-model="selected_mimbox" :options="mimboxes" :filter="true" filterPlaceholder="Select mimbox" dataKey="id">
                    <template #option="option">
                        <span>{{ option.option.name }}</span>
                    </template>
                    <template #value="option">
                        <span>{{ option?.value?.name }}</span>
                    </template>
                </Dropdown>
                <Calendar v-model="selected_date_range" selectionMode="range" />
            </div> -->
            <Divider />
            <h3>Current limit values: </h3>
            <div class="flex flex-col gap-2 items-center">
                <div class="flex gap-2">
                    <span>conductivity: {{ LIMITS.conductivity }}</span>
                    <span>turbidity: {{ LIMITS.turbidity }}</span>
                    <span>conductivity_turbidity: {{ LIMITS.conductivity_turbidity }}</span>
                    <span>temperature: {{ LIMITS.temperature }}</span>
                </div>
                <div class="flex gap-2">
                    <span>color: {{ LIMITS.color * 100 }}%</span>
                    <span>color_difference: {{ LIMITS.color_difference * 100 }}%</span>
                </div>
            </div>
            <Divider />
            <div>Percentage of events saved: {{ ((totalSaved / (totalDiscarded + totalSaved)) * 100).toFixed(2) }}%</div>
            <div class="flex gap-2">
                <div>Total saved events: {{ totalSaved }}</div>
                <div>Total discarded events: {{ totalDiscarded }}</div>
            </div>
            <div class="flex flex-col gap-2">
                <div class="flex gap-2">
                    <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="colorOpts" :series="discardedColor"></apexchart>
                    <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="colorDiffOpts" :series="discardedColorDifference"></apexchart>
                    <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="condOpts" :series="discardedConductivity"></apexchart>
                </div>
                <div class="flex gap-2">
                    <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="condturbOpts" :series="discardedConductivityTurbidity"></apexchart>
                    <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="tempOpts" :series="discardedTemperature"></apexchart>
                    <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="turbOpts" :series="discardedTurbidity"></apexchart>
                </div>
            </div>
            <Button :label="enable_new_limits ? 'Disable new limits' : 'Enable new limits'" @click="enable_new_limits = !enable_new_limits" />
            <div v-if="enable_new_limits" class="flex flex-col items-center gap-2">
                <h1>New Limits</h1>
                <div>Percentage of events saved: {{ ((totalSavedNew / (totalDiscardedNew + totalSavedNew)) * 100).toFixed(2) }}%</div>
                <div class="flex gap-2">
                    <div>Total saved events: {{ totalSavedNew }}</div>
                    <div>Total discarded events: {{ totalDiscardedNew }}</div>
                </div>
                <form class="flex flex-col items-center gap-2">
                    <label for="conductivity">Conductivity</label>
                    <InputText v-model="NEW_LIMITS.conductivity" type="number" :step="10" />
                    <span class="text-xs font-light">The maximum allowed conductivity in µS/cm</span>

                    <label for="conductivity">Turbidity</label>
                    <InputText v-model="NEW_LIMITS.turbidity" type="number" :step="10" />
                    <span class="text-xs font-light">The maximum allowed turbidity in NTU</span>

                    <label for="conductivity">Conductivity + (2 * turbidity)</label>
                    <InputText v-model="NEW_LIMITS.conductivity_turbidity" type="number" :step="10" />
                    <span class="text-xs font-light">The maximum allowed conductivity+turbidity measurement (with tap conductivity added to limit)</span>

                    <label for="conductivity">Temperature</label>
                    <InputText v-model="NEW_LIMITS.temperature" type="number" :step="5" />
                    <span class="text-xs font-light">The maximum allowed temperature measurement</span>

                    <div class="flex gap-2 w-1/2 w-[800px]">
                        <div class="flex flex-col gap-2">
                            <label for="conductivity">Color if Turbidity below 25 NTU</label>
                            <InputText v-model="NEW_LIMITS.color[25]" type="number" :step="0.05" />
                            <span class="text-xs font-light">Maximum allowed color as a percentage when turbidity is below 25 NTU ({{ NEW_LIMITS.color[25] }} is minimum {{ Math.round(NEW_LIMITS.color[25] * 100) }}% transparent (white reading))</span>
                        </div>
                        <div class="flex flex-col gap-2">
                            <label for="conductivity">Color if Turbidity below 100 NTU</label>
                            <InputText v-model="NEW_LIMITS.color[100]" type="number" :step="0.05" />
                            <span class="text-xs font-light">Maximum allowed color as a percentage when turbidity is below 100 NTU ({{ NEW_LIMITS.color[100] }} is minimum {{ Math.round(NEW_LIMITS.color[100] * 100) }}% transparent (white reading))</span>
                        </div>
                        <div class="flex flex-col gap-2">
                            <label for="conductivity">Color if Turbidity below 500 NTU</label>
                            <InputText v-model="NEW_LIMITS.color[500]" type="number" :step="0.05" />
                            <span class="text-xs font-light">Maximum allowed color as a percentage when turbidity is below 500 NTU ({{ NEW_LIMITS.color[500] }} is minimum {{ Math.round(NEW_LIMITS.color[500] * 100) }}% transparent (white reading))</span>
                        </div>
                    </div>

                    <label for="conductivity">Color difference</label>
                    <InputText v-model="NEW_LIMITS.color_difference" type="number" :step="0.05" />
                    <span class="text-xs font-light">The difference between colors as a percentage ({{ NEW_LIMITS.color_difference }} = not more than {{ Math.round(NEW_LIMITS.color_difference * 100) }}% difference between colors)</span>

                    <Button :label="'Regenerate'" @click="() => onFileChange(event_log_file, true)" />
                </form>
                <div class="flex gap-2 flex-col">
                    <div class="flex gap-2">
                        <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="colorOpts" :series="discardedColorNew"></apexchart>
                        <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="colorDiffOpts" :series="discardedColorDifferenceNew"></apexchart>
                        <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="condOpts" :series="discardedConductivityNew"></apexchart>
                    </div>
                    <div class="flex gap-2">
                        <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="condturbOpts" :series="discardedConductivityTurbidityNew"></apexchart>
                        <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="tempOpts" :series="discardedTemperatureNew"></apexchart>
                        <apexchart v-if="!loading" type="donut" height="300px" width="300px" :options="turbOpts" :series="discardedTurbidityNew"></apexchart>
                    </div>
                </div>
            </div>
            <DataTable :value="eventLog" responsiveLayout="scroll" :scrollable="true" scrollHeight="600px" class="w-full">
                <Column field="date" header="Date"></Column>
                <Column field="status" header="Status"></Column>
                <Column field="note" header="Note"></Column>
                <Column field="newNote" header="New Note"></Column>
            </DataTable>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import DataTable from 'primevue/datatable/DataTable';
import Column from 'primevue/column/Column';
import AdminSidebar from '../components/admin_components/AdminSidebar.vue';

import { ref, onMounted, watch } from 'vue';
import { fb } from '../main';
import Calendar from 'primevue/calendar/Calendar';
import Dropdown from 'primevue/dropdown/Dropdown';
import Divider from 'primevue/divider/Divider';
import Button from 'primevue/button/Button';
import InputText from 'primevue/inputtext/InputText';

const eventLog = ref(null)
const totalDiscarded = ref(0)
const totalDiscardedNew = ref(0)
const totalSaved = ref(0)
const totalSavedNew = ref(0)
const enable_new_limits = ref(false)
const event_log_file = ref(null)

const loading = ref(true)

const visibleLeft = ref(false)

// const selected_company = ref(null)
// const selected_mimbox = ref(null)
// const selected_date_range = ref(null)

// const companies = ref([])
// const possible_mimboxes = ref([])
// const mimboxes = ref([])
// onMounted(() => {
//     fb.firestore().collection('companies').get().then((snapshot) => {
//         snapshot.forEach(doc => {
//             companies.value.push(doc.id)
//         })
//     })
//     fb.firestore().collection("mimboxes").where("access", "array-contains", "mimbly").get().then((snapshot) => {
//         snapshot.forEach(doc => {
//             const data = doc.data()
            
//             possible_mimboxes.value.push({
//                 id: doc.id,
//                 name: data.name,
//                 access: data.access
//             })
//         })
//     })
// })

// watch(selected_company, (newVal) => {
//     if(newVal) {
//         mimboxes.value = possible_mimboxes.value.filter((item) => item.access.includes(newVal))
//     }
// })
// const mimboxes = ref([[], []])
// const selectedMimbox = ref(null)
// const dateRange = ref(null)
// onMounted(async () => {
//     const mimboxes_collection = await fb.firestore().collection('mimboxes').where("access", "array-contains", "mimbly").get()
//     mimboxes_collection.forEach(mimbox => {
//         const { muid, name } = mimbox.data()
//         mimboxes.value[0].push(name)
//         mimboxes.value[1].push(muid)
//     })
// })

const chartOpts = {
    tooltip: {},
    // stroke: {
    //     width: [0, 4]
    // },
    title: { text: "Discarded events" },
    labels: ["Other", "Hit discard limit"],
    colors:["#e7c2b9", "#e76f51"],
    chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        id: 'total_codes',
        type: 'donut'
    }
}
const discardedColor = ref([0,0])
const discardedColorNew = ref([0,0])
const colorOpts = { ...chartOpts, title: { text: "Color" } }
const discardedColorDifference = ref([0,0])
const discardedColorDifferenceNew = ref([0,0])
const colorDiffOpts = { ...chartOpts, title: { text: "Color Difference" } }
const discardedConductivity = ref([0,0])
const discardedConductivityNew = ref([0,0])
const condOpts = { ...chartOpts, title: { text: "Conductivity" } }
const discardedConductivityTurbidity = ref([0,0])
const discardedConductivityTurbidityNew = ref([0,0])
const condturbOpts = { ...chartOpts, title: { text: "Conductivity/turbidity" } }
const discardedTemperature = ref([0,0])
const discardedTemperatureNew = ref([0,0])
const tempOpts = { ...chartOpts, title: { text: "Temperature" } }
const discardedTurbidity = ref([0,0])
const discardedTurbidityNew = ref([0,0])
const turbOpts = { ...chartOpts, title: { text: "Turbidity" } }

const discarded_table = {
    color: discardedColor,
    color_difference: discardedColorDifference,
    conductivity: discardedConductivity,
    conductivity_turbidity: discardedConductivityTurbidity,
    temperature: discardedTemperature,
    turbidity: discardedTurbidity
}

const new_discarded_table = {
    color: discardedColorNew,
    color_difference: discardedColorDifferenceNew,
    conductivity: discardedConductivityNew,
    conductivity_turbidity: discardedConductivityTurbidityNew,
    temperature: discardedTemperatureNew,
    turbidity: discardedTurbidityNew
}

const getIndexes = (line) => {
    const data = line.split(',');
    const dateIndex = data.findIndex((item) => item === 'timestamp');
    const typeIndex = data.findIndex((item) => item === 'type');
    const colorIndex = data.findIndex((item) => item === 'color_water');
    const conductivityIndex = data.findIndex((item) => item === 'cond_water');
    const tapConductivityIndex = data.findIndex(item => item === 'cond_calib');
    const temperatureIndex = data.findIndex((item) => item === 'temp_water_in');
    const turbidityIndex = data.findIndex((item) => item === 'turb_water');
    return [
        dateIndex,
        typeIndex,
        colorIndex,
        conductivityIndex,
        tapConductivityIndex,
        temperatureIndex,
        turbidityIndex,
    ];
}

// const addToChart = (index) => chartSeries.value[index] = (chartSeries.value[index] ?? 0) + 1

function checkDiscarded({ discarded_table, conductivity, tap_conductivity, temperature, turbidity, color, new_limits = false }) {
    const discarded = {
        color: is_discarded_color(color, turbidity, new_limits), 
        color_difference: is_discarded_color_difference(color, new_limits), 
        conductivity: is_discarded_conductivity(conductivity, tap_conductivity, new_limits), 
        conductivity_turbidity: is_discarded_conductivity_turbidity(conductivity, turbidity, tap_conductivity, new_limits),
        temperature: is_discarded_temperature(temperature, new_limits),
        turbidity: is_discarded_turbidity(turbidity, new_limits)
    }

    let discarded_val = 0
    let saved_val = 0
    let status
    if(Object.values(discarded).some((item) => item)) {
        status = 'Discarded'
        discarded_val = 1
    } else {
        status = 'Saved'
        saved_val = 1
    }

    let note = [];
    Object.entries(discarded).forEach(([key, value]) => {
        if(value) {
            note.push(key)
            discarded_table[key].value[1]++
        }
    })
    note = note.join(', ')
    return { note, status, discarded_val, saved_val }
}

function readEventLog(e, only_new) {
    const content = e.target.result;
    const lines = content.split('\n');
    let dateIndex, typeIndex, colorIndex, conductivityIndex, tapConductivityIndex, temperatureIndex, turbidityIndex;
    let tap_conductivity = 200 //TODO: Change to start from last calibration
    const resData = []
    
    if(!only_new) {
        totalDiscarded.value = 0
        totalSaved.value = 0
        discardedColor.value = [0,0]
        discardedColorDifference.value = [0,0]
        discardedConductivity.value = [0,0]
        discardedConductivityTurbidity.value = [0,0]
        discardedTemperature.value = [0,0]
        discardedTurbidity.value = [0,0]
    }

    totalDiscardedNew.value = 0
    totalSavedNew.value = 0
    discardedColorNew.value = [0,0]
    discardedColorDifferenceNew.value = [0,0]
    discardedConductivityNew.value = [0,0]
    discardedConductivityTurbidityNew.value = [0,0]
    discardedTemperatureNew.value = [0,0]
    discardedTurbidityNew.value = [0,0]
    
    lines.forEach((line, index) => {
        if(index === 0) {
            [
                dateIndex,
                typeIndex,
                colorIndex,
                conductivityIndex,
                tapConductivityIndex,
                temperatureIndex,
                turbidityIndex,
            ] = getIndexes(line);
            return;
        }
        const data = line.split(',');
        const type = data[typeIndex]
        if(type === 'calibration') tap_conductivity = parseFloat(data[tapConductivityIndex])
        if(type !== 'end_water_to_mimbox') return
        const date = data[dateIndex];
        const color = data[colorIndex];
        const conductivity = parseFloat(data[conductivityIndex]);
        const temperature = parseFloat(data[temperatureIndex]);
        const turbidity = parseFloat(data[turbidityIndex]);

        let note, status
        if(!only_new) {
            const { note: discarded_note, status: discarded_status, discarded_val, saved_val } = checkDiscarded({ discarded_table, conductivity, tap_conductivity, temperature, turbidity, color })
            note = discarded_note
            status = discarded_status
            totalDiscarded.value += discarded_val
            totalSaved.value += saved_val
        }

        const { note: newNote, discarded_val: new_discarded_val, saved_val: new_saved_val} = checkDiscarded({ discarded_table: new_discarded_table, conductivity, tap_conductivity, temperature, turbidity, color, new_limits: true })
        totalDiscardedNew.value += new_discarded_val
        totalSavedNew.value += new_saved_val

        resData.push({ date, status, note, newNote })
    });
    discardedColor.value[0] = totalDiscarded.value - discardedColor.value[1];
    discardedColorDifference.value[0] = totalDiscarded.value - discardedColorDifference.value[1];
    discardedConductivity.value[0] = totalDiscarded.value - discardedConductivity.value[1];
    discardedConductivityTurbidity.value[0] = totalDiscarded.value - discardedConductivityTurbidity.value[1];
    discardedTemperature.value[0] =totalDiscarded.value - discardedTemperature.value[1];
    discardedTurbidity.value[0] = totalDiscarded.value - discardedTurbidity.value[1];

    discardedColorNew.value[0] = totalDiscardedNew.value - discardedColorNew.value[1];
    discardedColorDifferenceNew.value[0] = totalDiscardedNew.value - discardedColorDifferenceNew.value[1];
    discardedConductivityNew.value[0] = totalDiscardedNew.value - discardedConductivityNew.value[1];
    discardedConductivityTurbidityNew.value[0] = totalDiscardedNew.value - discardedConductivityTurbidityNew.value[1];
    discardedTemperatureNew.value[0] =totalDiscardedNew.value - discardedTemperatureNew.value[1];
    discardedTurbidityNew.value[0] = totalDiscardedNew.value - discardedTurbidityNew.value[1];

    eventLog.value = resData;
    loading.value = false;
}

const onFileChange = (e, only_new = false) => {
    loading.value = true
    eventLog.value = null
    totalDiscarded.value = 0
    totalSaved.value = 0
    event_log_file.value = e;
    const reader = new FileReader();
    reader.onload = readEventLog
    reader.readAsText(event_log_file.value.target.files[0], only_new);
};

const LIMITS = {
    "color": 0.2,
    "color_difference": 0.15,
    "turbidity": 300,
    "conductivity": 600,
    "conductivity_turbidity": 1000,
    "temperature": 40,
}

const NEW_LIMITS = ref({
    "color": {
        25: 0.8,
        100: 0.4,
        500: 0.2
    },
    "color_difference": 0.10,
    "turbidity": 500,
    "conductivity": 1000,
    "conductivity_turbidity": 1500,
    "temperature": 40,
})

function is_discarded_turbidity(turbidity, new_limits = false) {
    const table = new_limits ? NEW_LIMITS.value : LIMITS;
    if (turbidity === null) return false;
    else if (turbidity > parseFloat(table['turbidity'])) {
        return true;
    }
    return false;
}

function is_discarded_temperature(temperature, new_limits = false) {
    const table = new_limits ? NEW_LIMITS.value : LIMITS;
    if (temperature === null) return false;
    else if (temperature > parseFloat(table['temperature'])) return true;
    return false;
}

function is_discarded_conductivity(conductivity, tap_conductivity, new_limits = false) {
    const table = new_limits ? NEW_LIMITS.value : LIMITS;
    if (conductivity === null) return false;
    else if (conductivity > (parseFloat(table['conductivity']) + tap_conductivity)) {
        return true;
    }
    return false;
}

function is_discarded_conductivity_turbidity(conductivity, turbidity, tap_conductivity, new_limits = false) {
    const table = new_limits ? NEW_LIMITS.value : LIMITS;
    if (conductivity === null || turbidity === null) return false;
    else if ((conductivity + (2 * turbidity)) > ((new_limits ? tap_conductivity : 0) + parseFloat(table['conductivity_turbidity']))) return true;
    return false;
}

const cR = /"([A-z])":([0-9]+(?:\.[0-9]+)?)/g

const formatColors = (colorString) => {
    const arr = [...colorString.matchAll(cR)]
    const colors = {}
    arr.forEach(([_, key, val]) => {
        colors[key] = parseFloat(val)
    })
    return colors
}

function closest_up (num, arr) {
    var filteredArr = arr.filter(val => num <= val);
    if (filteredArr.length === 0) return arr[arr.length - 1];
    var closest = filteredArr.reduce((prev, curr) => {
        return (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev);
    });
    return closest;
}

function is_discarded_color(colorString, turbidity, new_limits = false) {
    const table = new_limits ? NEW_LIMITS.value : LIMITS;
    const colors = formatColors(colorString)
    for (const value of Object.values(colors)) {
        if (new_limits) {
            const t = closest_up(turbidity, Object.keys(table['color']))
            if (value <= table['color'][t]) return true;
            else return false;
        } else if (value <= table['color']) return true;
    }
    return false;
}

function is_discarded_color_difference(colorString, new_limits = false) {
    const table = new_limits ? NEW_LIMITS.value : LIMITS;
    const colors = formatColors(colorString)
    for (const [key1, value1] of Object.entries(colors)) {
        for (const [key2, value2] of Object.entries(colors)) {
            if (key1 !== key2 && Math.abs(value1 - value2) >= parseFloat(table['color_difference'])) {
                return true;
            }
        }
    }
    return false;
}

</script>