<template>
    <div class="login-view">
        <div class="col col-1">
            <h3>{{trans('mimboard')}}</h3>
            <span v-if="$route.name == 'login'">{{description.login}}</span>
            <span v-else>{{description.register}}</span>
            <img src="../assets/fish.svg" alt="an image" v-if="$route.name == 'login'">
            <img src="../assets/fish-register.svg" alt="an image" v-else>
        </div>
        <div class="col col-2">
            <LoginRegister :type="$route.name" @login="loginFunc" @forgot="forgotFunc" :loginError="error" />
        </div>
        <div class="col col-3">
            <getSupport />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                error: false,
                pane: '',
                description: {
                register: '',
                login: '',
                }
            }
        },
        methods: {
            //changeLang(language) { localStorage.setItem('language_web', language)},
            async loginFunc(email, password) { 
                if (!await this.login(email,password)) {
                    return this.error = true;
                }
                return this.error = false;
            },
            async forgotFunc(email) { 
                await this.forgot_password(email)
            },
        },
        mounted() {
            this.metaInfo("Login", this.$route.path)

            this.description.register = this.trans('register_parahraph');
            this.description.login = this.trans('login_parahraph');
        }
    }
</script>

<style lang="scss" scoped>

.login-view {
    display:grid;
    gap:7rem;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 100px
} 

img {
    display:block;
    width:100%;
    object-fit:contain;
    object-position: bottom right;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1128px){

    .login-view {
        display:flex;
        justify-content: center;
        ::v-deep {
            h3 {
                margin-left:1rem;
            }
            .container {
                width:100vw;
                height:auto;
                padding:2rem 1rem;
                border-radius: 0;
                box-sizing: border-box;
                display:flex;
                flex-direction: column;
                gap:1rem;
            }

            input {
                width:100%;
                font-size:16px;
                height:2.5rem;
            }
        }
    }

    .col-1 {
        display:none;
    }

    .col-3 {
        display:none;
    }
}
</style>