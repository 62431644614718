<template>
<div class="flex flex-col items-center w-full h-full">
    <svg id="emzr4dncO7M1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 122 122" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><g clip-path="url(#emzr4dncO7M6)"><g><path d="M0,122.335L0,0h122.35v122.335h-122.35ZM27.2845,11.2639h-16.0054v16.0206c4.2772-6.2865,9.7189-11.7282,16.0054-16.0206ZM11.2791,95.0278v16.0432h16.0206c-6.2865-4.293-11.7434-9.734-16.0206-16.0432ZM95.0199,111.071h16.0441v-16.0432c-4.293,6.3092-9.758,11.7502-16.0441,16.0432Zm.0228-99.7995c6.2863,4.2924,11.7283,9.7341,16.0213,16.0206v-16.0206h-16.0213ZM111.064,61.206523c0-25.898953-16.088799-49.935023-49.8967-49.935023s-49.8882,22.552804-49.8882,49.935023s21.073834,49.864477,49.7209,49.864477s49.980235-23.965524,50.064-49.864477Z" transform="translate(0-.335)" clip-rule="evenodd" :fill="color" fill-rule="evenodd"/></g><clipPath id="emzr4dncO7M6"><rect width="122" height="122" rx="0" ry="0" fill="#fff"/></clipPath></g><path id="forward"  d="M12.2152,59.4998c9.587087,1.262413,7.35864,6.518664,15.069299,6.518664s9.423023-6.518664,17.2155-6.518664s7.926778,6.518664,16.675,6.518664s7.324937-6.518664,16.827855-6.518664s8.11678,6.518664,16.422246,6.518664c6.370021,0,10.269305-5.922802,15.7099-6.518664" transform="translate(.0749-1.101957)" fill="none" :stroke="color" stroke-width="10" stroke-linecap="round" stroke-dashoffset="107.883464" stroke-dasharray="107.883464"/></svg>
    <!-- <span :style="`color: ${color}`" class="w font-semibold">{{trans('loading')}}</span> -->
</div>
</template>

<script>
export default {
    name: 'Loading',
    props: ['color'],
    data(){
        return {
            running: false
        }
    },
    methods: {
        animate() {
            const line = document.getElementById("forward")
            if(!line) return
            const current = line.getAttribute('stroke-dashoffset')
            line.setAttribute('stroke-dashoffset', Number(current) - 5)
            if (this.running) setTimeout(this.animate, 50)
        },
    },
    mounted(){
        this.running = true
        this.animate()
    },
    beforeDestroy(){
        this.running = false
    }
}
</script>