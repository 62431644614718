<template>
<div>
    <div class="serviceLogList">
        <!-- List -->
        <div class="list">
            <h3>{{trans('maintenance_&_service_log')}}</h3>
            <div class="comp-container">
                <div class="log flex d-col" v-for="l in filtered_logs" :key="l.id" data-log-container>
                <div :class="role === 'admin' || l.author === new_report_author ? 'cursor-pointer' : ''" @click="expand($event.target, l.author); editing = undefined">
                     <div class="title flex items-center gap-2 justify-between px-2">
                        <div class="flex gap-4">
                            <span class="">{{displayDate(l.timestamp, 'date') || '-'}}</span>
                            <span class="flex items-center gap-2 font-bold">{{trans(l.type) || '-'}} - <span class="text-xs font-normal">{{l.author.length > 25 ? l.author.slice(0, 25) + '...' : l.author}}</span></span>
                        </div>
                        <div v-if="role === 'admin' || (l.author == new_report_author)" class="down-arrow"></div>
                    </div>
                </div>
                    <div class="log-content">
                        <div class="flex justify-between mb-1">
                            <div class="flex flex-col">
<!--                            <div class="flex" style="align-items: center; justify-content: space-between">
                                    <div class="author">{{trans('done_by')}} <span class="author-name">{{l.author || 'anonymous'}}</span></div>
                                </div> -->
                                <div class="text-gray-500" v-if="l.edited">{{trans('edited by')}} <span class="author-name text-gray-500">{{l.edited.author}} {{trans('at')}} {{displayDate(l.edited.timestamp)}}</span></div>
                            </div>
                            <button v-if="['admin', 'craftsman'].includes(role)" :class="editing !== l.id ? 'edit-icon' : 'check-icon'" @click="editLog(l)"></button>
                        </div>
                        <textarea :disabled="editing !== l.id || !['admin', 'craftsman'].includes(role)" class="w-full box-border h-auto" :style="`${editing !== l.id ? '' : 'border-color: var(--blue)'}; border-top-right-radius:0; border-bottom-right-radius:0; overflow-y:scroll;`" :value="editing_message ? editing_message : l.edited?.message ? l.edited.message : l.message" @input="(event) => editing_message = event.target.value"/>
                        <div class="flex gap-2 m-2 overflow-x-auto">
                            <div @click="$refs[l.id][0].click()" v-if="editing == l.id" class="upload-img-icon w-12 h-12 rounded-xl cursor-pointer" style="min-width: 3rem;">
                                <input class="mt-2 hidden" type="file" multiple accept=".jpeg,.jpg,.png" :ref="l.id" @input="addImagesToLocal($event)" />
                            </div>
                            <div v-for="img in (editing != l.id ? l.images : local_images)" id="fullscreen-wrapper" class="flex justify-center relative" :key="img">
                                <div v-if="editing == l.id" class="absolute w-full h-full hover:opacity-100 opacity-0 rounded-xl cursor-pointer remove-small-image" @click="removeServiceLogImage(img)" />
                                <img v-if="editing == l.id" @click="toggle($event)" :src="img" :class="fullscreen ? 'w-auto h-full rounded-xl cursor-pointer' : 'w-12 h-12 rounded-xl cursor-pointer'" />
                                <ImagePreview v-else preview :src="img" imageClass="w-12 h-12 cursor-pointer bg-contain" class="overflow-hidden w-12 h-12 rounded-xl"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Report service or repair -->
        <div class="report" v-if="['admin','craftsman'].includes(role)">
            <h3>{{trans('report_service_or_repair')}}</h3>
            <div class="comp-container report-container">
                <div class="content flex d-col">
                    <div class="radio flex items-center">
                        <div class="service flex cursor-pointer" @click="$refs.service.click()">
                            <input class="pointer-events-none" :disabled="pushing_new_log" type="radio" name="radio" value="service" v-model="new_report_type" ref="service"><label class="cursor-pointer" for="service">{{trans('service')}}</label>
                        </div>
                        <div class="repair flex cursor-pointer" @click="$refs.repair.click()">
                            <input class="pointer-events-none" :disabled="pushing_new_log" type="radio" value="repair" v-model="new_report_type" name="radio" ref="repair"><label class="cursor-pointer" for="repair">{{trans('Repair')}}</label>
                        </div>
                            <div class="note flex cursor-pointer" @click="$refs.note.click()">
                            <input class="pointer-events-none" :disabled="pushing_new_log" type="radio" value="note" v-model="new_report_type" name="radio" ref="note"><label class="cursor-pointer" for="note">{{trans('note')}}</label>
                        </div>
                    </div>
                    <date-picker :disabled="pushing_new_log" id="datepicker" v-model="new_report_date" :placeholder="trans('service_date')" ref="date" @input="removeRequired($event)"></date-picker>
                    <textarea :disabled="pushing_new_log" v-model="new_report_text" name="notes" :placeholder="getDescriptiveText(new_report_type)" ref="text" @input="removeRequired($event)"></textarea>
                    <div class="flex items-center justify-between mt-4">
                        <div class="flex gap-2 items-end">
                            <div class="upload-img-icon w-12 h-12 rounded-xl cursor-pointer" @click="$refs.image.click()">
                                <input :disabled="pushing_new_log" class="mt-2 hidden" type="file" multiple accept=".jpeg,.jpg,.png" ref="image" @input="checkFileLength($event)" />
                            </div>
                            <span v-if="new_service_log_images_length">{{new_service_log_images_length > 1 ? `${new_service_log_images_length} images` : `${new_service_log_images_length} image`}}</span>
                        </div>
                        <button :disabled="pushing_new_log" class="btn submit" @click="push()">{{trans('submit')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.remove-small-image {
    transition: 100ms ease-in-out all;
    background-image:url('../../assets/remove-filled.svg');
    background-size:contain;
    background-repeat:no-repeat;
}

.upload-img-icon {
    background-image:url('../../assets/upload-image.svg');
    background-size:contain;
    background-repeat:no-repeat;
}

.img-icon {
    background-image:url('../../assets/image.svg');
    width:22px;
    height:22px;
    background-size:cover;
}

.edit-icon {
    background-image:url('../../assets/edit-mimbox.svg');
    width:18px;
    height:18px;
    background-size:cover;
}

.check-icon {
    background-image:url('../../assets/check.svg');
    width:18px;
    height:18px;
    background-size:contain;
    background-repeat:no-repeat;
}

.rounded-mimbly {
    border-radius: 22px
}

.comp-container {
    height:300px;
    min-height:0;
    max-height: 300px;
    padding:0;
    text-align:left;
    overflow-y:auto;
}

h3 {
    margin-top:1rem;
    text-align:left;
}

/* ########## List ########### */
.log {
    position: relative;
    height:2.3rem;
    overflow:hidden;
    transition:250ms ease-in-out;
}

.log.active {
    height:17rem;
}

.log::after {
    content:'';
    width:90%;
    height:var(--border-width);
    background:var(--lightgray);
    position: absolute;
    bottom:0;
    left:5%;
}

.title {
    height: 3rem;
}

.date,.name,.icon {
    position: absolute;
    top:1rem;
}

.date {
    left:1rem;
}

.name {
    left:50%;
    font-weight: 700;
    color:var(--black)
}

.icon {
    right:1rem;
    transform:translateY(-.25rem)
}

.log-content {
    position:absolute;
    top:2.5rem;
    box-sizing: border-box;
    padding:1rem;
    white-space: pre-wrap;
    height:inherit;
    width:100%;
    padding-bottom:3rem;
    font-family: 'Source Sans Pro', sans-serif;

}

/* ######### Report ######### */
.report-container {
    height:330px;
    max-height:330px;
    overflow:hidden;
}

.content {
    width:80%;
    margin:0 auto;
}

.radio {
    gap:1rem;
    margin:1rem 0;
    div {
        gap:.5rem;
    }
    input {
        cursor:pointer;
    }
}

#datepicker {
    margin-bottom:1rem;
    flex-grow:0;
    max-width:max-content;
}

textarea {
        appearance: none;
        box-shadow:none;
        background:none;
        height:100px;
        flex-grow:1;
        background: var(--white);
        border: 1.5px solid var(--lightgray);
        border-radius: 22px;
        padding:1rem;
        resize:none;
        font-size:1rem;
        font-family: 'Source Sans Pro', sans-serif;
        margin:0;
}
textarea:focus {
    outline:none;
}

.submit {
    max-width:120px;
    padding:.5rem .5rem;
    margin-top:1rem;

}

.report {
    margin-top:1rem;
}
</style>

<script>
import { api as fullscreen } from 'vue-fullscreen'
import ImagePreview from 'primevue/imagepreview'

export default{
    name: "serviceLogList",
    components: {
        ImagePreview
    },
    data(){
        return {
            filtered_logs: this.role == 'customer' ? this.sortEventsByDate(this.log.filter(l => l.type === 'maintenance')) : this.log,
            new_report_type: 'note',
            new_report_date: undefined,
            new_report_text: "",
            new_report_author: undefined,
            editing: undefined,
            editing_message: undefined,
            fullscreen: false,
            pushing_new_log: false,
            new_service_log_images_length: 0,
            local_images: [],
            ready_to_upload_edited: []
        }
    },
    props: ["log", "role"],
    watch: {
        log(){
            if(this.role === 'customer') this.filtered_logs = this.sortEventsByDate(this.log.filter(l => l.type === 'maintenance'))
            else
            this.filtered_logs = this.sortEventsByDate(this.log)
        },
    },
    methods:{
        getDescriptiveText(type) {
            switch(type) {
                case "service":
                    return this.trans('service_log_description')
                case "repair":
                    return this.trans('repair_log_description')
                case "note":
                    return this.trans('note_log_description')
            }
        },
        removeRequired(event){
            if(!event) return
            if(event.toString() === '[object InputEvent]') this.$refs.text.classList.remove('red')
            else this.$refs.date.$el.classList.remove('red')
        },
        async uploadImages(imgArr){
            let links = []
            imgArr = Array.from(imgArr)
            for (const img of imgArr) {
                let n = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
                const link = await this.uploadImage(this.$route.params.unit_id, n, img)
                links.push(link)
            }
            return links
        },
        async push(){
            this.pushing_new_log = true
            this.new_service_log_images_length = 0
            const links = await this.uploadImages(this.$refs.image.files)
            if(!this.new_report_date) this.$refs.date.$el.classList.add('red');
            if(this.new_report_text === '') this.$refs.text.classList.add('red');
            if(!this.new_report_date || !this.new_report_text) return
            await this.pushNewServiceLog(this.$route.params.unit_id, {
                timestamp: this.new_report_date.getTime(),
                message:this.new_report_text,
                type:this.new_report_type ?? 'service',
                author:this.new_report_author ?? 'unknown',
                images: links
            })
            this.$refs.image.value = ''
            this.new_report_type = 'service'
            this.new_report_text = ""
            this.pushing_new_log = false
        },
        expand(e, author) {
            if(this.role === 'craftsman' && author !== this.new_report_author) return
            e.closest('[data-log-container]').classList.toggle('active')
            e.closest('[data-log-container]').querySelector('.down-arrow').classList.toggle('active')
        },
        addImagesToLocal({target: { files }}){
            const placeholders = Array.from(files).map((file, i) => URL.createObjectURL(files[i]))
            this.local_images = [...this.local_images, ...placeholders]
            this.ready_to_upload_edited = [...this.ready_to_upload_edited, ...files]
        },
        async editLog({ id }) {
            const currLog = this.filtered_logs.filter(log => log.id === id)[0];

            const rightNow = new Date().getTime();

            if(this.ready_to_upload_edited.length > 0 && id === this.editing) {
                let newImages = await this.uploadImages(this.ready_to_upload_edited)
                this.local_images = this.local_images.filter(img => !img.includes('blob')).concat(newImages)
                this.ready_to_upload_edited = []
            }

            switch(id === this.editing) {
                case true:
                    this.editing = undefined;
                    if((this.editing_message === currLog.message || this.editing_message === currLog.edited?.message) && (this.local_images.length == currLog.images.length && this.ready_to_upload_edited.length < 1)) {
                        this.editing_message = undefined
                        this.editing = undefined
                        this.local_images = []
                        return
                    }
                    currLog.images = this.local_images
                    this.patchServiceLog(this.$route.params.unit_id, id, {
                        timestamp: rightNow,
                        message: this.editing_message,
                        author: this.new_report_author,
                        images: this.local_images
                    })
                    this.editing_message = undefined
                    break
                default:
                    this.editing_message = currLog.edited?.message ?? currLog.message;
                    this.editing = currLog.id;
                    this.local_images = currLog.images
            }
        },
        removeServiceLogImage(image){
            // const currLog = this.filtered_logs.filter(log => log.timestamp === timestamp)[0];
            const newImages = this.local_images.filter(img => img !== image)
            this.local_images = newImages
        },
        checkFileLength(e){
            this.new_service_log_images_length = e.target.files?.length ?? 0
        },
        async toggle (e) {
            await fullscreen.toggle(e.target.closest('#fullscreen-wrapper'))
            this.fullscreen = !this.fullscreen
        },
    },
    async mounted(){
        this.new_report_date = new Date()
        let user = await this.getUser()
        this.new_report_author = user.email
    }
}
</script>