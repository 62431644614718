<template>
  <div class="deinstallation">
      <div class="comp-container" :class="{show: open}" @click="open = !open">
          <div class="label flex">
              <div class="icon de-install"></div>
              <h3>{{trans('deinstallation')}}</h3>
          </div>
          <div class="desc" >
            {{trans('deinstall_mimboxes_description')}} 
          </div>
          <div class="buttons flex content-center items-center">
            <button class="btn reset" @click="clear">Clear</button>
              <button class="btn reset bg-orange-400" @click="reset">{{trans('reset')}}</button>
              <button class="btn delete btn-error" @click="del">{{trans('delete')}}</button>
          </div>
      </div>
  </div>
</template>

<script>
import { fb } from '../../main.js'

export default {
    name: 'Deinstallation',
    props: ['unit'],
    data() {
        return {
            show: false,
            open: false
        }
    },
    methods: {
        clear(){
            this.$confirm.require({
                message: 'This action will clear only the installation information and all images. The service log, event log, error log etc will be unaffected. Proceed?',
                header: 'Clear',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.$toast.add({ severity: 'info', summary: 'Clear', detail: 'Installation info cleared', life: 3000 });
                    fb.firestore().collection('mimboxes').doc(this.$props.unit.id).set({
                        install: {
                            address: null,
                            date: null,
                            installed: false,
                            name: null,
                            phone: null
                        },
                        images: []
                    }, { merge: true })
                },
            });
        },
        del() {
            this.$confirm.require({
                message: 'This action will delete the mimbox. Proceed?',
                header: 'Delete',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.$toast.add({ severity: 'info', summary: 'Delete', detail: 'Data deleteion in progress', life: 3000 });
                    const functions = fb.app().functions('europe-west1')
                    const { data: code} = await functions.httpsCallable('archive_unit')({ muid: this.$props.unit.id, delete: true })
                    switch (code) {
                        case 201:
                            this.$router.push('/')
                            this.$toast.add({ severity: 'success', summary: 'Reset', detail: 'Data deletion complete', life: 3000 });
                            break;
                        case 500:
                            this.$toast.add({ severity: 'error', summary: 'Reset', detail: 'Data deletion failed', life: 3000 });
                            break;
                    }
                },
            });
        },
        reset() {
            this.$confirm.require({
                message: 'This action will reset mimbox data (but keep the MUID representation of the physical mimbox in the database). Proceed?',
                header: 'Reset',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.$toast.add({ severity: 'info', summary: 'Reset', detail: 'Data archive and reset in progress', life: 3000 });
                    const functions = fb.app().functions('europe-west1')
                    const { data: code } = await functions.httpsCallable('archive_unit')({ muid: this.$props.unit.id })
                    switch (code) {
                        case 201:
                            this.$toast.add({ severity: 'success', summary: 'Reset', detail: 'Data archive and reset complete', life: 3000 });
                            break;
                        case 500:
                            this.$toast.add({ severity: 'error', summary: 'Reset', detail: 'Data archive and reset failed', life: 3000 });
                            break;
                    }
                    
                },
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.p-component {
    width:500px;
}

.deinstallation {
    text-align: left;
    padding-top:1rem;
}
.comp-container {
    padding:20px;
    box-sizing:border-box;
    overflow:hidden;
    transition:250ms ease-in-out;
    cursor:pointer;
    max-height:70px;
}

.desc {
    margin-top:30px;
    font-family: 'Source sans pro', sans-serif
}

.show {
    max-height:280px;
}

.buttons {
    gap:3rem;
    height:max-content;
    width:100%;
    margin-top:30px;
    margin-bottom:0rem;
}

h3 {
    margin-left:1rem;
    margin-bottom:0;
}

.de-install {
    background-image:url('../../assets/deinstallation.svg');
    background-size:cover;
    width:27px;
    height:27px;
}
</style>