<template>
    <div class="modal-view">
        <div class="modal-wrapper">
            <div class="large-modal-container">
                <div class="modal-body">
                    <div class="modal-content">
                        <a href="https://www.mimbly.se/" >Mission</a>
                        <a href="https://www.mimbly.se/products">Products</a>
                        <a href="https://www.mimbly.se/team">About us</a>
                        <a href="https://www.mimbly.se/">Min Sida</a>
                    </div>
                </div>
            </div>
        </div>
        <div id="modalOutsideExit" class="modal-background" @click="$emit('close')"></div>
    </div>
</template>

<script>
    export default {
        name: "modalMenu",
        props: [  ],
        data() {
            return {

            }
        },
         methods: {
            close() { this.$emit("close") },
        },
    }
</script>