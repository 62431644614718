<template>
    <div class="filter blue-box" :class="{inactive:itemsArr.length < 1}" data-dropdown>
        <div class="label">
            <span>{{trans(label)}}</span><div v-if="itemsArr.length > 0" class="icon down-arrow"></div>
        </div>
        <div class="items" data-dropdown-list>
            <div v-for="item in itemsArr" :key="item" class="item" @click="$emit('toggleFilter',item,label)"><span>{{item}}</span><div v-if="selItem.includes(item)" class="icon check"></div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: {
        label: {
            type: String,
            default: 'Dropdown'
        },
        itemsArr: {
            type: Array,
            default: () => []
        },
        selItem: {
            type: Array,
            default: () => []
        },
    },
}
</script>

<style scoped lang="scss">
* {
    --width: var(--filter-width)
}

//dropdown
.items{
    position:absolute;
    left:calc(var(--border-width) * -1 + 1px);
    top:calc(var(--filter-height)/2);
    width:var(--width);
    height:max-content;
    overflow: hidden;
    display:flex;
    flex-direction:column;
    z-index:-1;
    .item {
      border: calc(var(--border-width)/2) solid var(--blue);
      font-family: 'Source sans pro', sans-serif;
      box-sizing: border-box;
      border-top:none;
      background:white;
      width:100%;
      max-height:5rem;
      cursor:pointer;
      position:relative;
      display:flex;
      align-items:center;
      overflow:hidden;
      span{
          margin-left:0;
          margin:.5rem 1rem;
          margin-right:2rem;
          max-height:4rem;
          overflow:hidden;
      }
    }
    div:first-of-type {
        padding-top:calc(var(--filter-height)/2 + .25em);
        .check{
            top:calc(var(4em)/2 + .5em);
            transform:none;
        }
    }
    div:last-of-type {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
    transition: 100ms ease-in-out;
    transform-origin:top;
    transform: scaleY(0);
    opacity:0;
  }


.filter{
  width:var(--width);
  flex-shrink:0;
  flex-grow:0;
  margin-right:1.5em;
  position:relative;
  cursor:pointer;
  font-size:14px;
  .label{
    position: relative;
    width:90%;
    height:100%;
    display:flex;
    align-items:center;
    margin-left:calc(var(--border-radius) + .25em);
    pointer-events:none;
  }
}

.icon {
    position:absolute;
    right:-8px;
    top:0;
    width:max-content;
}

.down-arrow.active {
    transform: translateY(-5%) rotate(180deg)
}

.check {
    width:15px;
    height:10px;
    background:url('../assets/check.svg') no-repeat;
    top:50%;
    right:var(--filter-padding);
    transform:translateY(-20%);
}

.items.active {
    transform: scaleY(1);
    opacity:1;
}
</style>