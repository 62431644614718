<template>
<div class="get-support">
  <h3>{{trans('get_support')}}</h3>
  <div class="comp-container support-info flex">
      <div class="number flex items-center"><div class="icon phone"></div><span><a :href="`tel:${trans('mimbly_support_phone_number')}`">{{trans('mimbly_support_phone_number')}}</a></span></div>
      <div class="mail flex items-center"><div class="icon email"></div><span><a href="mailto:support@mimbly.se">support@mimbly.se</a></span></div>
  </div>
</div>
</template>

<script>
export default {
    name: 'getSupport'
}
</script>

<style scoped lang="scss">
.get-support {
    color: var(--blue);
    font-weight:700;
}


h3 {
    text-transform: none;
}

.support-info {
    padding: 1.5rem 1rem;
    flex-direction:column;
    justify-content: center;
    gap:1em;
    .number, .mail {
        span {
            color: var(--blue)
        }
    }
}

.icon {
    height:21px;
    aspect-ratio:1;
    display: inline-block;
    margin-right:1rem;
}

.phone {
    background-image:url('../assets/phone.svg');
}

.email {
    background-image:url('../assets/email.svg');
}



</style>