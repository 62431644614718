<template>
    <div class="singleUnitEvents">
        <div class="event-container">
            <h3>{{trans('event_log')}}</h3>
            <div class="comp-container flex">
                <date-picker id="datepicker" v-model="range" range :placeholder="trans('filter_time')"></date-picker>
                <button class="btn" @click="download2()">{{trans('download')}}</button>
                <!--
                <div class="event-item" v-for="event in filtered_events" :key="event.id" @click="expand($event.target)" data-event-container>
                        <span class="date">{{displayDate(event.timestamp, 'date') || '-'}}</span>
                        <span class="noti-time">{{displayDate(event.timestamp, 'hour') || '-'}}</span>
                        <span class="name">{{trans(event.type) || '-'}}</span>
                        <div class="icon upper-arrow"></div>
                        <div class="content">
                            <div v-for="(value, key) in event" class="timestamp" :key="key">
                                <span>{{trans(key)}}</span>
                                <span>{{value}}</span>
                            </div>
                        </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: "singleUnitEvents",
    data(){
        return {
            filtered_events: [],
            range: []
        }
    },
    props: ["events_prop", "unit"],
    watch: {
        events_prop(){
            this.filt()
        },
        range(){
            this.filt()
        }
    },
    methods: {
        filt(){
            let temp = new Date(0)
            let f = this.range[0] ? new Date(this.range[0]) : temp
            let t = this.range[1] ? new Date(this.range[1]) : new Date(1922513762000) // this is the year 2030
            let event_temp_list = []
            this.events_prop.forEach(e => {
                if(e.timestamp > f.getTime() && e.timestamp < t.getTime())
                    event_temp_list.push(e)
            })
            this.filtered_events = this.sortEventsByDate(event_temp_list)
        },

        async download2(){
            let csv = ""
            let items = await this.getEvents(this.unit.id, this.range[0]?.valueOf(), this.range[1]?.valueOf())
            let keysAmount = 0
            let all_keys = []
            for(let row = 0; row <= items.length; row++){
                let keysCounter = 0

                // If this is the first row, generate the headings
                if(row === 0){

                    // Loop each property of the object
                    for(let i of items){
                        all_keys.push(Object.keys(i))
                    }
                    all_keys = [...new Set(all_keys.flat())] // TODO: if you would like to have a set order, and set keys add them here to to all_keys
                    all_keys.sort() // all the keys will be sorted in alfabetical order
                    keysAmount = all_keys.length
                    for(let key of all_keys){
                        // This is to not add a comma at the last cell
                        // The '\n' adds a new line
                        csv += key + (keysCounter+1 < keysAmount ? ',' : '\n' )
                        keysCounter++
                    }
                }else{
                    for(let key of all_keys){
                        let value = items[row-1][key] ?? ""
                        if(typeof value === "object") {value = JSON.stringify(value).replaceAll(",","_")}
                        if(typeof value === "number" && value > 1662000000000) {value = new Date(value).toString()}

                        csv += value + (keysCounter+1 < keysAmount ? ',' : '\n' )
                        keysCounter++
                    }
                }
                keysCounter = 0
            }
            // Once we are done looping, download the .csv by creating a link
            let link = document.createElement('a')
            link.id = 'download-csv-'+this.unit.id
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
            link.setAttribute('download',`${this.unit.name} events.csv`);
            document.body.appendChild(link)
            document.querySelector('#download-csv-'+this.unit.id).click()
            document.body.removeChild(link)
        },
        expand(e) {
            e.closest('[data-event-container]').classList.toggle('active')
            e.closest('[data-event-container]').querySelector('.upper-arrow').classList.toggle('active')
        },
    },
    mounted(){
        this.filt()
    }

}
</script>

<style lang="scss" scoped>
.comp-container {
    max-height: 450px;
    overflow:auto;
    padding:1rem;
    gap:1rem;
    align-items:center;
    justify-content: flex-start;
}

#datepicker {
    margin-bottom:0;
}

.event-item {
    width:100%;
    height:2rem;
    max-height:max-content;
    text-align:left;
    position:relative;
    flex-shrink:0;
    overflow:clip;
    cursor:pointer;
    transition: 250ms ease-in-out;
    border-bottom:var(--border-width) solid var(--lightgray);
}

.event-item.active {
    height:15rem;
}

.content {
    position:absolute;
    top:2rem;
    left:30%;
    right:1rem;
    height:13rem;
    overflow-y:auto;
    div {
        position:relative;
        display:flex;
        justify-content: space-between;
        margin-bottom:.5rem;
    }

}

.content div::after {
    content:'';
    position: absolute;
    left:0;
    bottom:0;
    width:100%;
    height:var(--border-width);
    background:var(--lightgray)
}

.date,.noti-time,.name, .upper-arrow {
    position:absolute;
    top:1rem;
    transform:translateY(-50%);
    pointer-events:none;
    transition:250ms ease-in-out;
}

.upper-arrow.active {
    transform:translateY(-50%) rotate(180deg);
}

.date {
    left:2%;
}

.noti-time {
    left: 20%
}

.name {
    left: 40%;
    font-weight: 700;
}

.upper-arrow {
    background-image: url('../../assets/upper-arrow.svg');
    right:1rem;
}
</style>

<!-- mobile -->
<style lang="scss" scoped>
@media screen and (max-width: 1128px){
    .comp-container {
        max-height:200px;
        height:200px;
    }

    .date {
        display:none;
    }

    .noti-time {
        left:.5rem;
    }

    .event-item {
        overflow: hidden;
        max-height:none;
    }
}
</style>