<template>
<div class="unitFilter">
    <h3>{{trans('filter_your_selection')}}</h3>
        <!-- desktop -->
        <show-at breakpoint="mediumAndAbove">
            <div class="filter-container flex">
                <div class="dropdowns-container" v-for="section, index in possibleFilter" :key="index">
                <!-- if a checkbox needs to show up on click, pass in an array matching the item or items in the list -->
                    <Dropdown :label="section.key" :itemsArr="section.options" :selItem="filters[section.key]" @toggleFilter="toggleFilter"/>
                </div>
                <div class="search-container">
                    <input v-model="searchString" type="search" name="search" class="blue-box search" :placeholder="trans('search')">
                    <div v-if="!searchString || searchString == ''" class="icon search-icon"></div>
                </div>
            </div>
        </show-at>
        <!-- mobile -->
        <hide-at breakpoint="mediumAndAbove">
            <carousel class="filter-container flex" :scrollPerPage="false" :spacePadding="20" :spacePaddingMaxOffsetFactor="-12" :paginationEnabled="false">
                <slide class="dropdowns-container" v-for="section, index in possibleFilter" :key="index">
                        <!-- if a checkbox needs to show up on click, pass in an array matching the item or items in the list -->
                            <Dropdown :label="section.key" :itemsArr="section.options" :selItem="filters[section.key]" @toggleFilter="toggleFilter" />
                </slide>
                    <slide class="search-container">
                        <input v-model="searchString" type="search" name="search" class="blue-box search" :placeholder="trans('search')">
                        <div class="icon search-icon"></div>
                    </slide>
            </carousel>
        </hide-at>
    <!-- list -->   
    <div class="filter-list">
        <span class="clear" v-if="Object.keys(this.filters).length > 0" @click="searchString = '';filters = {}; filterNames=[]; filter()">{{trans('clear_all')}}</span>
        <div class="filter-name flex items-center" v-for="filter in filterNames" :key="filter.name" @click="toggleFilter(filter.name, filter.section)"><div class="cross" v-if="filter"></div>{{filter.name}}</div>
    </div>
</div>
</template>

<script>

export default{
    name: "unitFilter",
    data(){
        return {
            filters:{},
            searchString: "",
            filterNames: []
        }
    },
    props: ["possibleFilter"],
    watch:{
        searchString(){
            if(this.searchString){
                this.filters.search = this.searchString
            }
            else{
                delete this.filters.search
            }
            this.filter()
        },
    },
    methods:{
        filter(){
            let data = {}
                for(let key of Object.keys(this.filters)){
                    if(this.filters[key] != null){
                        if(data[key])
                            data[key].push(this.filters[key])
                        else
                            data[key] = this.filters[key]
                    }
                }
            this.$emit('filtered', data)
        },
        toggleFilter(filter, section){
            if(!filter){
                delete this.filters[section]
                this.filter()
            }
            else if((this.filters[section] && this.filters[section].includes(filter)) || section === "search"){
                if(section === "search")
                this.searchString = ""
                let index = this.filters[section].indexOf(filter)
                this.filters[section].splice(index, 1)
                if(this.filters[section].length === 0){
                    delete this.filters[section]
                }
                this.filter()
            }else if(this.filters[section]){
                this.filters[section].push(filter)
                this.filter()
            } else {
                this.filters[section] = [filter]
                this.filter()
            }
            //print names of the filters
            if(section !== 'search'){
                this.filterNames = [];
                for (let key in this.filters) {
                    this.filters[key].forEach(e => this.filterNames.push({name: e, section: key}))
                }
            }
        },
        
    },
}
</script>

<!-- desktop -->
<style lang="scss" scoped>
.unitFilter{
    display:flex;
    flex-direction:column;
}


//layout
h3{
    margin:0;
    margin-bottom:1rem;
}

/*
1. Filter styles
*/

.filter-container{
    display:flex;
    margin-bottom:.5rem;
}

/*
2. Generic/other styles
*/
.search-container {
    flex-grow: 1;
    position: relative;
    max-width:20rem;
}

.search {
    padding-left:.5rem;
    width:100%;
    min-width:8rem;
    font-weight:500;
    color:var(--blue)
}

.search:focus {
    filter: brightness(1.1)
}

.search::placeholder {
    opacity:1;
    color:var(--blue)
}

/*
3. Icon styles
*/
.icon {
    position:absolute;
    right:0;
    top:0;
}

.check {
    width:15px;
    height:10px;
    background:url('../../assets/check.svg') no-repeat;
    top:50%;
    right:var(--filter-padding);
    transform:translateY(-20%);
}

.active {
    transform: scaleY(1);
    opacity:1;
}

.filter-list {
    position: relative;
    z-index:-2;
    display:flex;
    gap:1rem;
    color: var(--blue);
    .clear{
        text-decoration: underline;
        cursor: pointer;
        color:var(--blue);
    }
    .filter-name{
        margin-left:1.5rem;
        cursor: pointer;
        color:var(--blue)
    }
    .cross{
        display: inline-block;
        background:url('../../assets/cross.svg');
        width:13px;
        height:13px;
        margin-right: .5rem;
    }
}

.search-icon {
    right:.5rem;
    top:50%;
    transform:translateY(-50%);
    width:18px;
    aspect-ratio: 1;
    background-position: center;
    background-image:url('../../assets/search.svg');
}
</style>

<!-- Mobile -->
<style lang="scss" scoped>

@media screen and (max-width:1228px){
    .unitFilter {
        padding-bottom:2rem;
    }

    .filter-list {
        position:absolute;
        top: calc(var(--filter-height) * 2 + 2rem);
    }

    .filter-container {
        width:90vw;
        ::v-deep .VueCarousel-wrapper{
            height:var(--filter-height);
        }
    }

    .filter-container.expanded{
        ::v-deep .VueCarousel-wrapper {
            height: 20rem;
            min-height:20rem;
        }
    }
    .dropdowns-container {
        ::v-deep * {
            --width:170px;
        }
        ::v-deep .filter {
            font-size:16px;
        }
    }
    
    .search-icon {
        top:.8rem;
    }
}
</style>
