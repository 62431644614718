import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import "firebase/compat/functions";
import 'firebase/compat/storage';
// const functions = getFunctions(firebaseApp);


export const auth = {

	data() {
		return {
            fbListeners : [],
            trans_listener: false,
            invited_data: false,
		}
	},
	methods: {
        async asyncLocalStorageSet(key, value){
            await null;
            return localStorage.setItem(key, value);
        },
        async login(email, password){
            let response;
            try{
                response = await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
            }catch(error){
                return false
            }
            await this.asyncLocalStorageSet("user_id", response.user.uid)
            let user = await firebase.firestore().collection('users').doc(response.user.uid).get()
            await this.asyncLocalStorageSet("client", user.data().client)
            await this.asyncLocalStorageSet("role", user.data().role)
            return true
        },

        async getUser(email){
            let snapshot;
            if(email) {
                try {
                    let reducer = false
                    snapshot = await firebase.firestore().collection("users").where('email', '==', email).get()
                    snapshot.forEach(doc => reducer = doc.data().email == email)
                    return reducer ? 'exists' : 'original'
                } catch (error) {
                    console.error(error)
                    return 'original'
                }
            }
            try {
                snapshot = email && snapshot[0] ? snapshot[0] : await firebase.firestore().collection("users").doc(localStorage.getItem("user_id")).get()
                return snapshot.data()
            } catch(e){
                console.error(e)
            }
        },

        async postUser(data){
            delete data.id
            delete data.client
            delete data.email
            let database = firebase.firestore().collection("users").doc(localStorage.getItem("user_id"))
            await database.set(data,  {merge: true})        
        },
        
        logout() {
            this.clear()
            var vm = this
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear()
                })
                .catch(error => {
                    vm.error = error
                })
        },
        async register(email, password, userData, delete_id) {
            let response = await firebase.auth().createUserWithEmailAndPassword(
                email,
                password
            )
            console.log("SET UP USER WITH THIS DATA: ", userData)
            Object.keys(userData).forEach(key => {
            if (userData[key] === undefined) {
                delete userData[key];
            }
            });
            await firebase.firestore().collection('users').doc(response.user.uid).set({
                ...{email: response.user.email,
                id: response.user.uid},
                ...userData
            });
            await firebase.firestore().collection('invited').doc(delete_id).delete()
            
            // let user = await firebase.firestore().collection('users').doc(response.user.uid).get()
            this.logout()
            // await this.asyncLocalStorageSet("user_id", response.user.uid)
            // await this.asyncLocalStorageSet("client", user.data().client)
            // await this.asyncLocalStorageSet("role", user.data().role)
        },
        async invite(userData){
            userData.timestamp = Date.now() - (1000*60*60*24)
            let ref = firebase.firestore().collection("invited")
            let id = await ref.add(userData)
            // let link = "https://mimblysoftware.web.app/#/register/"+id.id
            let link = "https://mimboard.mimbly.se/#/register/"+id.id
            const functions = firebase.functions();
            const invite_mail = functions.httpsCallable("invite_mail")
            let result = await invite_mail({ link: link, mail:userData.email })
            return result.data
        },
        getInvited(id){
            let vm = this
            let database = firebase.firestore().collection("invited").doc(id)
            database.get().then(snapshot => {
                vm.invited_data = snapshot.data()
            },error => {console.log("getInvited ERROR: ",error)})
        },
        async updateUser(data){
            let id = localStorage.getItem('user_id');
            if(!id){
                alert("Oops an error uccured, please try login and logout again")
                return false
            }
            data = this.delete_all_undefined(data)
            let ref = firebase.firestore().collection("users").doc(id);
            if(data.access){
                delete data.access
            }
            await ref.set(data, { merge: true });
            return true
        },
        async forgot_password(email){
            await firebase.auth().sendPasswordResetEmail(email)
        },
    }
}