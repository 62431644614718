<template>
	<div class="home-view">
        <singleUnitDetails :role="role" :last_alive_time="singleUnit.last_alive" :unit="singleUnit" class="single-unit-details"/>
        <show-at breakpoint="mediumAndAbove">
            <div class="desktop-container">
                <AdjustCalculation v-if="!loading_prices" ref="adjustCalculation" class="adjust-calculation" @adjustCalculation="calculation($event)" @hide="$refs.adjustCalculation.$el.style.visibility = 'hidden'"/>
                <div class="notifications-contact flex gap-1" v-if="['admin','craftsman'].includes(role)">
                    <notifications :notifications="notifications" :error_codes="error_codes" :role="role" v-if="['admin', 'craftsman'].includes(role)"/>
                    <Contact :unit="singleUnit" />
                </div>
                <div class="grid">
                    <div class="row-1">
                        <serviceLogList v-if="['admin', 'craftsman'].includes(role)" :log="serviceLog" :role="role"/>
                        <Contact v-if="role == 'customer'" :unit="singleUnit" />
                    </div>
                    <div class="row-2">
                        <UnitSavings ref="unitSavings" @filterTime="filterTime" :client="client" v-if="!loading_prices && role == 'customer'" class="savings" :selectedUnits="[{data: singleUnit}]" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'" />
                        <UnitPerformance v-else :unit="singleUnit"/>
                        <Installation v-if="role == 'admin' || role === 'craftsman'" :role="role" :unit="singleUnit" :class="{ notadmin: role == ['customer','craftsman'].includes(role) }"/>
                        <EolLog v-if="role == 'admin'" :eol_logs="eol_logs"/>
                    </div>
                    <div class="row-3">
                        <getSupport v-if="role !== 'admin'"/>
                        <Installation v-if="role == 'customer'" :role="role" :unit="singleUnit" :class="{ notadmin: role == ['customer','craftsman'].includes(role) }"/>
                        <singleUnitEvents :unit="singleUnit" :events_prop="events" v-if="role == 'admin'"/>
                        <RemoteCommands v-if="role == 'admin'"/>
                        <Deinstallation :unit="singleUnit" v-if="role == 'admin'"/>
                    </div>
                </div>
            </div>
        </show-at>

<!-- Mobile -->
        <hide-at breakpoint="mediumAndAbove" class="flex d-col gap-1">
            <div class="mobile">
                <notifications :notifications="notifications" :role="role" v-if="['admin', 'craftsman'].includes(role)"/>
                <serviceLogList :log="serviceLog" :role="role"/>
                <UnitSavings ref="unitSavings" :client="client" class="savings" v-if="role == 'customer'" :selectedUnits="[{data: sinlgeUnit}]" @calculation="$refs.adjustCalculation.$el.style.visibility = 'visible'" />
                <UnitPerformance v-else :unit="singleUnit"/>
                <getSupport  v-if="role !== 'admin'" />
                <singleUnitEvents :events_prop="events"  v-if="role == 'admin'"/>
                <Contact />
                <Installation :unit="singleUnit"/>
                <Deinstallation :unit="singleUnit" v-if="role == 'admin'" />
                <RemoteCommands v-if="role == 'admin'" />
            </div>
        </hide-at>
	</div>
</template>

<style lang="scss" scoped>

.adjust-calculation {
	visibility: hidden;
}
.single-unit-details {
    margin-bottom: 30px;
}

.notifications-contact {
    display:grid;
    grid-template-columns: 2.5fr 1fr;
    margin-bottom:1rem;
    max-height:350px;
    gap:1rem;
}

.grid {
    display:grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    gap:1rem;
}

.notadmin ::v-deep.comp-container {
    height:500px;
}

.customer-info {
    margin-bottom:1rem;
}

</style>

<script>
import singleUnitDetails from '../components/singleUnit_components/singleUnitDetails'
import singleUnitEvents from '../components/singleUnit_components/singleUnitEvents'
import serviceLogList from '../components/singleUnit_components/serviceLog'
import notifications from '../components/singleUnit_components/notifications.vue'
import Deinstallation from '../components/singleUnit_components/Deinstallation.vue'
import Installation from '../components/singleUnit_components/Installation.vue'
import RemoteCommands from '../components/singleUnit_components/RemoteCommands.vue'
import UnitPerformance from '../components/singleUnit_components/UnitPerformance.vue'
import getSupport from '../components/getSupport.vue'
import UnitSavings from '../components/home_components/unitSavings.vue'
import AdjustCalculation from '../components/adjustCalculation.vue'
import EolLog from '../components/singleUnit_components/EolLog.vue'
import Contact from '../components/singleUnit_components/Contact.vue'

export default
{
	name: 'Home',
    components: {
        AdjustCalculation,
        singleUnitDetails,
        singleUnitEvents,
        serviceLogList,
        notifications,
        Deinstallation,
        Installation,
        RemoteCommands,
        UnitPerformance,
        getSupport,
        UnitSavings,
        EolLog,
        Contact
    },
	data()
	{
		return {
            savings_data: undefined,
            client: undefined,

            toggle_qr_code: false,
            role: '',
            time_range: [],
			showDetail: false,
			detailOptions: [],
            headerKeys: ["type","timestamp"],
            events: [],
            filters: [],
            filter_on_type: false,
            calculated_savings: {},
            show_lifetime_values: true,
            prevRoute: null,
            eol_logs: [],
            loading_prices: true
		}
	},
	watch:
	{
        async filter_on_type(){
            if(this.filter_on_type){
                this.events = [],
                this.events = await this.requestEvents(this.$route.params.unit_id, [["type","==",this.filter_on_type]])
            }
        },
		singleUnitDetails(){
			let vm = this
			setTimeout(function(){ 
				vm.detailOptions = Object.keys(vm.singleUnitDetails);  
			}, 500); 
		},
        singleUnit(){
            this.requestTech(this.singleUnit.technician)
        }
	},
    computed: {
        headerKeyValues(){
            if(this.events){
                return this.events;
            } else {
                return []
            }
        }
    },
	methods: {
        async filter_events(){
            this.calculated_savings = {};
            this.events = await this.requestEvents(this.$route.params.unit_id, this.time_range[0], this.time_range[1]);
            this.calculated_savings = this.calculate_savings(this.events);
        },
        async calculation({water_price, energy_price}) {
			water_price = parseFloat(water_price)
			energy_price = parseFloat(energy_price)
			await this.adjust_company_calculation({ water_price, energy_price })
			this.$refs.unitSavings.filter()
		},
        async filterTime(range){
			if(range === null) {
                this.singleUnit.savings_for_range = null;
				this.$refs.unitSavings.filter()
				return
			}
			const unitWithSavings = await this.getUnitSavingsForTime(range, this.singleUnit.id);
            if(unitWithSavings) {
                const water_saved = unitWithSavings?.data?.water_saved ? unitWithSavings?.data?.water_saved : 0;
                const water_used = unitWithSavings?.data?.water_used ? unitWithSavings?.data?.water_used : 0;
                this.singleUnit.savings_for_range = { water_saved, water_used };
                this.$refs.unitSavings.filter()
            }
		}
	},
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from.name
        })
    },
	async mounted() {
        if(this.prevRoute == "login") await new Promise(res => setTimeout(res, 1000));

        this.metaInfo("singleUnit", this.$route.path)
        
        this.time_range = [Date.now() - 1000*60*60*24, Date.now()]
        this.requestSingleUnit(this.$route.params.unit_id)
        this.requestServiceLog(this.$route.params.unit_id)
        this.requestNotifications(this.$route.params.unit_id)
        this.requestErrorCodeList()
        this.eol_logs = await this.getAllEolLogs(this.$route.params.unit_id)
        if(this.$customer_prices.water_price === undefined && this.$customer_prices.energy_price === undefined) await this.get_company_prices()
        this.role = localStorage.getItem("role")
        this.client = localStorage.getItem("client")
        this.loading_prices = false
	},
    beforeDestroy(){
        this.unsubscribeAll();
	}
}
</script>