import Vue from "vue"
import VueRouter from "vue-router"
import Home from '../views/home.vue'
import Login from '../views/login.vue'
import SingleUnit from '../views/singleUnit.vue'
import publicUnit from '../views/publicUnit.vue'
// import Help from '../views/help.vue'
import Stats from '../views/admin.vue'
// import Register from '../views/register.vue'
import Users from '../views/users.vue'
import WaterDiscards from '../views/WaterDiscards.vue'

import firebase from 'firebase/compat/app';

Vue.use(VueRouter)

const routes = [
    {
        path: "*",
        redirect: "/"
    },
	{
		path: "/",
		name: "home",
		component: Home,
		meta: { requiresAuth: true }
	},
	{
		path: "/login/",
		name: "login",
		component: Login,
		meta: { requiresAuth: false }
	},
    {
        path: "/unit/:unit_id",
        name: "singleUnit",
        component: SingleUnit,
        meta: { requiresAuth: true }
    },
    {
        path: "/public/:unit_id",
        name: "publicUnit",
        component: publicUnit,
        meta: { requiresAuth: false }
    },
    {
        path: "/register/:register_id",
        name: "register",
        component: Login,
        meta: { requiresAuth: false }
    },
	// {
	// 	path: "/help",
	// 	name: "help",
	// 	component: Help,
	// 	meta: { requiresAuth: true }
	// },
    // {
    //     path: "/admin",
    //     name: "admin",
    //     component: Admin,
    //     meta: { requiresAuth: true, mimblyOnly: true, adminOnly: true }
    // },
    {
        path: "/admin/stats",
        name: "admin_stats",
        component: Stats,
        meta: { requiresAuth: true, mimblyOnly: true, adminOnly: true }
    },
    {
        path: "/admin/users",
        name: "admin_users",
        component: Users,
        meta: { requiresAuth: true, mimblyOnly: true, adminOnly: true }
    },
    {
        path: "/admin/water_discards",
        name: "admin_water_discards",
        component: WaterDiscards,
        meta: { requiresAuth: true, mimblyOnly: true, adminOnly: true }
    }
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) { next({ path: '/login' }) }
            else { 
                // wait until matamo is loaded
                while (window?._paq === undefined) {
                    await new Promise(r => setTimeout(r, 100))
                }
                const user_data = await firebase.firestore().collection('users').doc(user.uid).get()
                let err = false
                try {
                    window?._paq?.push(['setUserId', user_data?.data()?.email])
                } catch(e) {
                    err = true
                }
                if (!user_data?.data()?.email || err) window?._paq?.push(['setUserId', user.uid])
                if (to.matched.some(record => record.meta.mimblyOnly) && localStorage.getItem('client') !== 'mimbly') next({ path: '/' })
                else if (to.matched.some(record => record.meta.adminOnly) && user_data.data().role !== 'admin') next({ path: '/' })
                else next()
            }
        })
    }
    else if (!to.matched.some(record => record.meta.requiresAuth)) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user && to.name ==='login' && to.query.unit_id) { 
                next({ path: '/unit/'+to.query.unit_id })
            }
            else if (user && to.name ==='login') { next({ path: '/' }) }
            else next()
        })
    }
    else next()
});

export default router