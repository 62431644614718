<template>
  <div class="container">
        <svg-progress-bar
        :type="'rect'"
        :value="value"
        :options="options"
        ref="bar"
        ></svg-progress-bar>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['value'],
  data() {
    return {
      options: {
        radius: 50,
        text: '',
        pathColors: ['#0068FF','#EDF1F7'],
        rectRadius: 10,
        rectHeight: 20,
        rectWidth: 300
      }
    }
  },
  mounted() {
    this.$refs.bar.$el.querySelector('svg').setAttribute('stroke', '#0068FF')
    this.$refs.bar.$el.querySelector('svg').setAttribute('stroke-width', '4px')
    this.$refs.bar.$el.querySelector('svg').style['border-radius'] = '20px'

  }
}
</script>