<template>
    <div>
    <!-- Desktop -->
        <show-at breakpoint="mediumAndAbove">
            <div class="notifications comp-container" :class="{empty: notifications.length < 1}">
                <div class="left">
                    <div class="header flex items-center" @click="downloadErrors">
                        <h4>{{trans('notifications')}}</h4>
                        <span class="flex history"><div class="icon clock"></div>{{trans('history')}}</span>
                    </div>
                    <div class="notification-list flex" v-if="notifications.length > 0">
                        <div class="notification-singleunit flex" v-for="(notification) in notifications.slice(0, 20)" :key="notification.fb_id" @click="setNotification(notification)" :class="{ active: notification.fb_id == currNotification.fb_id}">
                            <span class="date">{{displayDate(notification.timestamp, 'number')}}</span>
                            <!-- <span class="mimbox-id">{{displayTime(notification.timestamp)}}</span> -->
                            <div class="icon error" :class="{alert: notification.type == 'alert', ach: notification.type == 'ach', error: notification.type == 'error'}"></div>
                        </div>
                    </div>
                    <div class="notifcation-list flex no-notifications" v-else>
                        <span>{{trans('no_notifications_to_show')}}</span>
                    </div>
                </div>
                <div class="right">
                    <div class="info-container flex">
                        <div v-if="currNotification.fb_id" class="curr-notification flex items-center">
                            <span>{{displayDate(currNotification.timestamp, 'number')}}</span>
                            <span id="notification-name">{{displayTime(currNotification.timestamp)}}</span>
                            <div class="icon error" :class="{alert: currNotification.type == 'alert', ach: currNotification.type == 'ach', error: currNotification.type == 'error'}"></div>
                        </div>
                        <div v-else class="curr-notification flex items-center">
                            <span></span>
                            <span id="notification-name">{{trans('select_a_notification_to_show_info')}}</span>
                            <div></div>
                        </div>
                        <div class="curr-inner flex" v-if="currNotification.fb_id">
                            <div class="curr-left flex d-col">
                                <div class="code-list">
                                    <h4>{{trans('notification_codes')}}</h4>
                                    <span v-for="code, i in currNotification.activeCodes" :key="code">
                                        {{code}}<span v-if="currNotification.activeCodes.length > i + 1">, </span>
                                    </span>
                                </div>
                                <div class="assigned">
                                    <h4>{{trans('assigned_to')}}</h4> 
                                    <div class="input-field flex space-between">
                                        <input v-model="currNotification.assigne" value="mimbly_assigned" @change="setAssigned('mimbly_assigned')" type="radio" name="mimbly">
                                        <label for="mimbly" class="checkbox-label flex items-center">{{trans('mimbly_assigned')}}</label>
                                    </div>
                                    <div class="input-field flex space-between">
                                        <input v-model="currNotification.assigne" value="on_site_assigned" @change="setAssigned('on_site_assigned')" type="radio" name="site">
                                        <label for="site" class="checkbox-label flex items-center">{{trans('on_site_assigned')}}</label>
                                    </div>
                                    <div class="input-field flex space-between">
                                        <input v-model="currNotification.assigne" value="technician_assigned" @change="setAssigned('technician_assigned')" type="radio" name="tech">
                                        <label for="tech" class="checkbox-label flex items-center">{{trans('technician_assigned')}}</label>
                                    </div>
                                </div>
                            </div>
                            <div :options="{wheelSpeed: .2, wheelPropagation: false}" class="curr-right">
                                <h4>{{trans('code_explaination')}}</h4>
                                <div class="desc-text-container flex d-col gap-05">
                                    <div v-for="code in currNotification.activeCodes" :key="code">
                                        <label>{{code + ' - ' +  (error_codes?.[code]?.[language]?.label ?? error_codes?.[code]?.en.label)}}</label>
                                        <p>{{error_codes?.[code]?.[language]?.description ?? error_codes?.[code]?.en.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </show-at>

        <!-- mobile -->
        <hide-at breakpoint="mediumAndAbove">
            <div class="simplebar">
                <div class="notifications comp-container">
                    <ul class="flex d-col gap-05">
                        <li class="mobile-notification flex d-col" v-for="notification in notifications" :key="notification.fb_id">
                            <div class="label flex space-between blue-box" @click="setNotification(notification, $event)">
                                <span class="date">{{displayDate(notification.date, 'date')}}</span> <!-- notification.date -->
                                <span class="mimbox-id">{{notification.error_code}}</span> <!-- mimbox.id -->
                                <!-- <div class="icon" :class="{alert: notification.type == 'alert', ach: notification.type == 'ach', error: notification.type == 'error'}"></div> -->
                                <div class="icon error"></div>
                            </div>
                            <div class="content" :class="{active: notification == currNotification}">
                                <div class="inner-content">
                                <h4>{{trans('code_explaination')}}</h4>
                                {{trans('notification_desc_' + currNotification.error_code)}}
                                <!--<a :href="currNotification.link">{{trans('See guide')}}</a>-->
                                <h4 class="assigned-to" v-if="role == 'admin'">{{trans('Assigned to')}}</h4> 
                                <div class="assigned flex space-between" v-if="role == 'admin'">
                                    <div class="radio-button">
                                        <input v-model="currNotification.assigne" value="mimbly_assigned" @change="setAssigned('mimbly_assigned')" type="radio" name="mimbly">
                                        <label for="mimbly" class="checkbox-label">mibmly</label>
                                    </div>
                                    <div class="radio-button">
                                        <input v-model="currNotification.assigne" value="on_site_assigned" @change="setAssigned('on_site_assigned')" type="radio" name="site">
                                        <label for="site"  class="checkbox-label">onsite</label>
                                    </div>
                                    <div class="radio-button">
                                        <input v-model="currNotification.assigne" value="technician_assigned" @change="setAssigned('technician_assigned')" type="radio" name="check">
                                        <label for="check" class="checkbox-label">tech</label>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </hide-at>
    </div>
</template>

<script>
import descriptions from '../../assets/code-explanations.json'

export default{
    name: "notifications",
    props: ['notifications', 'role', 'error_codes'],
    watch: {
        notifications(data) {
            if(this.linkedNotification.fb_id) { //if there's a routed notification
                data = data.filter(e => e.fb_id == this.linkedNotification.fb_id)
                this.setNotification(data[0])
            }
        },
        currNotification(data) {
            let codesArr = []
            data.error_code.toString(2).split('').reverse().forEach((code, i) => {
                if(code === '1'){
                    codesArr.push(i + 1)
                }
            })
            this.currNotification.activeCodes = codesArr.length > 0 ? codesArr : [0]
        }
    },
    data(){
        return {
            currNotification: {},
            linkedNotification: {},
            language: ''
        }
    },
    methods: {
        async setAssigned(){
            if(this.role == 'admin'){
                await this.updateNotification(this.$route.path.split("unit/")[1], this.currNotification.fb_id, {assigne: this.currNotification.assigne})
            }
        },
        setNotification(data, event) {
            if(data.error_code || data.error_code === 0){
                let code = data.error_code.toString()
                let notification = descriptions[data.type]?.[code]
                this.currNotification = data
                this.currNotification.description = notification?.description
                this.currNotification.link = notification?.link
                this.currNotification.name = notification?.name
                if(event && event.target.closest('.mobile-notification').querySelector('.active')) {this.currNotification = {}}
            }
        },
        //look at route and set notification to that, if it exists
        setLinkedNotification() {
            const regex = /\?.*/
            const path = this.$route.fullPath

            if(!path.match(regex)) return
            const arr = path.match(regex).pop().replace('?','').split('_')
            this.linkedNotification.fb_id = arr[0]
            // this.linkedNotification.fb_id = arr[1]
            // data = data.filter(e => e.fb_id == this.linkedNotification.fb_id)

        },
        async downloadErrors(){
            await this.download_all_notifications(this.$route.path.split("unit/")[1])
            return true
        }
    },
    mounted() {
        this.setLinkedNotification()
        this.language = localStorage.getItem("selectedLanguage") ?? 'en'
    }
}
</script>

<style lang="scss" scoped>
.notifications {
border:none;
background:var(--medgray);
height:350px;
max-height:350px;
display: grid;
grid-template-columns: minmax(15rem, 1fr) 2fr;
gap:3rem;
padding:0;
min-width:920px;
}

.left, .right {
    height:inherit;
    margin:1rem;
}

.header {
    justify-content: space-between;
    position:relative;
    h4 {
        margin:0;
    }
    .history {
        color: var(--blue);
        gap:.5rem;
        cursor:pointer;
        position: absolute;
        width:max-content;
        right:-30px;
    }
    .clock {
        background-image: url('../../assets/clock.svg');
    }
}

.notification-list {
    flex-direction:column;
    margin-top:1rem;
    gap:.5rem;
    height:auto;
    max-height:276px;
    overflow:auto;
    ::v-deep .simplebar-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

.no-notifications {
    margin-top:1rem;
    font-style: italic;
    color:rgba(0,0,0,.5)
}

.info-container {
    border:none;
    background:var(--white);
    flex-grow:1;
    height:90%;
    border-radius: var(--border-radius);
    padding: 0;
    flex-direction:column;
    gap:.5rem;
    position:relative;
    h4 {
        margin-bottom:1rem;
    }
}

.curr-notification {
    justify-content: space-between;
    background: var(--medgray);
    height: var(--filter-height);
    padding:.5rem 1rem;
    margin:1rem;
    border-radius: var(--border-radius);
    span, div {
        display: flex;
        align-items: center;
    }
    .icon {
        background:var(--black);
        mask-repeat: no-repeat;
    }
    .ach {
        mask-image:url('../../assets/ach.svg')
    }
    .error {
        mask-image:url('../../assets/error.svg')
    }
    .alert {
        mask-image:url('../../assets/alert.svg')
    }
}

.curr-inner {
    text-align:left;
    gap: 2rem;
    position:absolute;
    top: 5rem;
    left: var(--rem-1);
    right: var(--rem-1);
    bottom: var(--rem-1);
    h4:first-child {
        margin-top:0;
    }
}

.desc-text-container {
    font-family: 'source sans pro', sans-serif;
    label {
        color:var(--blue)
    }
}

.input-field {
    flex-direction: row-reverse;
}

.checkbox-label {
    color: var(--blue)
}

input[type="radio"] {
    appearance: none;
    border: 2px solid var(--blue);
    border-radius: .25rem;
    width: 1.2rem;
    aspect-ratio:1;
    cursor:pointer;
    position: relative;
}

input[type="radio"]:checked::after {
        content: '';
        font-size: 14px;
        position: absolute;
        width:70%;
        height:70%;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        background: url('../../assets/check.svg') center no-repeat;
}

input[type="radio"]:checked + .checkbox-label {
    font-weight: 700;
}

a {
    text-decoration: underline;
    font-weight: 700;
}

.curr-right, .curr-left {
    height:100%;
    width:100%;
    overflow:auto;
}

.curr-left {
    min-width:40%;
    .n-times {
        margin-bottom:1rem;
    }
}

.code-list {
    margin-bottom:16px;
    h4 {
        margin-bottom: 5px;
    }
    span {
        font-size: 14px;
    }
}

p {
    margin:0;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 744px) {
    .notifications {
        background:transparent;
        display:flex;
        flex-direction: column;
        align-items:center;
        height:auto;
        min-width: 0px;
        overflow-y:auto;
        border-radius:0;
    }

    .simplebar {
        width:100%;
        height:350px;
    }

    ul{
        padding:0;
        width:100%;
    }

    .mobile-notification {
        border:calc(var(--border-width)/2) solid var(--blue);
        border-radius: var(--border-radius);
        .label {
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            border-bottom:none !important;
            height:2.5rem;
            box-sizing:border-box;
            padding:.5rem 1rem;
            cursor:pointer;
        }
        .content {
            max-height:0;
            font-family: 'source sans pro', sans-serif;
            overflow:hidden;
            visibility: hidden;
            h4 { margin: 0; margin-bottom: .5rem}
            box-sizing: border-box;
            .assigned-to {margin-top:.5rem}
            .assigned {
                width:100%;
            }
            
            .radio-button {
                position:relative;
                height:max-content;
                label {
                    width:100%;
                    pointer-events:none;
                }
                input[type="radio"] {
                    border:none;
                    margin:0;
                    width:120%;
                    height:120%;
                    transform:translate(-10%,-10%);
                    position:absolute;
                    border-radius: var(--border-radius);
                }
                input[type="radio"]:checked {
                    background-color: var(--lightgray);
                    z-index:-1;
                }
                input[type="radio"]:checked::after {
                    display:none;
                }


            }
        }

        .content.active {
            max-height:15rem;
            visibility: visible;
        }

        .inner-content {
            padding:1rem;
            height:15rem;
            box-sizing: border-box;
        }
    }
}
</style>