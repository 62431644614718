<template>
    <div v-if="!isGirbUnit" class="public-container flex d-col gap-1 items-center">
        <div class="mimbly-logo"></div>
        <h1>{{trans('so_far_we_have_saved')}}</h1>
        <div class="items flex gap-1 items-center">
            <div class="comp-container item flex d-col items-center content-center" v-for="(item, key) in items" :key="key">
                <div class="icon" :class="key"></div>
                <h2>{{item.saved}} {{item.unit}}</h2>
                <span>{{item.label}}</span>
            </div>
        </div>
        <h2 v-if="address">Mimbox located at {{address}}</h2>

        <h2>Find out more at <a href="http://www.mimbly.se">www.mimbly.se</a>, or <router-link class="" :to="{ path: '/login', query: { unit_id: $route.params.unit_id } }">login</router-link> to mimbox</h2>
        <div class="mt-12">
            <a href="https://www.mimbly.se/support" class="px-8 py-5 font-bold border-solid border-primary hover:bg-primary hover:text-white duration-200 cursor-pointer" style="border-radius: 300px; border-width: 3px">Mimbox support</a>
        </div>
    </div>
    <div v-else class="public-container flex d-col gap-1 items-center">
        <div class="girbau-logo"></div>
        <h1 class="text-center">So far this unit has saved</h1>
        <div class="items flex gap-4 items-center my-4">
            <div style="background-color: #007886" class="rounded-mimbly item flex d-col items-center content-center" v-for="(item, key) in items" :key="key">
                <div class="icon" :class="'g' + key"></div>
                <h2 class="text-white">{{item.saved}} {{item.unit}}</h2>
                <span class="text-white">{{item.es}}</span>
            </div>
        </div>
        <h2 v-if="address">Installed at {{address}}</h2>

        <h2 class="items-center gap-1">Find out more at <a class="items-center gap-1 hover:underline inline-block" style="color: #FF7F41" href="http://www.mimbly.se">www.mimbly.se <i class="arrow" /></a> or <router-link style="color: #FF7F41" class="items-center gap-1 hover:underline inline-block" :to="{ path: '/login', query: { unit_id: $route.params.unit_id } }">log in <i class="arrow"></i></router-link>  to the dashboard</h2>
    </div>
</template>

<script>

export default {
    name: 'publicUnit',
    data() {
        return {
            isGirbUnit: false,
            dLocale: 'sv',
            address: false,
            items: {
                water_saved: {
                    label: 'Water',
                    es: 'Agua',
                    saved: 0,
                    unit: 'l'
                },
                co2_saved: {
                    label: 'Carbon Dioxide (CO2)',
                    es: 'Dióxido de carbono (CO2)',
                    saved: 0,
                    unit: 'kg'
                },
                plastic_saved: {
                    label: 'Microplastics',
                    es: 'Microplásticos',
                    saved: 0,
                    unit: 'g'
                }
            }
        }
    },
    methods: {
        async getPublicInformation(muid) {
            const data = await this.publicPage(muid)
            Object.keys(data).forEach((e) => {
                if(e === "address") this.address = data.address
                else this.items[e].saved = parseFloat(parseFloat(data[e]).toFixed(2)).toLocaleString(this.dLocale)
            })
        },
        changeFavicon(url) {
            let favicon = document.querySelector('link[rel="icon"]')
            if (!favicon) {
                favicon = document.createElement('link')
                favicon.setAttribute('rel', 'icon')
                favicon.setAttribute('type', 'image/png')
                document.head.appendChild(favicon)
            }
            favicon.setAttribute('href', url)
        }
    },
    async mounted(){
        const client = localStorage.getItem('client')
        if(client !== 'mimbly') this.metaInfo("Public", this.$route.path)
        const id = this.$route.params.unit_id
        this.currUnit = await this.getPublicInformation(id)
    }
}
</script>

<style scoped lang="scss">
.public-container {
    margin:10vh 0;
    position:relative;
}

h2 {
    margin:0;
}

h2:first-of-type {
    margin:1rem;
}

a {
    color: #0068FF;
}

.mimbly-logo {
    background-image: url('../assets/dashboard-logo.svg');
    background-size:contain;
    background-repeat: no-repeat;
    width:100px;
    aspect-ratio: 1;
}

.girbau-logo {
    background-image: url('../assets/girbau/logo.svg');
    background-size:contain;
    background-repeat: no-repeat;
    width:350px;
    height:100px;
}

.arrow {
    background-image: url('../assets/girbau/arrow.svg');
    width:30px;
    height:30px;
    background-size:contain;
    background-repeat: no-repeat;
    display: inline-block;
}

h1 {
    margin-top:0;
}

.item {
    box-sizing: border-box;
    padding:2rem;
    width:20rem;
    aspect-ratio: 1;
}

.icon {
    background-size:cover;
    width:130px;
    aspect-ratio:1;
}

.water_saved {
    background-image: url('../assets/water.svg');
}

.gwater_saved {
    background-image: url('../assets/girbau/water.svg');
    background-size: contain;
}

.plastic_saved {
    background-image: url('../assets/microplastics.svg')
}

.gplastic_saved {
    background-image: url('../assets/girbau/microplastics.svg');
    background-size: contain;
}

.co2_saved {
    background-image: url('../assets/co2.svg')
}

.gco2_saved {
    background-image: url('../assets/girbau/CO2.svg');
    background-size: contain;
}

</style>

<style lang="scss" scoped>
@media screen and (max-width: 1228px){
    .public-container {
        align-items:center;
        margin:5vh 0;
    }
    .icon {
        width:100px;
    }
    .items {
        flex-direction:column;
        width:100%;
    }

    .item {
        width:250px;
        padding:1rem;
    }

    h2 {
        text-align: center;
    }
}
</style>