<template>
    <div>
        <h3>{{trans('savings')}}</h3>
        <div class="comp-container transition-all duration-100 ease-in-out" style="max-height: 750px; height: 2000px; overflow: hidden;" ref="container">
            <div class="pr-4">
                <span class="flex justify-between items-center w-full py-4 px-4 mb-4">
                    <Calendar v-model="selected_month" view="month" dateFormat="MM" :showButtonBar="true" showIcon />
                    <div  v-if="role !== 'craftsman'" class="cursor-pointer flex items-center gap-2" @click="$emit('calculation', true)">
                        <div class="icon cog pr-2"></div><show-at breakpoint="mediumAndAbove">{{trans('adjust_calculations')}}</show-at>
                    </div>
                </span>
            </div>
            <div class="flex justify-around items-center">
                <div class="flex flex-col items-center cursor-pointer" v-for="stat, name in saved" :key="name" @click="() => {selectStat(name); logEvent(name)}">
                    <svg class="hover:opacity-75 transition duration-200 ease-in-out" width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{selected: name == selected_stat}">
                        <circle cx="54.1191" cy="54.7891" r="53.25" fill="var(--lightgray)" stroke="#0068FF" stroke-width="1.5"/>
                        <path class="color-changer" v-if="name == 'water'" d="M39.2148 66.9299C39.269 64.7082 40.2986 61.484 41.3011 58.2597C44.4169 48.0994 48.2643 38.1829 52.1659 28.2935C52.5181 27.3723 53.0329 26.695 54.0896 26.6679C55.065 26.6408 55.661 27.2369 56.0133 28.1039C60.5109 39.6731 65.0898 51.2423 68.3682 63.2722C70.9693 72.8364 65.3879 79.7996 59.2646 82.0214C49.5648 85.5165 39.2148 78.3636 39.2148 66.9299Z" fill="#0068FF"/>
                        <g v-else-if="name == 'economy'">
                            <path class="color-changer" d="M53.491 25.3293C68.9185 25.3816 81.4958 38.0635 81.4697 53.5433C81.4435 69.0492 68.7354 81.6266 53.2033 81.5743C37.7758 81.522 25.1985 68.8401 25.2247 53.3603C25.2508 37.8543 37.9327 25.277 53.491 25.3293ZM52.5496 59.6882C50.7977 59.2959 50.2486 58.067 49.7779 56.7072C49.3073 55.3475 48.1567 54.903 47.1631 55.6613C46.3525 56.315 46.3786 57.1779 46.6663 58.0408C47.3984 60.2634 48.9935 61.597 51.2161 62.0676C52.3666 62.303 52.6804 62.7736 52.5496 63.8719C52.4712 64.6563 52.5235 65.4408 52.5496 66.2252C52.5496 66.6697 52.785 66.9835 53.2556 67.0097C53.7525 67.0358 53.9878 66.7221 54.0401 66.3037C54.0924 65.7807 54.0924 65.2577 54.1185 64.7348C54.1447 64.0288 53.9355 63.2443 54.197 62.6691C54.5108 61.9631 55.4783 62.1722 56.132 61.9369C58.5376 61.0217 60.1326 58.9037 60.0804 56.4981C60.0281 54.1186 58.5899 52.2882 56.0797 51.4253C55.6351 51.2684 55.1645 51.1376 54.7199 51.0069C54.3539 50.9023 54.1185 50.7454 54.1185 50.3009C54.1447 48.7059 54.1185 47.1108 54.1185 45.385C55.3475 45.7772 55.9228 46.5355 56.2627 47.6076C56.5765 48.6274 57.3086 49.1504 58.3807 48.7581C59.4789 48.3659 59.6097 47.4507 59.2698 46.4832C58.6422 44.7313 57.4132 43.5023 55.5828 43.1363C54.5369 42.9271 53.9094 42.6656 54.1185 41.4366C54.197 40.966 53.9878 40.4168 53.3602 40.4168C52.6542 40.3907 52.4973 40.9398 52.5758 41.5151C52.7065 42.5087 52.3666 42.9009 51.2945 43.084C48.8104 43.4762 47.2415 45.2804 47.0585 47.5292C46.8493 50.1963 47.8952 51.9221 50.4055 52.9419C50.85 53.1249 51.2945 53.3341 51.7652 53.4126C52.4189 53.5433 52.6019 53.8571 52.6019 54.4846C52.5235 56.1843 52.5496 57.8839 52.5496 59.6882Z" fill="#0168FE"/>
                            <path class="color-changer-blue" d="M53.8066 53.9574C53.8066 55.5786 53.8327 57.1736 53.8066 58.7687C53.7804 59.5793 54.1988 59.4485 54.6956 59.2655C55.8723 58.821 56.6829 57.6443 56.6045 56.4415C56.4999 55.0818 55.5847 54.245 53.8066 53.9574ZM52.1331 49.9567C52.1331 48.2047 52.1331 46.6358 52.1331 45.0146C50.355 45.5638 49.832 46.139 49.832 47.4726C49.832 49.0153 50.9041 49.5122 52.1331 49.9567Z" fill="#FAFCFE"/>
                            <path class="color-changer" d="M54.05 54.1543C55.8281 54.4419 56.7433 55.2787 56.8479 56.6384C56.9263 57.8412 56.1419 59.044 54.939 59.4624C54.4422 59.6454 54.0238 59.7762 54.05 58.9656C54.0761 57.3705 54.05 55.7493 54.05 54.1543Z" fill="#0168FE"/>
                            <path class="color-changer" d="M52.1331 49.9567C50.878 49.5122 49.832 49.0153 49.832 47.4726C49.832 46.139 50.355 45.5638 52.1331 45.0146C52.1331 46.6358 52.1331 48.2047 52.1331 49.9567Z" fill="#0168FE"/>
                        </g>
                        <g v-else-if="name == 'microplastics'">
                            <path d="M39.1209 52.212C38.7835 52.212 38.2773 52.0713 37.771 51.9307C36.1962 51.5089 34.6495 50.9465 33.1027 50.4121C32.9621 50.3559 32.8496 50.2715 32.8496 50.1309C32.8496 49.9903 32.934 49.8778 33.0746 49.8215C34.8463 49.0622 36.5899 48.3029 38.4179 47.7405C39.8802 47.2905 40.977 48.1061 41.3426 49.0622C41.9332 50.5246 40.8927 52.1557 39.1209 52.212Z" fill="#B0B0B0"/>
                            <path class="" d="M40.6792 69.21C50.8369 69.21 59.0713 60.9756 59.0713 50.8179C59.0713 40.6602 50.8369 32.4258 40.6792 32.4258C30.5215 32.4258 22.2871 40.6602 22.2871 50.8179C22.2871 60.9756 30.5215 69.21 40.6792 69.21Z" fill="#E4F7FF"/>
                            <path class="color-changer-medgray" fill-rule="evenodd" clip-rule="evenodd" d="M40.1769 35.3851C31.9305 35.3851 25.2454 42.0701 25.2454 50.3166C25.2454 58.563 31.9305 65.2481 40.1769 65.2481C48.4234 65.2481 55.1084 58.563 55.1084 50.3166C55.1084 42.0701 48.4234 35.3851 40.1769 35.3851ZM18.3242 50.3166C18.3242 38.2477 28.108 28.4639 40.1769 28.4639C52.2458 28.4639 62.0296 38.2477 62.0296 50.3166C62.0296 62.3855 52.2458 72.1693 40.1769 72.1693C28.108 72.1693 18.3242 62.3855 18.3242 50.3166Z" fill="#0068FF"/>
                            <path class="color-changer-medgray" fill-rule="evenodd" clip-rule="evenodd" d="M86.0835 80.651L54.6426 61.4434L58.2508 55.5371L89.6917 74.7448L86.0835 80.651Z" fill="#0068FF"/>
                            <path d="M33.1066 50.874L32.8535 48.9336C33.0223 49.0461 33.8659 50.2554 33.8659 50.2554" fill="#74FF91"/>
                            <path d="M28.2428 49.0046L26.2461 48.2734C27.5679 48.3297 33.7829 48.7796 33.7829 48.7796" fill="#6B00FF"/>
                            <path d="M37.4031 52.4766L36.8125 50.2549C37.2062 50.3955 39.0341 51.7735 39.0341 51.7735" fill="#D8FF00"/>
                            <path d="M35.4922 45.6359C35.9703 45.5797 39.4856 46.3108 39.4856 46.3108L39.6262 48.3357L35.4922 45.6359Z" fill="#726AFF"/>
                            <path d="M38.3158 44.6223L36.1504 42.9912C36.3472 43.0475 37.894 43.8349 37.894 43.8349" fill="#74FF91"/>
                            <path d="M35.0189 43.7377L32.8535 43.5689C33.5003 43.0627 37.0437 41.0098 37.0437 41.0098" fill="#00CA00"/>
                            <path d="M44.2478 46.8569L41.4355 44.9727C41.7449 44.9727 44.0228 45.5913 44.0228 45.5913" fill="#FF3FA2"/>
                            <path d="M39.4551 54.877C39.9613 55.2144 42.3236 58.5329 42.3236 58.5329L40.2144 60.2484L39.4551 54.877Z" fill="#00D4FF"/>
                            <path d="M44.458 56.6339L42.0957 55.5371C43.6424 55.5934 51.0386 56.2964 51.0386 56.2964" fill="black"/>
                            <path d="M49.6992 43.6252C48.9962 43.7095 45.3965 43.1471 45.3965 43.1471L47.7307 41.0098L49.6992 43.6252Z" fill="#CD75FF"/>
                            <path d="M46.4222 37.708L46.9847 38.7485C46.844 38.7204 46.0566 38.2986 46.0566 38.2986" fill="#74FF91"/>
                            <path d="M44.3875 46.293L46.1592 46.4898C45.878 46.7148 44.0781 47.5585 44.0781 47.5585" fill="#6B00FF"/>
                            <path d="M43.418 50.915L46.6521 51.5337C46.3708 51.6743 44.0366 52.0118 44.0366 52.0118" fill="#FF3FA2"/>
                            <path d="M44.3321 44.669C43.9947 44.2753 41.4918 39.8601 41.4918 39.8601L41.4355 37.0479L44.3321 44.669Z" fill="#00D4FF"/>
                            <path d="M29.638 57.2984H28.7943L26.9102 52.5738L29.638 52.2363V57.2984Z" fill="#74FF91"/>
                            <path d="M33.8097 54.0066L39.378 53.5566C37.9156 54.1191 32.8535 58.6749 32.8535 58.6749" fill="#571A00"/>
                            <path d="M47.2792 55.6234L46.7168 52.8955C47.0824 53.0642 48.8541 54.7516 48.8541 54.7516" fill="#74FF91"/>
                            <path d="M51.9452 52.7163L50.6797 49.5947C51.4952 49.7916 55.4042 51.732 55.4042 51.732" fill="#D8FF00"/>
                            <path d="M47.368 51.4661L42.7559 51.213C44.162 50.5099 51.6988 47.6133 51.6988 47.6133" fill="#00CA00"/>
                            <path class="color-changer-medgray" d="M87.9028 81.1132C89.8598 81.1132 91.4462 79.5268 91.4462 77.5698C91.4462 75.6128 89.8598 74.0264 87.9028 74.0264C85.9458 74.0264 84.3594 75.6128 84.3594 77.5698C84.3594 79.5268 85.9458 81.1132 87.9028 81.1132Z" fill="#0068FF"/>
                        </g>
                        <g v-else-if="name == 'co2'">
                            <path class="color-changer" d="M31.4386 66.1205C38.2414 66.1205 43.7562 60.6057 43.7562 53.8029C43.7562 47.0001 38.2414 41.4854 31.4386 41.4854C24.6358 41.4854 19.1211 47.0001 19.1211 53.8029C19.1211 60.6057 24.6358 66.1205 31.4386 66.1205Z" fill="#0068FF"/>
                            <path class="color-changer" d="M43.3918 55.5746C50.1946 55.5746 55.7093 50.0598 55.7093 43.257C55.7093 36.4542 50.1946 30.9395 43.3918 30.9395C36.589 30.9395 31.0742 36.4542 31.0742 43.257C31.0742 50.0598 36.589 55.5746 43.3918 55.5746Z" fill="#0068FF"/>
                            <path class="color-changer" d="M60.966 53.4652C67.7688 53.4652 73.2835 47.9504 73.2835 41.1476C73.2835 34.3448 67.7688 28.8301 60.966 28.8301C54.1632 28.8301 48.6484 34.3448 48.6484 41.1476C48.6484 47.9504 54.1632 53.4652 60.966 53.4652Z" fill="#0068FF"/>
                            <path class="color-changer" d="M43.3918 75.9632C50.1946 75.9632 55.7093 70.4485 55.7093 63.6457C55.7093 56.8429 50.1946 51.3281 43.3918 51.3281C36.589 51.3281 31.0742 56.8429 31.0742 63.6457C31.0742 70.4485 36.589 75.9632 43.3918 75.9632Z" fill="#0068FF"/>
                            <path class="color-changer" d="M57.4523 66.1205C64.2551 66.1205 69.7699 60.6057 69.7699 53.8029C69.7699 47.0001 64.2551 41.4854 57.4523 41.4854C50.6495 41.4854 45.1348 47.0001 45.1348 53.8029C45.1348 60.6057 50.6495 66.1205 57.4523 66.1205Z" fill="#0068FF"/>
                            <path class="color-changer" d="M60.966 78.0726C67.7688 78.0726 73.2835 72.5578 73.2835 65.755C73.2835 58.9523 67.7688 53.4375 60.966 53.4375C54.1632 53.4375 48.6484 58.9523 48.6484 65.755C48.6484 72.5578 54.1632 78.0726 60.966 78.0726Z" fill="#0068FF"/>
                            <path class="color-changer" d="M72.2141 59.0892C79.0168 59.0892 84.5316 53.5745 84.5316 46.7717C84.5316 39.9689 79.0168 34.4541 72.2141 34.4541C65.4113 34.4541 59.8965 39.9689 59.8965 46.7717C59.8965 53.5745 65.4113 59.0892 72.2141 59.0892Z" fill="#0068FF"/>
                            <path class="color-changer" d="M78.5422 73.1507C85.345 73.1507 90.8597 67.636 90.8597 60.8332C90.8597 54.0304 85.345 48.5156 78.5422 48.5156C71.7394 48.5156 66.2246 54.0304 66.2246 60.8332C66.2246 67.636 71.7394 73.1507 78.5422 73.1507Z" fill="#0068FF"/>
                            <path class="color-changer-blue" d="M44.0454 61.0871C46.6377 61.0871 48.7838 60.1522 50.1862 58.431L47.9764 56.3912C46.9777 57.5598 45.724 58.1548 44.2366 58.1548C41.4531 58.1548 39.477 56.1999 39.477 53.3951C39.477 50.5903 41.4531 48.6354 44.2366 48.6354C45.724 48.6354 46.9777 49.2304 47.9764 50.3778L50.1862 48.3379C48.7838 46.6381 46.6377 45.7031 44.0666 45.7031C39.4345 45.7031 35.9922 48.9117 35.9922 53.3951C35.9922 57.8786 39.4345 61.0871 44.0454 61.0871Z" fill="white"/>
                            <path class="color-changer-blue" d="M59.4028 61.0871C64.0775 61.0871 67.541 57.8361 67.541 53.3951C67.541 48.9542 64.0775 45.7031 59.4028 45.7031C54.7069 45.7031 51.2646 48.9754 51.2646 53.3951C51.2646 57.8148 54.7069 61.0871 59.4028 61.0871ZM59.4028 58.1548C56.7467 58.1548 54.7494 56.2212 54.7494 53.3951C54.7494 50.569 56.7467 48.6354 59.4028 48.6354C62.0589 48.6354 64.0562 50.569 64.0562 53.3951C64.0562 56.2212 62.0589 58.1548 59.4028 58.1548Z" fill="white"/>
                            <path class="color-changer-blue" d="M71.7241 62.9921L73.4346 61.3772C74.4758 60.4104 74.6776 59.7517 74.6776 59.0292C74.6776 57.68 73.5727 56.83 71.9153 56.83C70.5766 56.83 69.5355 57.3719 68.9618 58.2112L70.2154 59.0186C70.5766 58.5193 71.1185 58.2749 71.7559 58.2749C72.5421 58.2749 72.9459 58.6149 72.9459 59.1992C72.9459 59.5605 72.829 59.9429 72.1703 60.5698L69.3017 63.279V64.3945H74.922V62.9921H71.7241Z" fill="white"/>
                        </g>
                    </svg>
                    <div v-if="!loading && !loading_date">
                        <h1 v-if="name == 'economy'" class="m-0 mt-2">{{saved.economy ? Math.round(Number(saved.economy / total.economy) * 100).toLocaleString() : 0}}%</h1>
                        <h1 v-else class="m-0 mt-2">{{Math.round(Number(stat)).toLocaleString()}} {{getCalcUnit(name)}}</h1>
                    </div>
                    <div v-else class="bg-mediumGray rounded-mimbly w-24 h-8 mt-4 skeleton-loader" />
                    <span class="mt-2">{{trans(name)}}</span> 
                </div>
            </div>

            <!-- Headers -->
            <div class="flex flex-col items-center mb-4">
                <h1 class="mt-8 mb-2 text-primary">{{trans(selected_stat)}}</h1>
                <p v-if="selected_stat == 'water'" class="m-0">
                    <span v-if="!loading && !loading_date" class="font-bold">{{numberToLocaleString(saved.water)}} </span>
                    <span v-else class="skeleton-loader h-2 w-4 px-4 mr-2 rounded-mimbly"></span>
                    <span>{{trans('liter_har_sparats_återvinningsgrad_på')}} </span>
                    <span v-if="!loading && !loading_date" class="font-bold">{{recycling_rate !== undefined ? recycling_rate :  0}}%</span>
                    <span v-else class="skeleton-loader h-2 w-4 px-4 ml-2 rounded-mimbly"></span>
                </p>
                <p v-if="selected_stat == 'economy'" class="m-0"><span class="font-bold">{{(saved.economy ? Math.round(Number(saved.economy / total.economy) * 100) : 0).toLocaleString()}}%</span> {{trans('cheeper_vs_running_washing_without_mimbox')}}</p>
                <p v-if="selected_stat == 'microplastics'" class="m-0"><span class="font-bold">{{numberToLocaleString(saved.microplastics)}} </span>g {{trans('reduced_emissions_up_to')}} <span class="font-bold">90%</span></p>
                <span v-if="selected_stat == 'co2'" class="subtitle"><span class="font-bold">{{numberToLocaleString(saved.co2)}}</span> {{' kg ' + trans('minskat_utsläpp_en_minskning_på')}} <span class="font-bold">{{numberToLocaleString(saved.co2 ? (saved.co2 / total.co2) * 100 : 0)}}%</span></span>
            </div>

            <!-- Content -->
            <div class="flex justify-between" style="height: 300px">
                <!-- Water -->
                <div class="px-6 w-3/5 relative" v-if="selected_stat == 'water'">
                    <div style="position: absolute; top:0px;">
                        <h1 v-if="!loading && !loading_date" class="mb-2">{{LITERS_PER_WASH}} l</h1>
                        <div v-else class="bg-mediumGray rounded-mimbly w-24 h-8 mb-2 skeleton-loader" style="margin-top: 17px" />
                        <p class="font-light m-0">{{trans('savings_vatten_förbrukning_per_tvätt')}}</p>
                    </div>
                    <div style="position: absolute; top: 100px;">
                        <h1 v-if="!loading && !loading_date" class="mb-2">{{numberToLocaleString(total.water / LITERS_PER_WASH)}} {{language === 'en' ? '' : 'st'}}</h1>
                        <div v-else class="bg-mediumGray rounded-mimbly w-24 h-8 mb-2 skeleton-loader" style="margin-top: 17px" />
                        <p class="font-light m-0">{{trans('savings_antal_tvättar')}}</p>
                    </div>
                    <div style="position: absolute; top: 200px;">
                        <h1 v-if="!loading && !loading_date" class="mb-2">{{(recycling_rate !== undefined || recycling_rate !== 'NaN') ? numberToLocaleString(LITERS_PER_WASH - (LITERS_PER_WASH * (recycling_rate / 100))) : 0}} l</h1>
                        <div v-else class="bg-mediumGray rounded-mimbly w-24 h-8 mb-2 skeleton-loader" style="margin-top: 17px" />
                        <p class="font-light m-0">{{trans('savings_vatten_förbrukning_per_tvätt_med_mimbox')}}</p>
                    </div>
                </div>
                <!-- Economy -->
                <div class="w-3/5 px-6 relative" v-if="selected_stat == 'economy'">
                    <div style="position: absolute; top:0px;">
                        <h1 class="mb-2">{{numberToLocaleString(saved.water / 1000)}} m³</h1>
                        <p class="font-light m-0">{{trans('savings_ekonomi_vattenbesparing')}}</p>
                    </div>
                    <div style="position: absolute; top: 100px;">
                        <h1 class="mb-2">{{energy_saved ? numberToLocaleString(energy_saved) : 0}} kWh</h1>
                        <p class="font-light m-0">{{trans('savings_ekonomi_elbesparing')}}</p>
                    </div>
                    <!-- place reserved for ROI -->
                </div>
                <!-- Microplastics -->
                <div class="w-3/5 px-6 relative" v-if="selected_stat == 'microplastics'">
                    <div style="position: absolute; top:0px;">
                        <h1 class="mb-2">{{numberToLocaleString(total.water / LITERS_PER_WASH)}} {{language === 'en' ? '' : 'st'}}</h1>
                        <p class="font-light m-0">{{trans('savings_antal_tvättar')}}</p>
                    </div>
                    <div style="position: absolute; top: 100px;">
                        <h1 class="mb-2">700 000 {{language === 'en' ? 'pcs' : 'st'}}</h1>
                        <p class="font-light m-0">{{trans('savings_microplast_per_tvätt')}}</p>
                    </div>
                    <div style="position: absolute; top: 200px;">
                        <h1 class="mb-2">2.00 g</h1>
                        <p class="font-light m-0">{{trans('savings_microplast_vikt')}}</p>
                    </div>
                </div>
                <!-- Co2 -->
                <div class="w-3/5 px-6 relative" v-if="selected_stat == 'co2'">
                    <div style="position: absolute; top:0px;">
                        <h1 class="mb-2">{{numberToLocaleString(saved.water / 1000)}} m³</h1>
                        <p class="font-light m-0">{{trans('savings_ekonomi_vattenbesparing')}}</p>
                    </div>
                    <div style="position: absolute; top: 100px;">
                        <h1 class="mb-2">{{numberToLocaleString(energy_saved ? energy_saved : 0)}} kWh</h1>
                        <p class="font-light m-0">{{trans('savings_ekonomi_elbesparing')}}</p>
                    </div>
                    <div style="position: absolute; top: 200px;">
                        <h1 class="mb-2">{{numberToLocaleString(saved.co2)}} kg</h1>
                        <p class="font-light m-0">{{trans('savings_co2_saved_from_energy_production')}}</p>
                    </div>
                </div>
                <div class="flex-grow flex">
                    <apexchart v-if="!loading && !loading_date && selected_stat !== 'microplastics'" width="100%" type="donut" :options="chartOptions" :series="series"></apexchart>
                </div>
                <div class="absolute text-sm opacity-50 italic" style="bottom: 1rem; left: 1rem;">
                *{{trans('savings_general_disclaimer')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { analytics } from '../../main.js'

export default {
    name: "unitSavings",
    props: ["selectedUnits", "role", "client"],
    watch: {
        selected_month: function(newDate, oldDate) {
            if(newDate == null) {
                this.$emit('filterTime', null)
                return
            }

            if(oldDate && newDate.getMonth() === oldDate.getMonth()) return
            this.loading_date = true;
            this.logEvent('filter_on_month')

            const from = new Date(newDate.getFullYear(), newDate.getMonth(), 1).getTime()
            const to = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0, 23, 59, 59).getTime()
            this.$emit('filterTime', {from, to})
        },
        selected_stat(stat) {
            this.setChartOptions(stat)
            switch (stat) {
                case "economy":
                    this.$refs.container.style.maxHeight = "700px";
                    break;
                default:
                    this.$refs.container.style.maxHeight = "750px";
                break;
            }
        },
        recycling_rate() {
            if(this.recycling_rate === undefined) return;
            this.setChartOptions("water")
        },
        selectedUnits() {
            this.filter();
        },
    },
    methods: {
        logEvent(name) {
            analytics.logEvent('savings_clicked_stat', { stat: name })
        },
        getCalcUnit(name) {
            switch(name) {
                case "water":
                    return "l"
                case "economy":
                    return ""
                case "microplastics":
                    return "g"
                case "co2":
                    return "kg"
            }
        },
        setChartOptions(stat = this.selected_stat){
            switch (stat) {
                case "water":
                    this.series = [parseInt(this.total.water - this.saved.water), parseInt(this.saved.water)]
                    this.chartUnit = 'l'
                    break;
                case "economy":
                    this.series = [parseInt(this.total.economy - this.saved.economy), parseInt(this.saved.economy)]
                    this.chartUnit = 'SEK'
                    break;
                case "microplastics":
/*                  this.series = [Math.ceil((this.saved.microplastics + (this.saved.microplastics * 0.1)) * 0.1), Math.floor(this.saved.microplastics)] */
                    this.series = [0, 0]
                    this.chartUnit = 'g'
                    break;
                case "co2":
                    this.series = [parseInt(this.total.co2 - this.saved.co2), parseInt(this.saved.co2)]
                    this.chartUnit = 'kg'
                    break;
            }
        },
        selectStat(stat) { this.selected_stat = stat },
        async filter() {
            Object.keys(this.saved).forEach(e => this.saved[e] = 0);
            let saved_water = 0
            let used_water = 0
            for (let u of this.selectedUnits) {
                if(u.data.savings_for_range) {
                    saved_water += u.data.savings_for_range.water_saved;
                    used_water += u.data.savings_for_range.water_used;
                    continue;
                }
                if (u.data.water_saved) saved_water += u.data.water_saved;
                if (u.data.water_used) used_water += u.data.water_used;
            }

            saved_water = parseInt(saved_water.toFixed(2));
            const total_water = saved_water + used_water
            const recycling_rate = (saved_water && total_water) ? Math.round(saved_water / total_water * 100) : 0;
            const total_energy_for_water = this.KWH_PER_LITER_SPENT * total_water
            const total_energy_for_recycled = this.KWH_SAVED_PER_LITER_RECYCLED * saved_water

            const saved_microplastics = (total_water / this.LITERS_PER_WASH) * this.PLASTIC_CAPTURES_PER_WASH
            const total_co2 = ((total_water / 1000) * (this.CO2_EMISSIONS_PER_M3_WASTE_WATER + this.CO2_EMISSIONS_PER_M3_WATER_PRODUCED)) + (this.CO2_EMISSIONS_PER_KWH * total_energy_for_water)
            const saved_co2 = ((saved_water / 1000) * (this.CO2_EMISSIONS_PER_M3_WASTE_WATER + this.CO2_EMISSIONS_PER_M3_WATER_PRODUCED)) + (this.CO2_EMISSIONS_PER_KWH * total_energy_for_recycled)

            const economy_for_kwh_spent_with = (total_energy_for_water - total_energy_for_recycled) * this.$customer_prices.energy_price
            const economy_for_kwh_spent_without = total_energy_for_water * this.$customer_prices.energy_price

            const economy_for_water_spent_with = (total_water - saved_water) * (this.$customer_prices.water_price / 1000)
            const economy_for_water_spent_without = total_water * (this.$customer_prices.water_price / 1000)

            const economy_without = economy_for_kwh_spent_without + economy_for_water_spent_without
            const economy_with = economy_for_kwh_spent_with + economy_for_water_spent_with
            
            this.total.economy = economy_without
            this.saved.economy = economy_without - economy_with

            this.saved.water = saved_water
            this.total.water = total_water
            this.saved.microplastics = saved_microplastics
            this.total.co2 = total_co2
            this.saved.co2 = saved_co2
            this.energy_without = total_energy_for_water
            this.energy_saved = total_energy_for_recycled

            this.recycling_rate = recycling_rate
            this.total_energy_for_water = total_energy_for_water
            this.total_energy_for_recycled = total_energy_for_recycled
            
            this.chartOptions.labels = [this.trans('spent'), this.trans('saved')]
            this.setChartOptions()
            this.loading_date = false;
            this.loading = false;
        },
    },
    async mounted(){
        this.filter()
        this.language = localStorage.getItem("selectedLanguage") ?? 'en'
    },
    data() {
        return {
            loading: true,

            selected_month: undefined,
            loading_date: false,
            language: 'en',

            total: {
                economy: 0,
                water: 0,
                microplastics: 0,
                co2: 0
            },
            saved: {
                economy: 0,
                water: 0,
                microplastics: 0,
                co2: 0
            },
            recycling_rate: undefined,
            energy_saved: 0,
            energy_without: 0,
            economy_with: 0,

            selected_stat: "water",

            LITERS_PER_WASH: 55,
            PLASTIC_CAPTURES_PER_WASH: 2, //g - upped from 1.04 to 2 because of andrea's findings
            KWH_SAVED_PER_LITER_RECYCLED: 0.01149,
            KWH_PER_LITER_SPENT: 0.024,
            CO2_EMISSIONS_PER_KWH: 0.045,
            CO2_EMISSIONS_PER_M3_WASTE_WATER: 0.708,
            CO2_EMISSIONS_PER_M3_WATER_PRODUCED: 0.344,
            
            chartUnit: 'l',
            series: [],
            chartOptions: {
                colors: ['#98B8DE', '#96CFB4'],
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    enabled: true,
                    background: {
                        enabled: true,
                        foreColor: '#4A4A4A',
                        borderColor: '#4A4A4A',
                        dropShadow: {
                            enabled: false
                        }
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    show: this.selected_stat === 'economy' ? false : true,
                                    color: '#96CFB4',
                                    formatter: w => 
                                        this.selected_stat === 'economy' ? '100%' : 
                                        w.globals.seriesTotals.reduce((a, b) => a + b, 0).toLocaleString() + ' ' + this.chartUnit
                                },
                                show: true,
                                fontFamily: "Montserrat",
                                value: {
                                    formatter: val => this.selected_stat === 'economy' ? 
                                    (parseInt(val) / this.total.economy * 100).toFixed(1) + '%'
                                    :
                                    parseInt(val).toLocaleString() + ' ' + this.chartUnit
                                },
                            }
                        }
                    }
                },
                labels: [],
            }
        };
    },
}
</script>

<style lang="scss" scoped>
:deep(.p-button-icon-only) {
    height: var(--filter-height) !important;
}

svg {
    max-width: 100%;
}
svg > * {
    pointer-events:none;
    transition: all 250ms ease-in-out;
}
svg:hover {
    .color-changer, circle {
        filter:brightness(1.5);
    }
}
svg.selected {
    circle, .color-changer-blue {
        fill:var(--blue);
    }
    .color-changer {
        fill:var(--lightgray);
    }
    .color-changer-medgray {
        fill:var(--medgray);
    }
}

.icon.cog {
    width:21px;
    aspect-ratio: 1;
    background-image:url('../../assets/cog.svg')
}
</style>