<template>
<div class="background-cover z-50" style="visibility: hidden" @click="maybeHide" data-hide>
    <div class="popup-container comp-container flex d-col w-full md:w-1/2 p-5">
        <div class="absolute right-0 top-0 pr-4 pt-4 cursor-pointer" data-hide>X</div>
        <form class="w-full" onsubmit="return false">
            <h2 class="m-0">{{trans('feedback_and_support')}}</h2>
            <p class="font-paragraph mb-0">{{trans('feedback_and_support_paragraph')}} <a href="mailto:mimboard@mimbly.se">mimboard@mimbly.se</a></p>
            <div class="flex gap-2 font-light">
                <p class="text-primary flex items-center gap-1"><input required class="cursor-pointer w-5 h-5" type="radio" name="picked" value="feedback" v-model="picked">{{trans('feedback')}}</p>
                <p class="text-primary flex items-center gap-1"><input required class="cursor-pointer w-5 h-5" type="radio" name="picked" value="bug" v-model="picked">{{trans('bug')}}</p>
            </div>
            <textarea class="w-full max-w-full min-w-full h-64 rounded-mimbly border border-mediumGray font-paragraph p-2 text-base" style="border-top: 1px solid #E1DFE0; border-left: 1px solid #E1DFE0" v-model="message" placeholder=""></textarea>
            <p v-if="error" class="text-sm text-darkRed">* required</p>
            <div class="mt-4 flex justify-end pr-8">
                <button :class="loading ? 'cursor-wait opacity-50' : ''" :disabled="loading" class="btn" @click="submit">{{trans('submit')}}</button>
            </div>
        </form>
  </div>
</div>
</template>

<style scoped>
* {
    box-sizing: border-box;
}
</style>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default {
    name: 'ContactUs',
    data() {
        return {
            picked: 'feedback',
            message: '',
            initial: true,
            error: false,
            loading: false
        }
    },
    watch: {
        message() {
            if(this.message.length == 0 && !this.initial){
                this.error = true
            } else {
                this.initial = false
                this.error = false
            }
        }
    },
    methods: {
        async submit() {
            if(this.message.length == 0){
                this.error = true
                return
            }
            this.loading = true
            this.$emit('hide')
            const userId = await firebase.auth().currentUser.uid
            const doc_ref = await firebase.firestore().collection("feedback").doc(userId).get()
            const dbFeedback = doc_ref?.data()?.feedback || []
            dbFeedback.unshift({
                type: this.picked,
                message: this.message,
                timestamp: Date.now()
            })
            firebase.firestore().collection("feedback").doc(userId).set({
                feedback: dbFeedback
            }, { merge: true })

            this.cleanUp()
        },
        maybeHide({ target }){
            if(!target.attributes.getNamedItem('data-hide')) return

            this.$emit('hide')
            this.cleanUp()
        },
        cleanUp(){
            this.error = false
            this.initial = true
            this.message = ''
            this.picked = 'feedback'
            this.loading = false
        }
    }
}
</script>