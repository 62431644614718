<template>
<div>
    <h1>{{trans('eol_log')}}</h1>
    <div class="border-2 border-mediumGray border-solid rounded-mimbly overflow-hidden">
    <div class="px-4 py-2 flex justify-center gap-4">
                <span>Logs: {{filtered_logs.length}}</span>
                <span>Warnings: {{filtered_logs.filter(log => log.type === 'ERROR' || log.type == 'WARNING').length}}</span>
            </div>
            <div class="bg-mediumGray" style="height: 1px" />
            <div class="flex items-center justify-between pr-4">
                <input class="my-4 mx-4 w-64" type="text" placeholder="search" @input="e => search(e.target.value)"/>
                <button @click="csv_from_data(eol_logs)" class="btn">download</button>
            </div>
        <div style="max-height: 600px" class="overflow-y-scroll">
            <div class="flex px-4 flex-col" v-for="log in filtered_logs" :key="log.message + log.timestamp">
                <div class="flex py-2">
                    <span :class="`w-1/12 flex-shrink-0 icon ${getIcon(log.type)}`" style="min-width: max-content" />
                    <span class="w-2/3 flex-shrink flex-grow">{{log.message}}</span>
                    <span class="w-1/3 flex justify-end">{{displayDate(log.timestamp, 'long')}}</span>
                </div>
                <div style="height: 1px" class="bg-mediumGray w-full" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'EolLog',
  props: ['eol_logs'],
  data() {
    return {
        filtered_logs: this.eol_logs
    }
  },
  methods: {
    getIcon(type){
        switch(type) {
            case 'ERROR': 
                return 'error';
            case 'WARNING':
                return 'error'
            case 'SUCCESS':
                return 'ach'
            default:
                return 'action'            
        }
    },
    search(e){
        if(e === "") this.filtered_logs = this.eol_logs;
        else this.filtered_logs = this.eol_logs.filter(log => log.message.toLowerCase().includes(e.toLowerCase()))
    }
  }
}
</script>