<template>
    <div>
        <div class="mb-4">
            <Button class="e w-12 h-12 flex justify-center items-center" @click="visibleLeft = !visibleLeft">
                <i class="pi pi-bars text-3xl text-white"></i>
            </Button>
        </div>
        <AdminSidebar :visibleLeft.sync="visibleLeft" @hide="visibleLeft = !visibleLeft" />
        <div class="flex justify-center">
            <h1>User List</h1>
        </div>
        <DataTable ref="dt" class="p-datatable-sm" :loading="loading" sortField="client" :sortOrder="1" :value="users" :filters.sync="filters">
            <template #header>
                <div class="flex justify-between w-full">
                    <div class="flex gap-2">
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter()"/>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                <div class="flex items-center px-4 py-2 border-2 border-solid border-primary text-primary rounded-mimbly hover:bg-primary hover:text-white duration-200 cursor-pointer" @click="exportCSV($event)">Export</div>
                </div>
            </template>
            <Column sortable field="client" header="Company"></Column>
            <Column sortable field="email" header="Email"></Column>
            <Column sortable field="role" header="Role"></Column>
            <Column sortable field="timestamp" header="Created at">
                <template #body="slotProps">
                    {{ slotProps.data.timestamp ? displayDate(parseInt(slotProps.data.timestamp)) : '' }}
                </template>
            </Column>
            <Column sortable field="last_seen" header="Last seen">
                <template #body="slotProps">
                    {{ slotProps.data.last_seen ? displayDate(slotProps.data.last_seen) : '' }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button/Button';
import InputText from 'primevue/inputtext';
import AdminSidebar from '../components/admin_components/AdminSidebar.vue';

import ObjectsToCsv from 'objects-to-csv';

export default {
    name: 'Users',
    components: { AdminSidebar, DataTable, Column, Button, InputText },
    data() {
        return {
            loading: true,
            users: [],
            visibleLeft: false,
            filters: {
                "global": {
                    "value": ""
                }
            },
        }
    },
    methods: {
        async getAllUsers() {
            const users = await firebase.firestore().collection('users').where("client", "!=", "mimbly").get()
            users.forEach(user => {
                if(user.data().email.includes("mimbly") || user.data().email.includes("proton")) return
                this.users.push(user.data())
            })
        },
        clearFilter() {
            this.filters.global.value = ''
        },
        async exportCSV() {
            const csv = new ObjectsToCsv(this.users.map(user => {
                return {
                    "Company": user?.client,
                    "Email": user?.email,
                    "Role": user?.role,
                    "Created at": user?.timestamp ? this.displayDate(parseInt(user.timestamp)) : '',
                    "Last seen": user?.last_seen ? this.displayDate(user.last_seen) : ''
                }
            }))
            const a = await csv.toString()
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(a));
            element.setAttribute('download', `${this.displayDate(Date.now(), 'medium')}_Mimbly_admin - users.csv`);   
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    },
    async mounted(){
        await this.getAllUsers();
        this.loading = false
    }
}
</script>