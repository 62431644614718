<template>
<div class="w-full overflow-hidden">
    <div class="bg-gray-200 flex items-center pr-4 gap-4">
        <div class="flex gap-2 ml-2 items-center">
            <i class="pi pi-ellipsis-v cursor-pointer" @click="(e) => $parent.show(e, getMimboxInfo())"></i>
        </div>
        <div class="flex gap-2 w-full items-center cursor-pointer h-full pl-2" @click="expand">
            <span style="border-style: solid" class="p-2 border-l-2 border-r-2 border-gray-400 border-0">
                <div class="w-16 h-6 rounded-full flex items-center justify-center text-sm text-white" :style="`background-color: ${mimbox.active_errors.length < 1 ? '#96CFB4' : mimbox.active_errors.every(code => code == 17) ? '#f6ad55' : '#FF5A6F'}`">
                    {{ activeErrorHeader(mimbox) }}
                </div>
            </span>
            <!-- <span style="width: 80px; border-style: solid" class="p-2 border-r-2 border-0 border-gray-400 text-sm">{{ activeErrorHeader(mimbox) }}</span> -->
            <div class="w-4 h-4 rounded-full" :style="`background-color: ${checkExpiredMaintenance()}`"/>
            <span style="width: 18%; min-width: 180px; max-width: 300px;" class="p-2 box-shadow-border">{{mimbox.company}}</span>
            <span style="width: 40%; min-width: 400px; max-width: 600px;" class="p-2 box-shadow-border">{{mimbox.name}}</span>
            <span style="width: 2%; min-width: 34px; max-width: 34px;" class="p-2 box-shadow-border flex items-center justify-items-center">
                <div class="w-4 h-4 rounded-full inline-block" :style="`background-color: ${mimbox.is_alive ? '#96CFB4' : '#FF5A6F'}`"/>
            </span> 
            <span style="width: 8%;" class="p-2 box-shadow-border">{{mimbox.hardware}}</span> 
            <span style="width: 8%;" class="p-2 box-shadow-border">v. {{mimbox.software}}</span> 
            <span style="width: 8%;" class="p-2 box-shadow-border">{{mimbox.total_water_processed.toLocaleString()}} l</span>
            <span style="width: 8%;" :class="'p-2 ' + (mimbox.recycling_rate * 100 > 30 ? 'text-green-600' : 'text-orange-700')">{{(mimbox.recycling_rate * 100).toFixed(2)}}%</span>
        </div>
    </div>
    <div class="bg-gray-200 transition-all duration-300 ease-in-out" :style="`height: 320px; max-height: ${expanded ? '320px' : '0'};`">
        <div class="bg-gray-100 flex gap-2 p-2 px-4 h-full">
            <div class="flex flex-col gap-1 w-1/4">
                <span class="text-lg font-semibold">General info</span>
                <span><span class="opacity-50">muid:</span> {{mimbox.muid}}</span>
                <span><span class="opacity-50">installed:</span> {{displayDate(mimbox.installed_timestamp)}}</span>
                <span><span class="opacity-50">days since install:</span> {{Math.round((Date.now() - mimbox.installed_timestamp) / (1000 * 60 * 60 * 24))}}</span>
                <span><span class="opacity-50">latest maintenance:</span> {{mimbox.most_recent_maintenance ? displayDate(mimbox.most_recent_maintenance.timestamp) : 'none on file'}}</span>
                <span><span class="opacity-50">days since latest maintenance:</span> {{mimbox.most_recent_maintenance ? Math.round((Date.now() - mimbox.most_recent_maintenance.timestamp) / (1000 * 60 * 60 * 24)) : 'none on file'}}</span>
                <span><span class="opacity-50">active errors:</span> {{mimbox.active_errors.length > 0 ? mimbox.active_errors : 'none'}}</span>
                <span><span class="opacity-50">total errors for time period:</span> {{mimbox_errors.error_history?.length}}</span>
                <span><span class="opacity-50">last filter bag expired reminder email:</span> {{last_service_reminder ?? 'none on file'}}</span>
                <span><span class="opacity-50">last service reminder email:</span> {{last_maintenance_reminder ?? 'none on file'}}</span>
                
            </div>
            <div class="w-1/4">
                <div class="flex flex-col gap-1">
                    <span class="text-lg font-semibold">Most common codes</span>
                    <span v-for="code in mimbox_errors.general_error_info?.total_codes_by_occurrences" :key="code.code">{{code.code}} <span class="opacity-50">({{error_codes_list[code.code]['en']['label']}})</span>: {{code.count}}</span>
                </div>
            </div>
            <div class="w-1/4 flex-grow" style="max-width: max-content">
                <div class="flex flex-col gap-1">
                    <span class="text-lg font-semibold">10 most recent errors</span>
                    <span v-for="code in mimbox_errors.error_history?.filter((code, index) => index < 11)" :key="code.timestamp + code.code"><span>{{displayDate(code.timestamp)}}</span> - {{code.code}} <span class="text-gray-500">({{error_codes_list[code.code]['en']['label']}})</span></span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>

.box-shadow-border {
    box-shadow: 2px 0px 0px 0px #cbd5e0;
}

.i {
    width:18px;
    height:18px;
    .link {
        background: url('../../assets/external-link.svg') no-repeat;
        background-size: contain;
    }
}

.send {
    background: url('../../assets/send.svg') no-repeat;
    background-size: contain;
}

.link-icon {
    width:18px;
    height:18px;
    background:url('../../assets/external-link.svg') no-repeat;
    background-size: contain;
}

* {
    margin: 0;
    box-sizing: border-box;
}

h1,h2,h3,h4 {
    margin:0;
    padding:0;
}
</style>

<script>
import firebase from 'firebase/compat/app'

export default {
    name: 'Mimbox',
    props: ['menuRef', 'mimbox', 'mimbox_errors', 'error_codes', 'error_codes_list'],
    data() {
        return {
            expanded: false,
            days_since_last_error: undefined,
            days_since_install: undefined,
            last_10: [],
            total_errors: 0,
            last_service_reminder: undefined,
            last_maintenance_reminder: undefined,
        }
    },
    methods: {
        activeErrorHeader(mimbox){
            if(!mimbox.active_errors || mimbox.active_errors.length < 1) return ''
            else if(mimbox.active_errors.every(err => err === 22)) {
                //Possible to spend some time here and figure out which error caused the code.
            }
            return mimbox.active_errors.reduce((acc, curr, i) => i != mimbox.active_errors.length - 1 ? acc + curr + ", " : acc + curr, "")
        },
        async getLastEmails(){
            const doc_ref = firebase.firestore().collection("mimboxes").doc(this.mimbox.muid)
            const doc = await doc_ref.get()
            const data = doc.data()
            if(!data) return
            if(!data.last_service_reminder) this.last_service_reminder = undefined
            else this.last_service_reminder = Math.round((Date.now() - data.last_service_reminder) / (1000 * 60 * 60 * 24))
            if(!data.last_maintenance_reminder) this.last_maintenance_reminder = undefined
            else this.last_maintenance_reminder = Math.round((Date.now() - data.last_maintenance_reminder) / (1000 * 60 * 60 * 24))
        },
        checkExpiredMaintenance(){
            const date_installed = Math.round((Date.now() - this.mimbox.installed_timestamp) / (1000 * 60 * 60 * 24))
            if(date_installed < 7) return '#96CFB4'
            if(!this.mimbox.most_recent_maintenance) return '#FF5A6F'
            const days_since_last_maintenance = Math.round((Date.now() - this.mimbox.most_recent_maintenance.timestamp) / (1000 * 60 * 60 * 24))
            if(30 < days_since_last_maintenance && days_since_last_maintenance > 7) return '#f6ad55'
            return '#96CFB4'
        },
        expand(){
            this.expanded = !this.expanded
        },
        async downloadAllErrors(){
            this.download_all_notifications(this.mimbox.muid)
            return true
        },
        displayCode(code) {
            if(!code) return ''
            return code.map(code => `${code}: ${this.error_codes_list[code].en.label}`).join(', ')
        },
        getMimboxInfo() {
            let service = false
            let maintenance = false

            if(this.mimbox.active_errors.length > 0 && this.mimbox.active_errors.every(code => code == 17)) service = true
            if((this.mimbox?.most_recent_maintenance && (Math.round((Date.now() - this.mimbox.most_recent_maintenance.timestamp) / (1000 * 60 * 60 * 24)) > 7)) || !this.mimbox.most_recent_maintenance) maintenance = true
            return {
                muid: this.mimbox.muid,
                name: this.mimbox.name,
                service,
                maintenance,
            }
        },

    },
    mounted() {
        this.getLastEmails()
    }
}
</script>