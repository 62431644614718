<template>
    <Sidebar :visible.sync="visibleLeft" @hide="check" >
        <div class="flex flex-col gap-2">
            <h2>Mimboard</h2>
            <Button :disabled="$route.name === 'home'" icon="pi pi-home" label="Home" class="p-button-outlined" @click="() => $router.push({ name: 'home' })" />
            <h2>Admin pages</h2>
            <Button :disabled="$route.name === 'admin_stats'" icon="pi pi-chart-bar" label="Stats" class="p-button-outlined" @click="() => $router.push({ name: 'admin_stats' })" />
            <Button :disabled="$route.name === 'admin_users'" icon="pi pi-user" label="User list" class="p-button-outlined" @click="() => $router.push({ name: 'admin_users' })" />
            <Button :disabled="$route.name === 'admin_water_discards'" icon="pi pi-list" label="Event log inspector" class="p-button-outlined" @click="() => $router.push({ name: 'admin_water_discards' })" />
        </div>
    </Sidebar>
</template>

<script setup>
/* eslint-disable */
import Sidebar from 'primevue/sidebar/Sidebar';
import Button from 'primevue/button/Button';
import router from '../../router/routes';

const emit = defineEmits(['hide'])

const check = () => {
    emit('hide')
}

const navigateTo = (name) => {
    router.push({ })
}

const props = defineProps({
    visibleLeft: Boolean
})

</script>