<template>
    <div class="allNotifications comp-container">
        <div class="title flex space-between">
            <h3>{{trans('recent_notifications')}}</h3>
        </div>
        <div class="notification-list">
            <div v-if="loading" class="flex flex-col gap-1 cursor-pointer">
                <!-- eslint-disable-next-line -->
                <span v-if="loading" v-for="el in [1,2,3]" :key="el" class="skeleton-loader h-10 w-full p-2 inline-block box-border" style="border-radius:10px;"></span>
            </div>
            <div v-else class="flex flex-col gap-1 cursor-pointer">
                <div v-for="notification in notifications" :key="notification.timestamp">
                    <a :href="`/#/unit/${notification.muid}`" target="_blank">
                        <div class="bg-white rounded-xl p-2 flex gap-2 items-center h-6">
                            <span class="">{{displayDate(notification.timestamp, 'short')}}</span>
                            <span class="overflow-hidden whitespace-no-wrap">{{notification.name}}</span>
                            <span class="items-center flex whitespace-no-wrap flex-1 justify-end">{{formatErrorCode(notification.error_code)}}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.comp-container{
    background:var(--medgray);
    border:none;
    padding:1rem;
    min-height:0;
    max-height:none;
    height:15rem;
    overflow:hidden;
}

.notification-list {
    height:80%;
    overflow-y:auto;
}

h3 {
    margin:0;
}

.title {
    width:100%;
    margin-bottom:20px;
}
</style>

<script>
export default{
    name: "allNotifications",
    data(){
        return {
            notifications: [],
            loading: true,
        }
    },
    props: ["filtered_units"],
    async mounted(){
        this.notifications = await this.getNotifications()
        this.loading = false
    }
}
</script>