<template>
    <div class="background-cover">
        <div class="content comp-container" v-on-click="hide" data-validation-container>
            <div class="header flex items-center space-between">
                <div class="flex gap-05 items-center">
                    <h3>{{trans('edit_mimbox')}}</h3>
                    {{possible_technician}}
                </div>
                <div class="icon close w-12 h-12" @click="$emit('hide')"></div>
            </div>
            <div class="id item-container">
                <h4>{{trans('mimbox_id')}}</h4>
                <span>{{unit.id}}</span>
            </div>
            <div class="name item-container">
                <h4>{{trans('mimbox_name')}}</h4>
                <input :disabled="role === 'craftsman' && unit.data.company !== userClient" type="text" name="name" v-model="mimbox.name" data-required @input="handleValidation($event.target, true)">
            </div>
            <div class="mimbox-site flex d-col gap-05 item-container">
                <label>{{trans('city')}}</label>
                <input :disabled="role === 'craftsman' && unit.data.company !== userClient" type="text" class="blue-box" v-model="mimbox.city">
            </div>
            <div class="mimbox-site flex d-col gap-05 item-container">
                <label>{{trans('address')}}</label>
                <input :disabled="role === 'craftsman' && unit.data.company !== userClient" type="text" class="blue-box" v-model="mimbox.address">
            </div>
            <div class="tags item-container">
                <label>{{trans('tags')}}</label>
                <vue-tags-input
                    class="tags-input"
                    v-model="tag"
                    :tags="unit.data.tags"
                    @tags-changed="newTags => mimbox.tags = newTags"
                />
            </div>
            <div class="dep item-container">
                <h4>{{trans('dependencies')}}</h4>
                <div v-for="section in mimbox.dependencies" :key="section.key">
                    <div v-if="role !== 'admin'"><span class="text-xs opacity-50">{{section.key}}:</span> {{section.set[0]}}</div>
                    <ScrollDropdown class="scroll-dropdown" v-else :label="section.key" :itemsArr="section.options" :selItem="section.set" @set="setMimbox(section, ...arguments)"/>
                </div>
                <!-- eslint-disable-next-line -->
            </div>
            <button 
            @click="update($event.target)"
            class="btn create">{{trans('save')}}</button>
        </div>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: 'editMimbox',
    props: ['unit', 'possible_technician', 'possible_companies', "role"],
    components: {
        VueTagsInput,
    },
    data() {
        return {
            tag: '',
            mimbox: false,
            userClient: undefined
        }
    },
    methods: {
        hide(){
            this.$emit('hide')
        },
         setMimbox(section, item) {
            let index = this.mimbox.dependencies.indexOf(section);
            this.mimbox.dependencies[index].set = [item];
        },
        async update(target) { //updateUnit(unit_id, data){
            if(this.mimbox.name === '') {this.handleValidation(target); return}
            let m = this.mimbox
            let tags = this.unit.data.tags ?? []
            m.tags.forEach((e) => tags.push(e.text.toLowerCase()))
            tags = [...new Set(tags)]
            let mimbox_data = {
                name: m.name,
                tags: tags,
                address: m.address,
                city:m.city,
                technician: m.dependencies[0].set[0],
                company: m.dependencies[1].set[0],
            }
            this.$emit('hide')
            await this.updateUnit(this.unit.id, mimbox_data);
            this.$router.go(0);
        },
    },
    async mounted(){
        this.mimbox= {
                tags: [],
                name: this.unit.data.name,
                city: this.unit.data.city,
                address: this.unit.data.address,
                dependencies: [
                {
                    key: "Service Technician",
                    options: await this.getTechList(),
                    set: [this.unit.data.technician]
                },
                {
                    key: "Company",
                    options: await this.getClientList(),
                    set: [this.unit.data.company]
                },
            ]
        }
        this.userClient = localStorage.getItem("client")
    }
}
</script>

<style scoped lang='scss'>
.header {
    h3 {
        margin:0;
    }
    margin-bottom:30px;
}

.content {
    width:370px;
    height:max-content;
    box-sizing: border-box;
    padding:30px;
    border: var(--border-width) solid var(--medgray);
    box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
    position:absolute;
    top:5rem;
    overflow-y:auto;
    margin-bottom:2rem;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.dep {
    pointer-events:none;
}

h4 {
    margin-top: 0;
    margin-bottom:.5rem;
}

label {
    font-weight: 700;
}

input {
    width:100%;
    text-transform: none !important;
}

.scroll-dropdown::v-deep *{
    --width: calc(370px - 60px);
}

.close {
    z-index:5;
}

.item-container {
    margin-top:20px;
}

button {
    margin-top:30px;
}

/* VUE TAGS */

.tags-input::v-deep {
    margin-top:.5rem;

    .ti-input {
        border: var(--border-width) solid var(--lightgray);
        border-radius: var(--border-radius)
    }
    .ti-new-tag-input {
        padding-left:.5rem;
        width: 7rem;
        min-width:7rem;
        box-sizing:border-box;
        text-transform:none;
        flex-grow:0;
    }

    .ti-tags {
        align-items:center;
    }

    .ti-tag {
        border-radius: var(--border-radius);
        height: var(--filter-height);
        min-width:7rem;
        width:auto;
        max-width:10rem;
        overflow:hidden;
        background-color: var(--white);
        border: var(--border-width) solid var(--lightgray);
        color: var(--blue);
        justify-content: space-between;
    }

    .ti-deletion-mark {
        background-color: var(--red);
        color: var(--black);
        border-color: var(--black)
    }
}
</style>