<template>
<div class="login-register-container">
  <h3 v-if="type == 'login'">{{trans('log_in')}}</h3>
  <h3 v-if="type == 'register'">{{trans('create_an_account')}}</h3>
    <!-- Login -->
    <!-- enter key to login or send forgot password email -->
    <form @keyup.enter="forgot_password ? $emit('forgot', email) : $emit('login', email, password);" onsubmit="return false">
        <div class="container comp-container" v-if="type == 'login'">
            <div class="input-field">
                <label for="email">{{trans('email')}}</label>
                <input type="email" v-model="email"  id="loginEmail" class="blue-box" placeholder="" />
            </div>
            <div v-if="!forgot_password" class="input-field password-field">
                <label for="password">{{trans('password')}}</label>
                <input type="password" v-model="password"  id="loginPassword" class="blue-box" placeholder="" />
            </div>
            <!-- error note -->
            <div v-if="inputerror || loginError" ref="errorNote" class="login-error-note">{{trans('something_went_wrong_in_the_form')}}</div>
            <div v-else ref="errorNote" class="login-error-note"></div>

            <div class="submit flex items-center">
                <button v-if="!forgot_password" class="login" @click="handleLogin">{{trans('log_in')}}</button>
                <button v-else class="login" @click="$emit('forgot', email); forgot_password = false">{{trans('reset_password')}}</button>
                <a v-if="!forgot_password" href="#" @click="forgot_password = true">{{trans('forgot_password')}}</a>
                <a v-else href="#" @click="forgot_password = false">{{trans('log_in')}}</a>
            </div>
        </div>
    </form>
    <!-- Register -->
    <div class="container comp-container" v-if="type == 'register'">
        <div class="inputs">
            <div class="input-field" v-for="input in registerInputs" :key="input.name">
                <label :for="input">{{trans(input.type)}}</label>
                <input v-if="input.type === 'password' || input.type === 'repeat_password'" type="password"  :name="input.type" v-model="data[input.type]" class="blue-box">
                <input v-else type="text" :name="input.type" v-model="data[input.type]" class="blue-box">
            </div>
        </div>
        <!-- error note -->
        <div v-if="inputerror" ref="errorNote" class="error-note">{{trans('incorrect_email_or_pw')}}</div>
        <div v-else ref="errorNote" class="error-note"></div>

        <div class="submit flex items-center">
            <button class="login" @click="registerFunc()">{{trans('register')}}</button>
            <div class="terms_container">
                <span class="terms_description">{{ trans('by_selecting_register_you_agree_to_our') }}</span> <a href="https://www.mimbly.se/termsandconditions" target="_blank">{{trans('terms_and_conditions')}}</a>
            </div>
        </div>
        
    </div>
    <div class="footer_text">
            <span>{{ trans('you_can_read_more_about_how_we_handle_your_info')}} <a target="_blank" href="https://www.mimbly.se/privacypolicy">{{trans('privacy_policy')}}.</a></span>
        </div>
</div>
</template>

<script>
export default {
    name: 'LoginRegister',
    props: ['type', 'userData', 'loginError'],
    data() {
        return {
            inputerror: false,
            data : {},
            email: '',
            password: '',
            forgot_password: false,
            registerInputs: [
                {type: 'name', value: ''},
                {type: 'password', value: ''},
                {type: 'repeat_password', value: ''},
                // {type: 'email', value: ''},
                {type: 'phone', value: ''},
                {type: 'job_title', value: ''},
                ]
        }
    },
    watch: {
        loginError: (val) => {
            this.inputerror = val
        }
    },
    methods: {
        async registerFunc() {
            if(!this.data.name || !this.data.password || !this.data.repeat_password) {this.inputerror = true; return}
            this.inputerror = false;
            let email = this.invited_data.email
            this.data.client = this.invited_data.client
            if(this.data.password != this.data.repeat_password){
                alert("Passwords dont match")
                return
            }
            let password = this.data.password
            delete this.data.password
            delete this.data.repeat_password
            await this.register(email, password, this.invited_data, this.$route.params.register_id)
            this.$router.push('/')
            // if(!e){alert(JSON.stringify(this.registerInputs)); return}
            // alert(e.target)
        },
        handleLogin(){
            if(!this.email || !this.password) {this.inputerror = true; return}
            this.$emit('login', this.email, this.password)
        }
    },
    mounted(){
        this.getInvited(this.$route.params.register_id)
    }
}
</script>

<style scoped lang="scss">
.container {
    padding:25px 20px;
    padding-bottom:30px;
    font-weight: 700;
    color: var(--black);
    border:none;
    background-color: var(--medgray)
}


input, input:focus, input:active {
    outline:none;
    border:none;
    width:100% !important;
    background-color: var(--white)
}

.input-field {
    margin-bottom: 2rem;
    input{
        margin-top:.5rem;
    }
}

.terms_container {
    font-size: 12px;
    font-weight: 400;
    text-align: right;
}

.footer_text {
    font-size: 12px;
    margin-top:18px;
    padding-inline: 20px;
}

.inputs > div:last-of-type, .password-field {
    margin-bottom:0;
}

.blue-box {
    text-transform: none;
    padding:0 .5rem;
    width:95%;
    background: var(--white);
}

.error-note, .login-error-note {
    color: var(--darkred);
    font-weight:400;
}

.error-note {
    margin-top:20px;
    margin-bottom:30px;
}

.login-error-note {
    margin-top:20px;
    margin-bottom:20px;
}
a {
        font-weight: 400;
        text-decoration: underline;
        text-transform: capitalize;
    }

.submit {
    justify-content: space-between;
}

.login {
    background:var(--lightblue);
    padding:.5rem 2.5rem;
    border-radius:2rem;
    color: var(--white);
    font-weight:400;
    text-transform: capitalize;
}
</style>