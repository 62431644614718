import Vue from 'vue'
import App from './App.vue'
import router from './router/routes'

require('@/assets/scss/main.scss')
require('./styles.css')

require("primevue/resources/themes/saga-blue/theme.css")
require("primevue/resources/primevue.min.css")
require("primeicons/primeicons.css")


// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'

// Vue.use(VueMaterial)

Vue.config.productionTip = false

// import LineChart from './components/LineChart'
// Vue.component('LineChart', LineChart)

/* Matomo */
import VueMatomo from 'vue-matomo'
Vue.use(VueMatomo, {
    host: 'https://mimbly.matomo.cloud/',
    siteId: 3,
    router: router,
    enableHeartBeatTimer: true,
})

/* PrimeVue */
import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);

import Calendar from 'primevue/calendar';
Vue.component('Calendar', Calendar);

import Carousel from 'primevue/carousel';
Vue.component('Carousel', Carousel);

import Galleria from 'primevue/galleria';
Vue.component('Galleria', Galleria);

import ImagePreview from 'primevue/imagepreview';
Vue.component('ImagePreview', ImagePreview);

import Card from 'primevue/card';
Vue.component('Card', Card);

import ConfirmationService from 'primevue/confirmationservice';
Vue.use(ConfirmationService);

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);

import Toast from 'primevue/toast';
Vue.component('Toast', Toast);

import ScrollPanel from 'primevue/scrollpanel';
Vue.component('ScrollPanel', ScrollPanel);

import PrimeDropdown from 'primevue/dropdown';
Vue.component('PrimeDropdown', PrimeDropdown);


import Tooltip from 'primevue/tooltip';
Vue.directive('tooltip', Tooltip);


// *****

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import vTitle from 'vuejs-title'
Vue.use(vTitle, {
    bgColor: "#4A4A4A"
})

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component('DatePicker', DatePicker)

import svg from 'svg-progress-bar'
Vue.use(svg)

import {showAt} from 'vue-breakpoints'
Vue.component('show-at', showAt)

import {hideAt} from 'vue-breakpoints'
Vue.component('hide-at', hideAt)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

import VueQrcode/*, { version }*/ from '@chenfengyuan/vue-qrcode';
Vue.component('vue-qrcode', VueQrcode);

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)


import topDiv from './components/topDiv'
Vue.component('topDiv', topDiv)
import Footer from './components/Footer'
Vue.component('Footer', Footer)
import modalMenu from './components/modalMenu'
Vue.component('modalMenu', modalMenu)



import unitList from './components/home_components/unitList'
Vue.component('unitList', unitList)
import unitFilter from './components/home_components/unitFilter'
Vue.component('unitFilter', unitFilter)
import unitSavings from './components/home_components/unitSavings'
Vue.component('unitSavings', unitSavings)
import addNew from './components/home_components/addNew'
Vue.component('addNew', addNew)
import editMimbox from './components/home_components/editMimbox'
Vue.component('editMimbox', editMimbox)
// import achievements from './components/home_components/achievements'
// Vue.component('achievements', achievements)
import allNotifications from './components/home_components/allNotifications'
Vue.component('allNotifications', allNotifications)
import getSupport from './components/getSupport'
Vue.component('getSupport', getSupport)
import LoginRegister from './components/landingpage_components/LoginRegister'
Vue.component('LoginRegister', LoginRegister)
import Performance from './components/home_components/Performance'
Vue.component('Performance', Performance)
import Dropdown from './components/Dropdown'
Vue.component('Dropdown', Dropdown)
import ScrollDropdown from './components/ScrollDropdown'
Vue.component('ScrollDropdown', ScrollDropdown)
import Account from './components/Account'
Vue.component('Account', Account)
import Information from './components/Information'
Vue.component('Information', Information)
import UnitPerformance from './components/singleUnit_components/UnitPerformance'
Vue.component('UnitPerformance', UnitPerformance)
import ProgressBar from './components/ProgressBar'
Vue.component('ProgressBar', ProgressBar)
// import data_detail from './components/data_detail'
// Vue.component('data_detail', data_detail)
// import data_economy_detail from './components/data_economy_detail'
// Vue.component('data_economy_detail', data_economy_detail)
// import errors_detail from './components/errors_detail'
// Vue.component('errors_detail', errors_detail)
// import filters_detail from './components/filters_detail'
// Vue.component('filters_detail', filters_detail)
//------------------------------------------------------------------------------

import {data} from "@/mixins/data"
Vue.mixin(data)
import {auth} from "@/mixins/auth"
Vue.mixin(auth)
//------------------------------------------------------------------------------



import firebase from 'firebase/compat/app';
require('firebase/compat/auth')
require('firebase/compat/firestore')
require('firebase/compat/storage')
require('firebase/compat/functions')
require('firebase/compat/analytics')

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    projectId: process.env.VUE_APP_PROJECTID,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPID,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};


firebase.initializeApp(firebaseConfig);
export const fb = firebase;
export const storage = firebase.storage()
export const functions = firebase.functions();
export const analytics = firebase.analytics();

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')