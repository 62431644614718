<template>
<div class="relative">
    <div v-if="loading" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; " class="flex items-center justify-center">
        <div style="left: 0; top: 0; right: 0; bottom: 0; opacity: 0.4; position: absolute;" class="bg-black"></div>
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem; color: rgb(74, 74, 74)"></i>
    </div>
    <div class="w-full h-05 bg-gray-600"></div>
    <div class="border-1 border-gray-600 mt-4 border-solid">
        <div class="flex justify-center items-center gap-4 mt-4 p-2 border-b-2">
            <span class="text-3xl">{{time?.from ? displayDate(time.from) : '-'}}</span>
            -
            <span class="text-3xl">{{time?.to ? displayDate(time.to) : '-'}}</span>
        </div>
        <div class="flex justify-around items-center gap-1 overflow-hidden" style="height: 350px; border-left: 1px solid rgb(233, 236, 239); border-right: 1px solid rgb(233, 236, 239);">
            <apexchart v-if="!loading" height="300px" width="700px" :options="last_codes_options" :series="last_codes_series"></apexchart>
            <div class="bg-gray-600 h-full w-1" />
            <apexchart v-if="!loading" height="300px" width="700px" :options="total_codes_options" :series="total_codes_series"></apexchart>
        </div>
    </div>
    <div class="flex justify-center items-center gap-4 mt-4 p-2 border-b-2">
        <span class="text-3xl">Mimbox list</span>
    </div>
    <div class="flex justify-between bg-gray-600 border-gray-600 border-t-2 border-solid gap-1 overflow-hidden">
        <div v-tooltip.top="generalTooltip[key]" class="flex flex-col items-center bg-gray-100 p-2 flex-1" v-for="key in Object.keys(information).filter(key => key != 'requested_at')" :key="key">
            <span class="text-xs">{{key}}</span>
            <span v-if="key.includes('recycle_rate')" class="text-2xl">{{information?.[key] ? (information[key] * 100).toFixed(2) : '-'}}%</span>
            <span v-else-if="key.includes('days') && key.includes('since')" class="text-2xl">{{information?.[key] ?? '-'}} days</span>
            <span v-else-if="key.includes('mimboxes_with_active_errors')" class="text-2xl">{{information?.[key] ? information[key][0] : '-'}} <span class="text-lg">({{information?.[key] ? information[key].reduce((curr, acc) => curr + acc, 0) : '-'}})</span></span>
            <span v-else-if="key.includes('water')" class="text-2xl">{{information?.[key] ? parseInt(information[key].toFixed()).toLocaleString() : '-'}}</span>
            <span v-else class="text-2xl">{{information?.[key] ? information[key].toLocaleString() : '-'}}</span>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'General',
    props: ['information', 'time', 'errors_listed', 'error_codes_list', 'loading'],
    data() {
        return {
            generalTooltip: {
                average_recycle_rate: 'The average recycle rate of all mimboxes all-time',
                total_water_processed: 'The total amount of water processed by all mimboxes all-time',
                total_water_recycled: 'The total amount of water recycled by all mimboxes all-time',
                average_water_processed: 'The average amount of water processed by a mimbox',
                average_days_since_install: 'The average number of days since installation of a mimbox',
                total_mimboxes: 'Total active installed mimboxes minus interal testing mimboxes. (WQ testbox, test_mimbox)',
                mimboxes_with_active_errors: 'The number of mimboxes with active errors. The first number is all errors except number 17, and the second number is all errors including number 17',
                average_days_since_last_error: 'The average number of days since the last error of a mimbox. Only includes mimboxes that has had an error in this time period.',
                most_common_last: 'The most common "most recent" error of all mimboxes that had an error in this time period.'
            },
            last_codes_options: {
                stroke: {
                    width: [0, 4]
                },
                title: { text: "last codes in time period" },
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    id: 'last_codes',
                },
                xaxis: {
                    categories: []
                },
                yaxis: [
                    {
                        title: {
                            text: "Latest Codes count",
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: "Percentage of latest",
                        },
                        max: 100,
                        min: 0
                    }
                ]
            },
            last_codes_series: [{
                name: 'Total Codes',
                type: 'bar',
                data: []
            },
            {
                name: 'Percentage of last codes',
                type: 'line',
                data: []
            }],
            total_codes_options: {
                tooltip: { 

                 },
                stroke: {
                    width: [0, 4]
                },
                title: { text: "total codes for time period" },
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    id: 'total_codes',
                },
                xaxis: {
                    categories: []
                },
                yaxis: [
                    {
                        title: {
                            text: "Total Codes",
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: "Percentage of total",
                        },
                        max: 100,
                        min: 0
                    }
                ]
            },
            total_codes_series: [{
                name: 'Total Codes',
                type: 'bar',
                data: []
            },
            {
                name: 'Percentage of total',
                type: 'line',
                data: []
            }],
        }
    },
    watch: {
        errors_listed: {
            handler(){
                this.renderTotalChart()
                this.renderLastChart()
            },
            deep: true
        }
    },
    methods: {
        renderPercentageLine(series) {
            const total_errors = series.reduce((acc, curr) => acc + curr, 0)
            const totals = series.reduce((acc, curr, i) => {
                if(i === 0) acc.push(curr)
                else acc.push(curr + acc[i - 1])
                return acc
            } ,[])
            return totals.map((total) => Math.ceil(total / total_errors * 100))
        },
        renderTotalChart(){
            this.total_codes_options.xaxis.categories = this.errors_listed.total_codes_by_occurrences.map((error) => error.code)
            const series = this.errors_listed.total_codes_by_occurrences.map((error) => error.count)
            this.total_codes_series[0].data = series
            this.total_codes_series[1].data = this.renderPercentageLine(series)
        },
        renderLastChart(){
            this.last_codes_options.xaxis.categories = this.errors_listed.latest_codes_by_occurrences.map((error) => error.code)
            const series = this.errors_listed.latest_codes_by_occurrences.map((error) => error.count)
            this.last_codes_series[0].data = series
            this.last_codes_series[1].data = this.renderPercentageLine(series)
        },
    }
}
</script>