<template>
    <div class="connected-check">
        <div class="flex-row flex items-center">
            <h1 class="name">{{unit.name}}</h1>
            <div class="flex items-center gap-05">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" :fill="color"/>
                </svg>
                <span class="connected">{{(color == 'var(--green)') ? trans('connected') : trans('disconnected')}}</span>
            </div>
        </div>
        <div class="flex-row flex items-center">
            <span class="box-id">{{`${trans('mimbox_id')}: ${unit.id}`}}</span>
            <span v-if="role == 'admin'" class="box-id">{{`${trans('serial_no')}: ${unit.serial_nr}`}}</span>
            <span v-if="role == 'admin'" class="box-id">{{`${trans('icc_id')}: ${unit.iccid}`}}</span>
            <span v-if="role == 'admin'" class="box-id">{{`${trans('sw_ver')}: ${unit.sw_ver}`}}</span>
            <span v-if="role == 'admin'" class="box-id">{{`${trans('hardware')}: ${unit.mimbox_vers}`}}</span>
        </div>
    </div>
</template>

<!-- Desktop -->
<style lang="scss" scoped>
    .flex-row {
        width:max-content;
        gap:35px;
        .connected {
            text-transform: uppercase;
        }
    }

    .name {
        margin: 0;
        color: var(--black);
        margin-right:1rem;
        font-size:32px;
    }

    .flex-row:last-of-type {
        margin-top: 1rem;
    }
</style>

<!-- mobile -->
<style scoped lang="scss">
@media screen and (max-width: 1128px){
    .connected-check {
        display:flex;
        flex-direction:column;
        align-items:center;
        padding-top:1rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    .connected {
        font-size:.75rem;
    }
}
</style>

<script>
export default{
    name: "singleUnitDetails",
    props: ["last_alive_time", "unit", "role"],
    computed: {
        color: function(){
            if(this.$props?.unit?.access?.some(a => a === 'demo_company')) return 'var(--green)'
            if(this.$props.last_alive_time+(1000*60*60*2) > new Date().getTime()) return 'var(--green)'
            return 'var(--lightred)'
        }
    }
}
</script>