<template>
<div class="background-cover z-50">
    <div class="popup-container comp-container flex d-col">
        <div class="title flex items-center space-between">
            <div class="flex gap-1">
                <div class="icon cog"></div>
                <h3>{{trans('adjust_calculations')}}</h3>
            </div>
            <div class="icon close w-8 h-8" @click="$emit('hide')"></div>
        </div>
      <div class="form">
          <span class="desc">{{trans('input_your_current')}}</span>
          <div class="power">
              <label for="power">{{trans('electicity_price')}}</label><input v-model="energy_price" type="text" name="power"><span>{{units.power}}</span>
          </div>
          <div class="water">
              <label for="water">{{trans('water_price')}}</label><input v-model="water_price" type="text" name="water"><span>{{units.water}}</span>
          </div>
      </div>
      <span class="explaination">
          <h4>{{trans('economy_calculation_title')}}</h4>
          {{trans('economy_calculation_calc')}}
      </span>
      <button class="save" @click="$emit('adjustCalculation', { water_price, energy_price }); $emit('hide')">{{trans('save')}}</button>
  </div>
</div>
</template>

<script>
export default {
    name: 'adjustCalculation',
    data() {
        return {
            water_price: this.$customer_prices.water_price,
            energy_price: this.$customer_prices.energy_price,
            units: {
                power: 'SEK/kWh',
                water: 'SEK/m3',
            },
        }
    },
    mounted(){
        this.water_price = this.$customer_prices.water_price
        this.energy_price = this.$customer_prices.energy_price
    }
}
</script>

<style scoped lang="scss">
.popup-container {
    background:var(--white);
    border: var(--border-width) solid var(--medgray);
    width:700px;
    padding:30px;
    min-height:0;
    gap:20px;
    justify-content: space-between;
    box-sizing:border-box;
}

.cog {
        background-image:url('../assets/cog.svg');
        transform: scale(1.5)
    }

h3 {
    margin:0;
}

h4 {
    margin:0;
    margin-bottom:.25rem;
}

.desc {
    padding-bottom:1rem;
    display:block;
}

.power {
    margin-bottom:1rem;
}

input[type='text']{
    display:inline-block;
    width:60px;
    height:25px;
    margin:0 1rem;
    border-radius:5px;
    font-size: 1rem;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.save {
    background:var(--lightblue);
    margin-top:10px;
    padding:.5rem 2.5rem;
    border-radius:2rem;
    color: var(--white);
    font-weight:400;
    text-transform: capitalize;
    max-width:120px;
    cursor:pointer;
}
</style>

<!-- mobile -->
<style lang="scss" scoped>
@media screen and (max-width: 1128px) {
    .popup-container {
        width:90vw;
        height:400px;
        text-align: center;
    }
}
</style>