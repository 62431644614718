<template>
    <div class="topDiv flex" v-if="$route.name != 'publicUnit' && !$route.name.includes('admin')">
        <div class="left-toolbar flex">
            <div v-if="$route.name == 'home'" class="icon arrow"></div>
            <a v-if="$route.name == 'home'" href="http://www.mimbly.se">mimbly.se</a>
            <div v-if="$route.name == 'singleUnit' || $route.name == 'help' || $route.name == 'admin' || $route.name == 'users' || $route.name == 'water_discards'" class="icon back" @click="$router.push('/')"></div>
        </div>
        <div v-if="!user || user.client !== 'Girbau'" class="icon logo flex justify-center items-end" @click="$router.push('/')" />
        <div v-else class="icon girbau-logo flex justify-center items-end" @click="$router.push('/')" />
        <div class="user-btns flex" v-if="$route.name != 'login' && $route.name != 'register'">
            <div class="icon info" @click="showInfo = !showInfo" data-open-info></div>
            <Information :showInfo="showInfo" @hide="() => showInfo = false"/>
            <div v-if="getLang()=='en'" @click="setLang('se')" class="icon flag se"></div>
            <div v-else-if="getLang()=='se'" @click="setLang('en')" class="icon flag en"></div>
            <Account :show="show" @hide="() => show = false" :user="getUser()"/>
            <div class="icon user" @click="show = !show" data-open-account></div>
        </div>
    </div>
</template>


<!-- desktop -->
<style lang="scss" scoped>
.topDiv{
    justify-content: center;
    align-items:center;
    width:95%;
    height:10rem;
    margin:0 auto;
    position:relative;
}

.left-toolbar{
    height:1rem;
    align-items: center;
    gap:1rem;
    left:0;
    position:absolute;
}

.user-btns{
    position:absolute;
    right:0;
    gap:1.5rem;
}

.icon {
    background-repeat: no-repeat;
}

.arrow {
    background:url('../assets/arrow.svg');
    width:32px;
    height:13px;
}

.logo {
    background:url('../assets/dashboard-logo.svg');
    width:140px;
    height:118px;
    cursor:pointer;
}

.girbau-logo {
    background:url('../assets/girbau/logo.svg');
    width:350px;
    height:100px;
    cursor:pointer;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
}

.user, .faq, .flag, .info{
    width:44px;
    height:44px;
    cursor:pointer;
    background-repeat:no-repeat;
    background-size:cover;
}

.se {
    background-image:url('../assets/sweden_flag.svg');
}

.en {
    background-image:url('../assets/uk_flag.svg');
}

.user {
    background-image:url('../assets/user.svg');
}

.faq {
    background-image:url('../assets/faq.svg');
}

.info {
    background-image:url('../assets/images/information/icon-info.svg')
}

.back {
    background-image:url('../assets/back-arrow.svg');
    width:30px;
    height:30px;
    background-position: center;
    cursor: pointer;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1128px){
.topDiv {
    height:4rem;
    width:90%;
    box-sizing: border-box;
    padding-top:2rem;
    margin-bottom:1rem;
}

.icon {
    transform:scale(.90)   
}

.logo {
    transform:scale(.6)
}

.arrow {
    transform:scale(.75);
}

a {
    transform:translateX(-1rem)
}
}
</style>

<script>
export default {
    name: 'topDiv',
    data() {
        return {
            user: undefined,
            show: false,
            showInfo: false,
        }
    },
    methods: {
        logoutFunc(){
            this.logout()
        },
        getLang(){
            let a = localStorage.getItem("selectedLanguage")
            if(a){
                return a
            }else{
                return "en"
            }
        },
    },
    async mounted() {
        this.user = await this.getUser()
    }
}
</script>