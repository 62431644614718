<template>
    <div class="filter" data-dropdown data-dropdown-no-close>
        <div class="label blue-box">
        <span>{{label}}</span><div v-if="itemsArr.length > 0" class="icon down-arrow"></div>
        </div>
        <div class="items" data-dropdown-list>
            <div class="spacer"></div>
            <div class="scroll" ref="scroll">
                <div v-for="(item, index) in itemsArr" :key="item" class="item"
                @click="$emit('set',item)"
                :class="{'last-item': itemsArr.length - 1 == index}"
                ><span>{{item}}</span><div v-if="selItem.includes(item)" class="icon check"></div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScrollDropdown',
    props: {
        label: {
            type: String,
            default: 'Dropdown'
        },
        itemsArr: {
            type: Array,
            default: () => []
        },
        selItem: {
            type: Array,
            default: () => []
        }
    },
}
</script>

<style lang="scss" scoped>
* {
    width:15rem;
    width: var(--width)
}

.filter {
    cursor:pointer;
    pointer-events:auto;
    margin-bottom:.5rem;
    max-height:auto;
    transition: margin-bottom 250ms linear; /* Not a great fix for the dropdowns but good enough for testing ? */
}

.filter.active {
    margin-bottom:calc(125px - var(--filter-height) - .5rem);
}

.label, .icon {
    pointer-events:none;
    text-transform: none;
}
.label {
    padding-left:.5rem;
    position:relative;
    width:var(--width);
    z-index:2;
}

.down-arrow.active {
    transform: translateY(-5%) rotate(180deg)
}

.spacer {
    height:.5rem;
    width:var(--width)
}

.scroll {
    height: 5rem;
    overflow-y:auto;
    overflow-x:hidden;
    pointer-events:auto;
}

.scroll::v-deep simplebar-track {
    margin:0 !important;
}

.items {
    transform-origin: top;
    pointer-events:none;
    transition: max-height 400ms ease;
    max-height:0;
    z-index:1;
    transform:translateY(-.5rem);
    box-sizing: border-box;
    border: calc(var(--border-width)/2) solid var(--blue);

}

.items.active {
    max-height:calc(125px - var(--filter-height));
    width:var(--width);
    border-top:none;
    border-radius:var(--border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height:auto;
}

.items.active filter {
    color:red;
}

.item{
    pointer-events:auto;
    color: var(--blue);
    position:relative;
    width:calc(var(--width) - 2rem - calc(var(--border-width) * 2));
    height:max-content;
    overflow:hidden;
    padding:.1rem 1rem;
    padding-top:.5rem;
    border: {
        bottom: calc(var(--border-width)/2) solid var(--blue);
    }
}
.last-item {
    border-bottom:none;
}

.check {
    width:15px;
    height:10px;
    background:url('../assets/check.svg') no-repeat;
    top:50%;
    right:1rem !important;
    transform:translateY(-20%);
}

.items {
    overflow:hidden;
    position:relative;
}


/* ########## ICONS ########### */
.icon {
    position:absolute;
    right:0rem;
}
</style>