<template>
<div class="drawer" :class="{active: showInfo}">
    <div class="information comp-container flex d-col" data-inside-drawer>
        <div class="title flex gap-1">
            <div class="icon info"></div>
            <h3>{{trans('mimboard_information')}}</h3>
            <div class="icon close" @click="$emit('hide'); edit = show = false"></div>  
        </div>
        <div class="welcome">
            <span>
                {{trans('info_welcome_to_the_mimboard')}}
            </span>
        </div>
        <div class="filter">
            <h4>{{trans('info_filter_title')}}</h4>
            <span>{{trans('info_filter_description')}}</span>
            <img class="company" src="../assets/images/information/filter.svg" />
        </div>
        <div class="savings">
            <h4>{{trans('info_savings_title')}}</h4>
            <span>{{trans('info_savings_description')}}</span>
            <img class="company" src="../assets/images/information/savings.svg" />
        </div>
        <div class="achievements">
            <h4>{{trans('info_achievements_title')}}</h4>
            <span>{{trans('info_achievements_description')}}</span>
            <img class="company" src="../assets/images/information/achievements.svg" />
        </div>
        <div class="my_account">
            <h4>{{trans('info_my_account_title')}}</h4>
            <span>{{trans('info_my_account_description')}}</span>
        </div>
        <button class="btn" @click="$emit('hide')">{{trans('ok')}}</button>
    </div>
</div>
</template>

<script>
export default {
    name:'Information',
    props: ['showInfo'],
    watch: {
        showInfo(){
            if(!this.showInfo) { document.removeEventListener('click', this.hideDrawer); return}
            document.addEventListener('click', this.hideDrawer)
        }
    },
    methods: {
        hideDrawer(e){
            if(e.target.closest('[data-inside-drawer]') || e.target.closest('[data-open-info]')) return
            this.$emit('hide')
        },
    },
}
</script>

<style lang="scss" scoped>
span {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight:400;
}

img {
    display:block;
    margin-top:20px;
}

h4 {
    font-size: 16px;
    margin-bottom:9px;
    margin-top:40px;
}

.welcome {
    margin-top: 30px;
}

.drawer {
    z-index:20;
    position:absolute;
    max-height:max-content;
    width:478px;
    right:275px;
    overflow:hidden;
    input[type="text"] {
        background-color: var(--white);
        text-transform:none;
    }

    max-height:0;
    opacity:0;
    transition:100ms ease-out;
}

.active {
    opacity:1;
    max-height:1000px;
}

.editable {
    height:570px;
}

.information {
    width: 478px;
    max-height:max-content;
    box-sizing:border-box;
    padding:30px;
    border: var(--border-width) solid var(--medgray)
}

.title h3 {
    font-size: 22px;
    margin:0;
}

.account div {
    font-size:16px;
    h5 {
        color: var(--black);
        margin-top:1.5rem;
        margin-bottom: .4rem;
    }
    input {
        background:var(--lightgray);
        height:30px;
        width:100%;
    }
}

.reset-password {
    width:max-content;
    padding:0 1rem;
    display:flex;
    .icon {
        background-image: url('../assets/right-arrow.svg');
        margin-left:.5rem;
    }
}

.close {
    position:absolute;
    top:20px;
    right:20px;
    background-image: url('../assets/cross.svg');
    cursor:pointer;
}

.info {
    width:26px;
    height:26px;
    cursor:pointer;
    background-repeat:no-repeat;
    background-size:cover;
    background-image:url('../assets/images/information/icon-info.svg')
}

.btn {
    margin-top:40px;
}

.footer {
    margin-top:auto;
}
</style>

<!-- mobile-->
<style lang="scss" scoped>
@media screen and (max-width: 1128px){
    .drawer {
        position:fixed;
        left:0;
        top:0;
        right:0;
        width:100%;
        display:flex;
        justify-content: center;
        padding-top:1rem;
    }

    .account {
        position:relative;
        right:0;
        width:90%;
    }
}
</style>