<template>
<div class="drawer" :class="{active: show, editable: edit}">
    <div class="account comp-container flex d-col" data-inside-drawer  v-if="currentUser">
        <div class="title flex space-between">
            <h3>{{trans('account')}}</h3>
            <div class="icon close" @click="$emit('hide'); edit = show = false"></div>  
        </div>
        <div class="username">
            <h5>{{trans('email')}}</h5>
            <span>{{currentUser.email}}</span>
        </div>
        <div class="password">
            <h5>{{trans('password')}}</h5>
            <span v-if="!edit">***********</span>
            <button v-else class="btn reset-password items-center" @click="changeLocalPassword()" >{{trans('change_password')}}<div class="icon right-arrow"></div></button>
            <span style="color:var(--red)" v-if="pressed_change_password">{{trans('email_sent')}}</span>
        </div>
        <div class="phone">
            <h5>{{trans('phone')}}</h5>
            <span v-if="!edit">{{currentUser.phone}}</span>
            <input v-else type="text" name="username" v-model="currentUser.phone">
        </div>
        <div class="title">
            <h5>{{trans('job_title')}}</h5>
            <span v-if="!edit">{{currentUser.job_title}}</span>
            <input v-else type="text" name="username" v-model="currentUser.job_title">
        </div>
        <div class="footer flex space-between">
            <button v-if="!edit" @click="edit = true" class="btn edit">{{trans('edit')}}</button>
            <button v-else @click="edit = false; updateLocalUser()" class="btn save">{{trans('save')}}</button>
            <a  v-if="!edit" href="#" @click="logout(); show = false; $emit('hide'); edit = false" class="flex items-center">{{trans('logout')}} <div class="icon logout"></div></a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:'Account',
    data() {
        return {
            edit: false,
            pressed_change_password: false,
            currentUser: {
                userName: "",
                phone: "",
                job_title: "",
                email: ""
            },
        }
    },
    props: ['show', 'user'],
    watch: {
        show(){
            if(!this.show) { document.removeEventListener('click', this.hideDrawer); return}
            document.addEventListener('click', this.hideDrawer)
        }
    },
    methods: {
        updateLocalUser(){
            this.updateUser(this.currentUser)
            this.pressed_change_password = false
        },
        hideDrawer(e){
            if(e.target.closest('[data-inside-drawer]') || e.target.closest('[data-open-account]')) return
            this.$emit('hide')
            this.edit = false
        },
        changeLocalPassword(){
            this.forgot_password(this.currentUser.email)
            this.pressed_change_password = true
        }
    },
    mounted(){
        this.user.then(e => {
            this.currentUser.first_name = e.first_name
            this.currentUser.last_name = e.last_name
            this.currentUser.phone = e.phone
            this.currentUser.job_title = e.job_title
            this.currentUser.email = e.email
        })
    }
}
</script>

<style lang="scss" scoped>
.drawer {
    position:absolute;
    height:530px;
    width:400px;
    right:120px;
    overflow:hidden;
    z-index:20;
    input[type="text"] {
        background-color: var(--white);
        text-transform:none;
    }

    max-height:0;
    opacity:0;
    transition:100ms ease-out;
}

.active {
    opacity:1;
    max-height:1000px;
}

.editable {
    height:570px;
}

.account {
    position:absolute;
    width: 370px;
    right:1rem;
    height:520px;
    box-sizing:border-box;
    padding:30px;
    border: var(--border-width) solid var(--medgray)
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.title h3 {
    font-size: 22px;
    margin:0;
    margin-bottom:0.5rem;
}

.account div {
    font-size:16px;
    h5 {
        color: var(--black);
        margin-top:1.5rem;
        margin-bottom: .4rem;
    }
    input {
        background:var(--lightgray);
        height:30px;
        width:100%;
    }
}

.reset-password {
    width:max-content;
    padding:0 1rem;
    display:flex;
    .icon {
        background-image: url('../assets/right-arrow.svg');
        margin-left:.5rem;
    }
}

.close {
    background-image: url('../assets/cross.svg');
    cursor:pointer;
}

a {
    text-decoration: underline;
    .logout {
        background-image: url('../assets/logout.svg');
        height:25px;
        margin-left:.5rem;
    }
}

.footer {
    margin-top:auto;
}
</style>

<!-- mobile-->
<style lang="scss" scoped>
@media screen and (max-width: 1128px){
    .drawer {
        position:fixed;
        left:0;
        top:0;
        right:0;
        width:100%;
        display:flex;
        justify-content: center;
        padding-top:1rem;
    }

    .account {
        position:relative;
        right:0;
        width:90%;
    }
}
</style>