<template>
  <div>
    <!-- Un-comment to enable footer on ALL pages for mobile screens -->
    <!-- <hide-at breakpoint="mediumAndAbove">
      <div class="flex flex-col gap-4 mx-2">
        <div class="flex justify-between w-full mt-12 mb-6 gap-24">
          <div class="flex-grow">
            <h3 class="text-primary" style="height: 30px">{{trans('follow_us')}}</h3>
            <div class="flex gap-4">
              <a target="_blank" href="https://www.instagram.com/mimbly/"><i class="instagram bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
              <a target="_blank" href="https://www.facebook.com/mimbly"><i class="facebook bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
              <a target="_blank" href="https://twitter.com/mimblyswe"><i class="twitter bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
              <a target="_blank" href="https://www.linkedin.com/company/mimbly/"><i class="linkedin bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
            </div>
          </div>
        </div>
        <div class="w-full mb-6">
          <a target="_blank" href="https://www.mimbly.se/support" class="text-2xl font-bold text-primary underline block mb-6">{{trans('mimbox_support')}}</a>
          <span class="text-gray-500 font-paragraph">{{ trans('mimbox_support_description') }}</span>
        </div>
        <div>
          <h3 class="text-primary text-2xl" style="height: 30px">{{trans('feedback_and_support')}}</h3>
          <button class="btn mb-6" @click="$emit('contact_us')">{{trans('contact_us')}}</button>
          <div class="flex flex-col gap-2">
            <span class="text-gray-500 font-paragraph">Mimbly AB</span>
            <span class="text-gray-500 font-paragraph">Anders Persongatan 12</span>
            <span class="text-gray-500 font-paragraph">416 64</span>
            <span class="text-gray-500 font-paragraph">Göteborg, SE</span>
          </div>
        </div>
        <div>
          <a target="_blank" href="https://www.mimbly.se/company" class="text-2xl font-bold text-primary underline">{{trans('about_us')}}</a>
          <div class="flex flex-col gap-4 mt-6">
            <a target="_blank" href="https://www.mimbly.se/privacypolicy" class="text-gray-500 underline font-paragraph capitalize">{{trans('privacy_policy')}}</a>
            <a target="_blank" href="https://www.mimbly.se/termsandconditions" class="text-gray-500 underline font-paragraph capitalize">{{trans('terms_and_conditions')}}</a>
            <a target="_blank" href="https://www.mimbly.se/cookiepolicy" class="text-gray-500 underline font-paragraph capitalize">{{trans('cookie_policy')}}</a>
            <div class="text-sm text-gray-600">mimboard version {{version}}</div>
          </div>
        </div>
      </div>
    </hide-at> -->
    <show-at breakpoint="mediumAndAbove">
      <div class="footer">
        <div class="divider"></div>
        <div class="flex justify-between w-full mt-12 mb-6 mx-4 gap-24">
          <div class="flex-grow">
            <h3 class="text-primary" style="height: 30px">{{trans('follow_us')}}</h3>
            <div class="flex gap-2">
              <a target="_blank" href="https://www.instagram.com/mimbly/"><i class="instagram bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
              <a target="_blank" href="https://www.facebook.com/mimbly"><i class="facebook bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
              <!-- <a target="_blank" href="https://twitter.com/mimblyswe"><i class="twitter bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a> -->
              <a target="_blank" href="https://www.linkedin.com/company/mimbly/"><i class="linkedin bg-cover w-8 h-8 inline-block hover:opacity-75 transition ease-in-out duration-300"></i></a>
            </div>
          </div>
          <div class="w-64">
            <a target="_blank" href="https://www.mimbly.se/support" class="text-2xl font-bold text-primary underline block mb-6">{{trans('mimbox_support')}}</a>
            <span class="text-gray-500 font-paragraph">{{ trans('mimbox_support_description') }}</span>
          </div>
          <div>
            <h3 class="text-primary text-2xl" style="height: 30px">{{trans('feedback_and_support')}}</h3>
            <button class="btn mb-6" @click="$emit('contact_us')">{{trans('contact_us')}}</button>
            <div class="flex flex-col gap-2">
              <span class="text-gray-500 font-paragraph">Mimbly AB</span>
              <span class="text-gray-500 font-paragraph">Anders Persongatan 12</span>
              <span class="text-gray-500 font-paragraph">416 64</span>
              <span class="text-gray-500 font-paragraph">Göteborg, SE</span>
            </div>
          </div>
          <div>
            <a target="_blank" href="https://www.mimbly.se/company" class="text-2xl font-bold text-primary underline">{{trans('about_us')}}</a>
            <div class="flex flex-col gap-4 mt-6">
              <a target="_blank" href="https://www.mimbly.se/privacypolicy" class="text-gray-500 underline font-paragraph capitalize">{{trans('privacy_policy')}}</a>
              <a target="_blank" href="https://www.mimbly.se/termsandconditions" class="text-gray-500 underline font-paragraph capitalize">{{trans('terms_and_conditions')}}</a>
              <a target="_blank" href="https://www.mimbly.se/cookiepolicy" class="text-gray-500 underline font-paragraph capitalize">{{trans('cookie_policy')}}</a>
              <div class="text-sm text-gray-600">mimboard version {{version}}</div>
            </div>
          </div>
        </div>
    </div>
    </show-at>
  </div>
</template>

<script>
import { version } from '../../package.json'

export default {
  name: 'Footer',
  data() {
    return {
      version: version
    }
  }
}
</script>

<style lang="scss" scoped>
  .divider {
    width:100%;
    height:1px;
    background-color:var(--lightgray);
    margin-top:3rem;
  }

  .mimbly-logo {
    background-image:url('../../public/mimbly_logo.png');
    background-size:contain;
    width:40px;
    aspect-ratio: 1;
    margin:0 auto;
    margin-top:1rem;
  }
  .mimbly-version {
    font-size: smaller;
    width:max-content;
    margin:0 auto;
  }

  .instagram {
    background-image:url('../assets/footer_icons/instagram.svg');
  }

  .facebook {
    background-image:url('../assets/footer_icons/facebook.svg');
  }

  .twitter {
    background-image:url('../assets/footer_icons/twitter.svg');
  }

  .linkedin {
    background-image:url('../assets/footer_icons/linkedin.svg');
  }
</style>