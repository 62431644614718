<template>
    <div class="addNew comp-container" :class="{ visible: show_btns}">
        <div @click="show_btns = !show_btns; " class="label flex">
            <div class="icon add"></div>
            <h2>{{trans('add_new')}}</h2>
        </div>
        <!-- category buttons -->
        <div class="buttons">
            <button class="blue-box" v-for="(type,i) in addNewTypes" :key="type" @click="show_btns = false; (currType != i) ? currType = i : currType = null" :class="{inactive:currType == i}">{{trans(type)}}</button> 
        </div>
        <div class="background-cover" :class="{hidden:currType == null}"> <!-- hidden -->
        <!-- ACCOUNT -->
            <div class="content comp-container" v-if="currType == 0" v-click-outside="handleClose" data-validation-container>
                <div class="header flex items-center space-between">
                    <div class="flex gap-05 items-center">
                        <div class="icon add"></div>
                        <h3>{{trans('add_new_account')}}</h3>
                    </div>
                    <div class="icon close" @click="handleClose"></div>
                </div>
                <div class="name">
                    <h4>{{trans('email')}}</h4>
                    <input type="text" class="blue-box" v-model="user_data.email" data-required >
                </div>
                <div class="user-type">
                    <h4>{{trans('user_type')}}</h4>
                    <ScrollDropdown class="scroll-dropdown" :label="user_data.role || trans('user_type')" :itemsArr="possible_user_types" :selItem="[user_data.role]" @set="setUser('type', ...arguments)"/>
                </div>
                <div class="company">
                    <h4>{{trans('company')}}</h4>
                    <ScrollDropdown class="scroll-dropdown" :label="user_data.client || trans('company')" :itemsArr="possible_companies" :selItem="[user_data.client]" @set="setUser('clientName', ...arguments)"/>
                </div>
                <div v-if="isRunning" class="w-full flex justify-center">
                    <div class="w-12">
                        <Loading color="#0068FF"/>
                    </div>
                </div>
                <button class="btn" :class="{inactive: isRunning}" @click="add_user($event.target)">{{trans('invite')}}</button> 
            </div>

        <!-- MIMBOX -->
            <div class="content comp-container" v-if="currType == 1" v-click-outside="handleClose" data-validation-container>
                <div class="header flex items-center space-between">
                    <div class="flex gap-05 items-center">
                        <div class="icon add"></div>
                        <h3>{{trans('add_new_mimbox')}}</h3>
                    </div>
                    <div class="icon close" @click="handleClose"></div>
                </div>
                <div class="name">
                    <h4>{{trans('amount_of_mimboxes')}}</h4>
                    <input type="text" class="blue-box" v-model="mimbox.amount" data-required @input="handleValidation($event.target, true)">
                    <div class="creation-note">{{trans('mimbox_creation_download_note')}}</div>
                </div>
                <button 
                    @click="add_multiple_units($event.target)" 
                    class="btn create">{{trans('create')}}</button>
            </div>

        <!-- COMPANY -->
            <div class="content comp-container" v-if="currType == 2" v-click-outside="handleClose" data-validation-container>
                <div class="header flex items-center space-between">
                    <div class="flex gap-05 items-center">
                        <div class="icon add"></div>
                        <h3>{{trans('add_new_company')}}</h3>
                    </div>
                    <div class="icon close" @click="handleClose"></div>
                </div>
                <div class="company-name">
                    <h4>{{trans('company_name')}}</h4>
                    <input v-model="company.name" type="text" name="company-name" id="company-name" data-required @input="handleValidation($event.target, true)">
                </div>
                <form onsubmit="return false" class="company-info">
                    <h4>{{trans('contact_info')}}</h4>
                    <input v-model="company.data.contacts[0][key]" v-for="(item, key) in company.data.contacts[0]" :key="key + '1'" type="text" :placeholder="trans(key).replace(/^\w/, (c) => c.toUpperCase())" />
                    <h4 v-if="company.data.contacts[1]">{{trans('second_contact')}}</h4>
                    <input v-model="company.data.contacts[1][key]" v-for="(item, key) in company.data.contacts[1]" :key="key + '2'" type="text" :placeholder="trans(key).replace(/^\w/, (c) => c.toUpperCase()) + ' 2'" />
                    <div class="add-extra flex items-center" @click="additionalContact($event, 'company')">{{trans('add_extra_contact')}} <div class="icon add small"></div></div>
                </form>
                <button
                @click="addNew('company',$event.target)"
                class="btn create">{{trans('create')}}</button>
            </div>
        
        <!-- SUB COMPANY -->
            <div class="content comp-container" v-if="currType == 4" ref="subCompanyContactForm">
                <div class="header flex items-center space-between">
                    <div class="flex gap-05 items-center">
                        <div class="icon add"></div>
                        <h3>{{trans('add_new_sub_company')}}</h3>
                    </div>
                    <div class="icon close" @click="currType = null; resetData(sub_company, 'contact')"></div>
                </div>
                <div class="sub-company-name">
                    <h4>{{trans('sub_company_name')}}</h4>
                    <input v-model="sub_company.name" type="text" name="sub-company-name" id="sub-company-name">
                    <div ref="errorNote" class="error-note">{{trans('please_input_user_email')}}</div>
                </div>
                <form onsubmit="return false" class="sub-company-info">
                    <h4>{{trans('contact_info')}}</h4>
                    <input v-model="sub_company.data.contacts[0][key]" v-for="(item, key) in sub_company.data.contacts[0]" :key="key + '1'" type="text" :placeholder="trans(key).replace(/^\w/, (c) => c.toUpperCase())" />
                    <h4 v-if="sub_company.data.contacts[1]">{{trans('second_contact')}}</h4>
                    <input v-model="sub_company.data.contacts[1][key]" v-for="(item, key) in sub_company.data.contacts[1]" :key="key + '2'" type="text" :placeholder="trans(key).replace(/^\w/, (c) => c.toUpperCase()) + ' 2'" />
                    <div class="add-extra flex items-center" @click="additionalContact($event, 'sub_company')">{{trans('add_extra_contact')}} <div class="icon add small"></div></div>
                </form>
                <button
                @click="addNew('sub_company')"
                class="btn create">{{trans('create')}}</button>
            </div>

        <!-- Site -->
            <div class="content comp-container" v-if="currType == 5">
                <div class="header flex items-center space-between">
                    <div class="flex gap-05 items-center">
                        <div class="icon add"></div>
                        <h3>{{trans('add_new_site')}}</h3>
                    </div>
                    <div class="icon close" @click="currType = null"></div>
                </div>
                <div class="site-name">
                    <h4>{{trans('site_name')}}</h4>
                    <input type="text" class="blue-box">
                </div>
                <div class="adress">
                    <h4>{{trans('adress')}}</h4>
                    <input type="text" class="blue-box">
                </div>
                <div class="city">
                    <h4>{{trans('city')}}</h4>
                    <input type="text" class="blue-box">
                </div>
                <form onsubmit="return false" class="company-info" datatype="site">
                </form>
                <button class="btn create">{{trans('create')}}</button>
            </div>

        <!-- Technician -->
            <div class="content comp-container" v-if="currType == 3" v-click-outside="handleClose" data-validation-container>
                <div class="header flex items-center space-between">
                    <div class="flex gap-05 items-center">
                        <div class="icon add"></div>
                        <h3>{{trans('add_new_technician')}}</h3>
                    </div>
                    <div class="icon close" @click="handleClose"></div>
                </div>
                <div class="tech-name">
                    <h4>{{trans('name_of_service_technician')}}</h4>
                    <input v-model="tech.name" type="text" class="blue-box" data-required @input="handleValidation($event.target, true)">
                </div>
                <form onsubmit="return false" class="company-info" datatype="tech"> <!-- Not sure about best way for this to work. Add extra creates a new identical form -> create button pushes makes objects of the form data and pushes to an array? -->
                    <h4>{{trans('contact_info')}}</h4>
                    <input v-model="tech.data.contacts[0][key]" v-for="(item, key) in tech.data.contacts[0]" :key="key + '1'" type="text" :placeholder="trans(key).replace(/^\w/, (c) => c.toUpperCase())" />
                    <h4 v-if="tech.data.contacts[1]">{{trans('second_contact')}}</h4>
                    <input v-model="tech.data.contacts[1][key]" v-for="(item, key) in tech.data.contacts[1]" :key="key + '2'" type="text" :placeholder="trans(key).replace(/^\w/, (c) => c.toUpperCase()) + ' 2'" />
                    <div class="add-extra flex items-center" @click="additionalContact($event, 'tech')">{{trans('add_extra_contact')}} <div class="icon add small"></div></div>
                </form>
                <button 
                @click="addNew('tech',$event.target)"
                class="btn create">{{trans('create')}}</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.no-scroll {
    position:fixed;
    overflow-y: scroll;
}
</style>

<style lang="scss" scoped>

.addNew {
    padding:1rem 1.5rem;
    height:10.5rem;
    max-height:4.5rem;
    min-height:0;
    flex-grow:0;
    overflow:hidden;
    transition:250ms ease-in-out;
}

.content::v-deep > .div-track {
    margin: 1.5rem 0;
}

.addNew.visible {
    max-height:12rem;
}

.header {
    h3 {
        margin:0;
    }
    margin-bottom:10px;
}

label {
    color:var(--black);
}

.label {
    align-items:center;
    cursor:pointer;
    h2{
        margin:0;
        margin-left:.5rem;
    }
}

.add {
    transform:scale(1.5);
}

.buttons {
    margin-top:30px;
    margin-bottom:1rem;
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:1fr 1fr;
    gap:.5rem;
    button {
        padding:.25rem .5rem;
        text-transform: capitalize;
    }
}


/* DROPDOWN SPECIFIC STYLING*/
.scroll-dropdown::v-deep *{
    --width: calc(370px - 1.5rem - 20px);
}

.scroll-dropdown {
    margin-bottom:0 !important;
}

/* ADD NEW POPUP GENERAL STYLING */

.hidden {
    visibility: hidden;
    opacity:0;
}

.close {
    z-index:5;
    position: absolute;
    top: 20px;
    right: 20px;
}

.content {
    width:370px;
    height:max-content;
    box-sizing: border-box;
    padding:30px;
    border: var(--border-width) solid var(--medgray);
    box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
    position:absolute;
    top:5rem;
    display:flex;
    flex-direction:column;
    gap:20px;
}

.dep {
    pointer-events:none;
}

h4 {
    margin-top:0;
    margin-bottom:10px;
    color: var(--black)
}

input {
    width:100%;
    height:30px;
    text-transform: none !important;
    font-size:16px;
}

.btn {
    margin-top:10px;
}

//tags

.tags {
    margin-top:1rem;
}

.tags-input::v-deep {
    margin-top:.5rem;

    .ti-input {
        border: var(--border-width) solid var(--lightgray);
        border-radius: var(--border-radius)
    }
    .ti-new-tag-input {
        padding-left:.5rem;
        width: 7rem;
        min-width:7rem;
        box-sizing:border-box;
        text-transform:none;
        flex-grow:0;
        font-size:16px;
    }

    .ti-tags {
        align-items:center;
    }

    .ti-tag {
        border-radius: var(--border-radius);
        height: var(--filter-height);
        min-width:7rem;
        width:auto;
        max-width:10rem;
        overflow:hidden;
        background-color: var(--white);
        border: var(--border-width) solid var(--lightgray);
        color: var(--blue);
        justify-content: space-between;
        font-size:16px;
        font-weight:500;
    }

    .ti-deletion-mark {
        background-color: var(--red);
        color: var(--black);
        border-color: var(--black)
    }
}

/* Company, Technician */

form {
    position: relative;
    margin-bottom:2rem;
    display:flex;
    flex-direction:column;
    gap:10px;
}

.add-extra {
    font-size:14px;
    color: var(--blue);
    position:absolute;
    right:0;
    bottom:-2rem;
    cursor:pointer;
}

.add.small {
    transform:scale(.7);
    margin-left:.5rem;
    margin-right:0;
    display:inline-block;
}

/* Mimbox */
.creation-note {
    margin-top:10px;
}
</style>

<script>
// import VueTagsInput from '@johmun/vue-tags-input';
import * as ev from 'email-validator';
const ObjectsToCsv = require('objects-to-csv');
import Loading from '../Loading.vue';
export default{
    name: "addNew",
    components: {
        // VueTagsInput,
        Loading
    },
    data() {
        return {
            isRunning: false,
            possible_user_types: ['admin', 'customer', 'craftsman', 'manufacturer'],
            // possible_companies: [],
            regex: {
                email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
            },
            tag: '',
            addNewTypes: ["add_account", "mimbox", "company", "technician"], //site
            currType: undefined,
            mimbox: this.initialMimboxData(),
            company: this.initialContactData(),
            sub_company: this.initialContactData(),
            tech: this.initialContactData(),
            show_btns: false,
            client_name: "",
            client_type: "",
            user_data: this.initialUserData(),
        };
    },
    props: ["possible_company_names", "possible_companies"],
    watch: {
        currType(){
            if(this.currType === undefined) document.querySelector('body').classList.remove('no-scroll')
            else document.querySelector('body').classList.add('no-scroll')
        },
    },
    async mounted(){
        this.mimbox.dependencies[0].options = await this.getTechList()
        this.mimbox.dependencies[1].options = await this.getClientList()
    },
    methods: {
        handleClose(){
            if(this.currType === 0) this.currType = undefined; this.resetData(this.user_data, 'user'); this.client_type = ''; this.client_name = ''
            if(this.currType === 1) this.currType = undefined; this.resetData(this.mimbox, 'mimbox')
            if(this.currType === 2) this.currType = undefined; this.resetData(this.company, 'contact')
            if(this.currType === 3) this.currType = undefined; this.resetData(this.tech, 'contact')
        },
        async download(data){
            const csv = new ObjectsToCsv(data);
            let a = await csv.toString();
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(a));
            element.setAttribute('download', `created.csv`);   
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        async add_multiple_units(target){
            if(!this.mimbox.amount || /([A-z])/g.test(this.mimbox.amount)) {this.handleValidation(target); return}
            let list = await this.addMultipleUnits(this.mimbox.amount || 0)
            await this.download(list)
        },
        async add_unit() {
            let m = this.mimbox
            if(m.name == '') { this.showErrorNote(); return }
            let tags = []
            m.tags.forEach((e) => tags.push(e.text))
            let API_KEY = "asdasasd"; // MUST BE SET!
            let mimbox_data = {
                name: m.name,
                tags: tags,
                address: m.address,
                technician: m.dependencies[0].set[0],
                company: m.dependencies[1].set[0],
                access: ["mimbly", m.dependencies[1].set[0]]
            }
            await this.addNewUnit(mimbox_data, API_KEY);
            this.resetData(this.mimbox, 'mimbox') //resets the form, not the mimbox !!
            this.currType = undefined;
        },
        async addNew(type, target) {
            if(this[type].name == '') { this.handleValidation(target); return }
            if(type == 'company') await this.addNewClient(this.company.name, this.company.data, 'companies');
            if(type == 'tech') await this.addNewClient(this.tech.name, this.tech.data, 'technicians');

            this.currType = undefined
            this.resetData(this[type], 'contact')
        },
        async resetData(obj, type){
            if(type === 'user'){
                Object.assign(obj, this.initialUserData())
            }
            if(type == 'contact') {
                Object.assign(obj, this.initialContactData())
                this.mimbox.dependencies[0].options = await this.getTechList()
                this.mimbox.dependencies[1].options = await this.getClientList()
            }
            if(type == 'mimbox' ) Object.assign(obj, this.initialMimboxData())
        },
        async add_user(target) {
            let ud = {
                email: await this.user_data.email,
                role: await this.user_data.role,
                client: await this.user_data.client
            }
            if (!ud.email || !ev.validate(ud.email)) { this.toggleErrorClass(target, true); return}
            this.toggleErrorClass(target, false)
            const doesUserExist = await this.getUser(ud.email)
            if(doesUserExist === 'exists') { alert('this user already exists'); this.running = false; this.handleClose(); return}
            if(this.isRunning) { alert('already running, please wait.'); this.handleClose(); return}
            this.isRunning = true
            ud.email = ud.email.replace(/\s/g, '')
            let link = await this.invite(ud);
            if(!link) {this.isRunning = false; console.error('something went wrong'); return}
            this.isRunning = false
            this.handleClose()
            this.resetData(ud, "user")
        },
        setMimbox(section, item) {
            let index = this.mimbox.dependencies.indexOf(section);
            this.mimbox.dependencies[index].set = [item];
        },
        setUser(field, item) {
            if (field == "clientName") this.user_data.client = item;
            if (field == "type") this.user_data.role = item;
        },
        setCompany(item) {
            let set = this.company_dependencies.set
            if(set.includes(item)) set.splice(set.indexOf(item),1)
            else set.push(item)
        },
        setMultiple(type, section,item) {
            let index = this[type].indexOf(section);
            let set = this[type][index].set
            if(section.key == 'Mimboxes') {
                if(set.includes(item)) set.splice(set.indexOf(item),1)
                else set.push(item)
            } else {
                this[type][index].set = item
            }
        },
        additionalContact(e, type){
            const ref = e.target.closest('form');
            this[type].data.contacts.push({
                name: '',
                phone: '',
                email: '',
                job_title: ''
            })
            //hide add extra button
            ref.parentNode.querySelector('.add-extra').style.cssText = 'display:none'
        }
    },
}
</script>