<template>
    <div>
    <div class="remote-commands comp-container flex d-col" :class="{show: open}">
        <div @click="open = !open" class="label flex">
            <div class="icon settings"></div>
            <h3 class="flex items-center">{{trans('remote_commands')}}</h3>
        </div>
        <div class="buttons flex d-col gap-05">
            <div class="blue-box" @click="sms_func('calibrate')">{{trans('calibrate')}}</div>
            <div class="blue-box" @click="sms_func('drain')">{{trans('drain')}}</div>
            <div class="blue-box" @click="sms_func('restart')">{{trans('restart')}}</div>
        </div>
    </div>
    <div v-if="popup" class="comp-container popup" :class="{show_popup: popup}" @click="open = !open">
          <div class="label flex">
              <h3>{{trans('remote_command_sent')}}</h3>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RemoteCommands',
    data(){
        return {
            open: false,
            popup: false
        }
    },
    methods: {
        async sms_func(message){
            this.popup = true
            setTimeout(() => {
                this.popup = false;
            }, 750)
            await this.send_sms(this.$route.params.unit_id, message);
        }
    }
}
</script>

<style lang="scss" scoped>
.comp-container {
    box-sizing:border-box;
    padding:20px;
    margin-top:1.5rem;
    cursor:pointer;
    position: relative;
    overflow:hidden;
    box-sizing:border-box;
    transition:250ms ease-in-out;
}

.popup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -25%);
    bottom: 10%;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    opacity:0;
    border: 1px solid var(--blue);
    transition: 500ms ease-in-out;
}

.show_popup {
    opacity:1;
}

.show {
    max-height:200px;
}

h3 {
    margin:0;
    margin-left:1rem;
}

 .add {
     transform:scale(1.5)
 }

 .buttons {
     margin-top:1.5rem;
 }

 .blue-box {
     text-transform:none;
     padding-left:.5rem;
     font-family: 'Source sans pro'
 }

 .settings {
     background-image:url('../../assets/settings.svg');
     background-size:cover;
     width:27px;
 }
</style>