<template>
<div class="performance" style="aspect-ratio: 1">
    <h3>{{trans('performance')}}</h3>
    <div class="border-2 border-mediumGray border-solid rounded-mimbly flex justify-center items-center overflow-hidden" style="aspect-ratio: 1">
        <div class="h-1/4 w-1/4" v-if="loading">
            <Loading color="#0068FF" />
        </div>
        <apexchart v-if="!loading" width="100%" type="donut" :options="chartOptions" :series="series"></apexchart>
    </div>
</div>
</template>

<script>
import Loading from '../Loading.vue';

export default {
    name: 'Performance',
    components: {
        Loading
    },
    props: ['perfUnits'],
    watch: {
        perfUnits(){
            this.initChart(this.perfUnits)
        }
    },
    methods: {
        initChart(units){
        this.chartOptions.labels = [this.trans("water_used"), this.trans("water_saved")]
        const recycled_water = units.reduce((acc, curr) => acc + (curr?.data?.water_saved ? Number(curr.data.water_saved) : 0), 0)
        const water_used = units.reduce((acc, curr) => acc + (curr?.data?.water_used ? Number(curr.data.water_used) : 0), 0)
        this.series = [water_used, recycled_water]
        this.loading = false
        }
    },
    data() {
        return {
            loading: true,
            series: [],
            general: {},
            chartOptions: {
                colors: ['#98B8DE', '#96CFB4'],
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    background: {
                        enabled: true,
                        foreColor: '#4A4A4A',
                        borderColor: '#4A4A4A',
                        dropShadow: {
                            enabled: false
                        }
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    show: true,
                                    color: '#96CFB4',
                                    formatter: w => 
                                        w.globals.seriesTotals.reduce((a, b) => a + b, 0).toLocaleString() + ' l'
                                },
                                show: true,
                                fontFamily: "Montserrat",
                                value: {
                                    formatter: val => parseInt(val).toLocaleString() + ' l'
                                },
                            }
                        }
                    }
                },
                labels: [],
            }
        }
    },
    async mounted(){
        // if(!localStorage.getItem('perf_data')?.general) {
        //     await new Promise(res => setTimeout(() => res, 5000))
        // }
        // this.chartOptions.labels = [this.trans("water_used"), this.trans("recycled_water")]
        // this.general = JSON.parse(localStorage.getItem('perf_data')).general;
        // const recycled_water = this.general?.total_water_recycled
        // this.series = [this.general?.total_water_processed - recycled_water, recycled_water]
        // this.loading = false
    }
}
</script>

<style scoped>

* {
    box-sizing: border-box;
}

.comp-container.perf {
    padding:1rem;
}

.time {
    margin-bottom:.5rem;
    color:var(--blue);
    gap:.5rem;
    cursor:pointer;
}

.icon{
    width:21px;
    aspect-ratio: 1;
}

.cal {
    background-image:url('../../assets/calendar.svg')
}

#datepicker {
    margin-bottom:1rem;
}
</style>