<template>
  <div class="unit-performance">
    <h3>{{trans('performance')}}</h3>
    <div class="mb-4">
        <Calendar v-model="dateRange" :showButtonBar="true" selectionMode="range" :maxDate="new Date()" :numberOfMonths="2" />
    </div>
    <div class="content">
        <div class="item grid-center">
            <div class="inner-container flex d-col items-center">
            <div class="icon tap-water w-12 h-12"></div>
            <h3 class="info">{{perf.used}} l</h3>
            <span class="flavor">{{trans('tap')}}</span>
            </div>
        </div>
        <div class="item grid-center">
            <div class="inner-container flex d-col items-center">
            <div class="icon recycled w-12 h-12"></div>
            <h3 class="info">{{perf.saved}} l</h3>
            <span class="flavor">{{trans('recycled')}}</span>
            </div>
        </div>
        <div class="item grid-center">
            <div class="inner-container flex d-col items-center">
            <div class="icon recycled-percentage w-12 h-12" :class="{achieved: perf.achieved}"></div>
            <h3 class="info">{{perf.percentage}} %</h3>
            <span class="flavor">{{trans('recycling_rate')}}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Performance',
    props: ['unit'],
    data() {
        return {
            dateRange: null,
            perf: {
                used: 0,
                saved: 0,
                percentage: 0,
                achieved: false
            }
        }
    },
    
    watch: {
        dateRange: {
            handler: async function (newVal) {
                if(newVal === null) { this.setPerf(this.$props.unit.water_saved, this.$props.unit.water_used); return }
                if(Boolean(newVal[0]) !== Boolean(newVal[1])) return
                const range = { from: newVal[0].getTime(), to: newVal[1].getTime() }
                const { data: { water_saved, water_used } } = await this.getUnitSavingsForTime(range, this.$props.unit.muid)
                this.setPerf(water_saved, water_used)
            },
        },
        unit: function() { this.setPerf(this.$props.unit.water_saved, this.$props.unit.water_used) },
        deep:true
    },
    methods: {
        setPerf(water_saved, water_used) {
            this.perf.used = Math.round(water_used)
            this.perf.saved = Math.round(water_saved)
            this.perf.percentage = Math.round(water_saved / (water_used+water_saved) * 100);
            (this.perf.percentage > 29) ? this.perf.achieved = true : this.perf.achieved = false
        }
    }
}
</script>

<style lang="scss" scoped>
.unit-performance {
    margin-bottom:1rem;
    padding-top:1rem;
    h3 {
        text-align:left;
    }
}

.content {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:20px;
}

.item {
    box-sizing: border-box;
    height:140px;
    border: var(--border-width) solid var(--medgray);
    border-radius: 22px;
    h3 {
        margin:.5rem 0;
    }
}

.icon {
    width:45px;
}

.tap-water {
    background-image: url('../../assets/performance_icons/tap-water.svg')
}

.recycled {
    background-image: url('../../assets/performance_icons/recycled.svg')

}

.recycled-percentage {
    mask-image: url('../../assets/performance_icons/recycled-percentage.svg');
    mask-repeat: no-repeat;
    mask-size:contain;
    background-color:var(--black);
}

.recycled-percentage.achieved {
    background-color: var(--green)
}
</style>

<!-- mobile -->
<style lang="scss" scoped>
.icon {
    transform:scale(.8);
}

span {
    text-align:center;
}
</style>