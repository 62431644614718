<template>
<div class="mobile-unitFilter">
    <h3>{{trans('filter_your_selection')}}</h3>
        <hide-at breakpoint="mediumAndAbove">
        <div>
                <input v-model="searchString" type="search" name="search" class="blue-box search search-mobile" :placeholder="trans('search')">
                <div class="icon search-icon"></div>
                <div class="filter-container">
                    <!-- if a checkbox needs to show up on click, pass in an array matching the item or items in the list -->
                    <!-- <Dropdown :label="section.key" :itemsArr="section.options" :selItem="filters[section.key]" @toggleFilter="toggleFilter" /> -->
                    <div class="filter" v-for="section in possibleFilter" :key="section.key">
                        <div class="header" @click="openFilter(section.key)">{{section.key}}</div>
                        <div class="content" :ref="section.key">
                            <div class="option" v-for="option in section.options" :key="option" @click="toggleFilter(option, section.key)">{{option}}</div>
                        </div>
                    </div>
                </div>
        </div>
        </hide-at>
    
    <div class="filter-list">
        <span class="clear" v-if="Object.keys(this.filters).length > 0" @click="searchString = '';filters = {}; filterNames=[]; filter()">{{trans('clear_all')}}</span>
        <div class="filter-name flex items-center" v-for="filter in filterNames" :key="filter.name" @click="toggleFilter(filter.name, filter.section)"><div class="cross" v-if="filter"></div>{{filter.name}}</div>
    </div>
</div>
</template>

<script>

export default{
    name: "unitFilter",
    data(){
        return {
            filters:{},
            searchString: "",
            filterNames: [],
            contentVisible: {},
        }
    },
    props: ["possibleFilter"],
    watch:{
        searchString(){
            if(this.searchString){
                this.filters.search = this.searchString
            }
            else{
                delete this.filters.search
            }
            this.filter()
        },
    },
    methods:{
        filter(){
            let data = {}
                for(let key of Object.keys(this.filters)){
                    if(this.filters[key] != null){
                        if(data[key])
                            data[key].push(this.filters[key])
                        else
                            data[key] = this.filters[key]
                    }
                }
            this.$emit('filtered', data)
        },
        toggleFilter(filter, section){
            if(!filter){
                delete this.filters[section]
                this.filter()
            }
            else if((this.filters[section] && this.filters[section].includes(filter)) || section === "search"){
                if(section === "search")
                this.searchString = ""
                let index = this.filters[section].indexOf(filter)
                this.filters[section].splice(index, 1)
                if(this.filters[section].length === 0){
                    delete this.filters[section]
                }
                this.filter()
            }else if(this.filters[section]){
                this.filters[section].push(filter)
                this.filter()
            } else {
                this.filters[section] = [filter]
                this.filter()
            }
            //print names of the filters
            if(section !== 'search'){
                this.filterNames = [];
                for (let key in this.filters) {
                    this.filters[key].forEach(e => this.filterNames.push({name: e, section: key}))
                }
            }
        },
        openFilter(filter){
            Object.keys(this.contentVisible).forEach(ref => {
                if(ref !== filter) {
                this.contentVisible[ref] = false
                this.$refs[ref][0].style.maxHeight = "0px"
                }

            })
            if(!this.contentVisible[filter]) this.contentVisible[filter] = true
            else this.contentVisible[filter] = !this.contentVisible[filter]

            this.$refs[filter][0].style.maxHeight = this.contentVisible[filter] ? this.$refs[filter][0].scrollHeight + "px" : "0px"
        }
    },
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.mobile-unitFilter {
    display: flex;
    flex-direction: column;
}

.filter-container {
    display: flex;
    flex-direction: column;
    gap:  2px;
    border-radius: 10px;
    overflow:hidden;
    /* DEBUG */
    background: var(--medgray);
    border: 2px solid var(--medgray);
}

.filter {
    position: relative;
    background: var(--white);
}

.header {
    padding: 1rem;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    /* DEBUG */
    position: relative; 
}

.content {
    max-height: 0;
    transition: 250ms ease-in-out;
    overflow: hidden;
}

.option {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: .5rem 0;
    border-top: 1px solid var(--lightgray);
}

.search-mobile {
    width: 100%;
    height:2rem;
    padding: 0 0.5rem;
}
</style>
