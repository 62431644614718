<template>
    <div class="unitList">
        <editMimbox :role="role" :unit="editUnit" v-if="Object.keys(editUnit).length > 0" @hide="editUnit = {}"/>
        <div class="label flex">
            <h3>{{trans('mimbox_family')}}</h3>
        </div>
        <div class="unit-list-container comp-container">
            <!-- eslint-disable-next-line -->
            <span v-if="loading" v-for="el in [1,2,3,4,5]" :key="el" class="skeleton-loader h-10 w-full px-4 inline-block box-border" style="border-radius:10px; border:calc(var(--border-width)) solid var(--medgray);"></span>
            <div class="unit" v-for="unit in listUnits" :key="unit.id">
                <div class="unit-info" v-title="unit.data.name">
                    <router-link :to="'/unit/' + unit.id" > {{ format(unit.data.name) }} </router-link>
                    <div class="unit-alive-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="8" :fill="color(unit.data)"/>
                        </svg>
                    </div>
                    <div v-if="canEdit(unit)" class="icon edit" @click="editUnit = unit"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.no-scroll {
    position:fixed;
    overflow-y: scroll;
}
</style>

<style lang="scss" scoped>
.unitList {
    height:max-content;
    width:clamp(300px, 350px, 400px);
}

@media (max-width: 769px) {
    .unitList {
        width:100%;
    }
}

.label {
    margin-bottom:1rem;
    height:2rem;
    align-items:center;
}

h3 {
    margin-bottom:0;
}

.unit-list-container {
    display:flex;
    gap:.75rem;
    flex-direction: column;
    width:100%;
    box-sizing: border-box;
    padding:1rem;
    max-height:450px;
    overflow-y:auto;
}

.unit-list-container ::v-deep.simplebar-content {
    display: flex;
    flex-direction: column;
    gap:.75rem;
}

.unit {
    width:100%;
    box-sizing: border-box;
}

.unit-info {
    padding-left:20px;
    padding-right:12px;
    box-sizing:border-box;
    height: 40px;
    width:100%;
    border:calc(var(--border-width)) solid var(--medgray);
    color:var(--blue);
    border-radius:10px;
    display:flex;
    justify-content: center;
    align-items:center;
    overflow:hidden;
    font-weight:700;
    text-align: center;
    a {
        display:flex;
        text-decoration:none;
        color:var(--blue);
        height:100%;
        width:100%;
        place-items:center;
    }
}

.unit-alive-icon {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right:1rem
}

.icon-field {
    width:100%;
    display:flex;
    justify-content: space-between;
}

.icon {
        width:30px;
        height:30px;
        background-repeat:no-repeat;
        background-position: center;
        cursor:pointer;
    }

.remove {
    background-image:url('../../assets/remove-mimbox.svg');
}

.edit {
    background-image:url('../../assets/edit-mimbox.svg');
    width:22px;
    height:18px;
    background-size:cover;
}

.add {
    background-image:url('../../assets/add.svg'); 
    position:absolute;
    right: calc(var(--padding) * 2);
    bottom: calc(var(--padding) * 2);
}
</style>

<script>

export default{
    name: "unitList",
    props: ["possibleUnits", "filter", "role"],
    components: {
    },
    data() {
        return {
            loading: true,
            client: false,
            listUnits: [],
            editUnit: {}
        };
    },
    watch: {
        possibleUnits() {
            if (this.possibleUnits) {
                this.filter_list(this.possibleUnits.slice());
            }
        },
        filter() {
            this.filter_list(this.possibleUnits.slice());
        },
        editUnit(){
            document.querySelector('body').classList.toggle('no-scroll')
        },
        listUnits(){
            if(this.loading == false) return
            if(this.listUnits.length > 0) this.loading = false
        }
    },
    methods: {
        color({last_alive, access, install}) {
            if(access.some(a => a == 'demo_company')) return 'var(--green)'
            if(!install?.installed) return ('var(--orange)')
            else if(last_alive+(1000*60*60*2) > new Date().getTime()) return 'var(--green)'
            return 'var(--lightred)'
        },
        format(mimboxName) {
            if(!mimboxName) return ""
            if(mimboxName.length > 18) return mimboxName.slice(0, 20) + '...'
            return mimboxName
        },
        remove_unit_from_filter(unit_id, list) {
            let result = list.filter(u => u.id !== unit_id);
            this.listUnits = result;
            this.$emit("filterd", result);
        },
        filter_list(list) {
            let wm = this;
            for (let key of Object.keys(wm.filter)) {
                list = list.filter(u => {
                    if (key === "search") {
                        let matchesName = (u.data?.name ?? "").toLowerCase().includes(wm.filter.search.toLowerCase())
                        let matchesSerialNo = (u.data?.serial_nr ?? "").toString().toLowerCase().includes(wm.filter.search.toLowerCase());
                        let matchesId = (u.data?.muid ?? "").toString().toLowerCase().includes(wm.filter.search.toLowerCase())
                        if(matchesName || matchesSerialNo || matchesId) return true;
                    }
                    if (key === "tags" && u.data[key] && wm.filter[key]){
                        return wm.filter[key].some(r=> u.data[key].includes(r))
                    }
                    else
                        return wm.filter[key].includes(u.data[key]);
                });
            }
            this.listUnits = list;
            // console.log(this.$refs.unitInfoContainer[0].offsetWidth)
            this.$emit("filterd", list);
        },
        canEdit(unit){
            if(this.role == 'admin') return true
            if(this.role == 'craftsman' && unit.data.technician == this.client) return true
            if(unit.data.company == this.client) return true
            return false
        }
    },
    mounted(){
        this.client = localStorage.getItem("client")
    }
}
</script>