<template>
    <div class="flex flex-col">
        <h1 class="mb-4">Primary contact</h1>
        <div class="border-2 border-solid border-mediumGray rounded-mimbly px-4 py-2 flex flex-col gap-2 mb-4 relative">
            <input :disabled="!primaryContact.editing" v-if="primaryContact.name !== undefined" :class="primaryContact.editing ? 'text-lg border-none underline' : 'border-none text-lg cursor-text'" style="max-width: max-content" v-model="primaryContact.name" />
            <div v-else class="bg-mediumGray rounded-mimbly w-24 skeleton-loader" style="height: 22px"/>
            <div class="flex items-center gap-2">
                <div class="icon email w-6 h-6" /> <span>email: </span> 
                <input :disabled="!primaryContact.editing" v-if="primaryContact.email !== undefined" :class="primaryContact.editing ? 'border-none text-base underline flex-grow' : 'border-none text-base cursor-text flex-grow'" v-model="primaryContact.email" />
                <div v-else class="bg-mediumGray rounded-mimbly w-24 skeleton-loader" style="height: 26px"/>
            </div>
            <div class="flex items-center gap-2">
                <div class="icon phone w-6 h-6" /> <span>phone: </span>
                <input :disabled="!primaryContact.editing" v-if="primaryContact.phone !== undefined" :class="primaryContact.editing ? 'border-none text-base underline' : 'border-none text-base cursor-text'" v-model="primaryContact.phone" />
                <div v-else class="bg-mediumGray rounded-mimbly w-24 skeleton-loader" style="height: 26px"/>
            </div>
            <div v-if="client === 'mimbly' || client === unit.company">
                <!-- <button @click="send_reminder()" :class="`absolute send inline-block w-6 h-6 bg-cover ${unit.contacts?.[0].email && unit.contacts?.[0].name && !primaryContact.editing ? 'opacity-100 cursor-pointer' : 'opacity-25 cursor-default'}`" style="bottom: 1rem; right: 1rem;"></button> -->
                <div v-if="!primaryContact.editing" @click="edit('primaryContact')" class="icon edit w-6 h-6 absolute cursor-pointer" style="right: 1rem; top: 1rem;" />
                <div v-else @click="save('primaryContact')" class="icon check w-6 h-6 absolute cursor-pointer" style="right: 1rem; top: 1rem;" />
            </div>
        </div>
        <div v-if="unit.technician">
            <h1 class="mb-4">Technician - {{unit.technician}}</h1>
            <div class="border border-solid border-mediumGray rounded-mimbly px-4 py-2 flex flex-col gap-2 mb-4 relative">
                <input :disabled="!technician.editing" v-if="technician.name !== undefined" :class="technician.editing ? 'text-lg border-none underline' : 'border-none text-lg cursor-text'" style="max-width: max-content" v-model="technician.name" />
                <div v-else class="bg-mediumGray rounded-mimbly w-24 skeleton-loader" style="height: 22px"/>
                <div class="flex items-center gap-2">
                    <div class="icon email w-6 h-6" /> <span>email: </span>
                    <input :disabled="!technician.editing" v-if="technician.email !== undefined" :class="technician.editing ? 'border-none text-base underline flex-grow' : 'border-none text-base cursor-text flex-grow'" v-model="technician.email" />
                    <div v-else class="bg-mediumGray rounded-mimbly w-24 skeleton-loader" style="height: 26px"/>
                </div>
                <div class="flex items-center gap-2">
                    <div class="icon phone w-6 h-6" /> <span>phone: </span>
                    <input :disabled="!technician.editing" v-if="technician.phone !== undefined" :class="technician.editing ? 'border-none text-base underline' : 'border-none text-base cursor-text'" v-model="technician.phone" />
                    <div v-else class="bg-mediumGray rounded-mimbly w-24 skeleton-loader" style="height: 26px"/>
                </div>
                <div v-if="client === 'mimbly' || client === unit.technician">
                    <div v-if="!technician.editing" @click="edit('technician')" class="icon edit w-6 h-6 absolute cursor-pointer" style="right: 1rem; top: 1rem;" />
                    <div v-else @click="save('technician')" class="icon check w-6 h-6 absolute cursor-pointer" style="right: 1rem; top: 1rem;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export default {
    name: 'Contact',
    props: ['unit'],
    data(){
        return {
            client: '',
            technician: {
                editing: false,
                name: undefined,
                jobTitle: undefined,
                phone: undefined,
                email: undefined
            },
            primaryContact: {
                editing: false,
                name: this.unit?.contacts?.[0].name || '-',
                jobTitle: this.unit?.contacts?.[0].jobTitle || '-',
                phone: this.unit?.contacts?.[0].phone || '-',
                email: this.unit?.contacts?.[0].email || '-'
            }
        }
    },
    methods: {
        clearEditing(){
            this.technician.editing = false
            this.primaryContact.editing = false
        },
        edit(contact) {
            this.clearEditing()
            this[contact].editing = true
        },
        async save(contact) {
            this.clearEditing()
            let data = {}
            if(this[contact].name !== '-') data.name = this[contact].name
            if(this[contact].email !== '-') data.email = this[contact].email
            if(this[contact].phone !== '-') data.phone = this[contact].phone
            switch(contact) {
                case 'primaryContact':
                    await this.updatePrimaryContact(data)
                    break
                case 'technician':
                    await this.updateTechnician(data)
                    break
            }
        },
        async updatePrimaryContact(data) {
            const mimbox = firebase.firestore().collection("mimboxes").doc(this.unit.muid)
            const mimbox_data = await mimbox.get()
            let contacts_arr = mimbox_data.data().contacts || []
            contacts_arr[0] = { ...contacts_arr[0], ...data }
            mimbox.update({
                contacts: contacts_arr
            })
            return 201
        },
        async updateTechnician(data) {
            const tech_ref = await firebase.firestore().collection("technicians").doc(this.unit.technician)
            const tech_data = await tech_ref.get()
            let contacts_arr = tech_data.data().contacts || []
            contacts_arr[0] = {...contacts_arr[0], ...data}
            tech_ref.update({
                contacts: contacts_arr
            })
            return 201
        },
        async getTechnician() {
            try {
                const tech = this.unit.technician
                const tech_ref = await firebase.firestore().collection("technicians").doc(tech).get()
                const tech_data = tech_ref.data()
                this.technician.name = tech_data.contacts[0].name || '-'
                this.technician.email = tech_data.contacts[0].email || '-'
                this.technician.phone = tech_data.contacts[0].phone || '-'
            } catch(e) {
                this.technician.name = '-'
                this.technician.email = '-'
                this.technician.phone = '-'
            }
        },
        // async send_reminder() {
        //     if(!this.unit.contacts?.[0].email || !this.unit.contacts?.[0].name || this.primaryContact.editing) return
        //     const data = await this.check_reminder_email(this.unit.muid)

        //     const { installed_days, microplastic_g, name, send_to, last_sent_days } = data
        //     data.muid = this.unit.muid
        //     this.$confirm.require({
        //         message: `this contact last recieved an email about filter change ${last_sent_days} days ago, send another?`,
        //         header: 'Confirmation',
        //         icon: 'pi pi-exclamation-triangle',
        //         accept: () => {
        //             this.$confirm.require({
        //                 message: `Sending reminder email to "${name}" with email "(${send_to})", \n installed for "${installed_days}" days, "${microplastic_g}" g microplastics saved`,
        //                 header: 'Confirmation',
        //                 icon: 'pi pi-exclamation-triangle',
        //                 accept: () => {
        //                     const send_reminder_server = firebase.functions().httpsCallable("send_reminder_email")
        //                     send_reminder_server(data)
        //                 },
        //             });
        //         },
        //     })
        // },
    },
    mounted() {
        this.client = localStorage.getItem('client')
        this.getTechnician()
    },
}
</script>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input[type="text"] {
    background: none;
    color: var(--black);
    font-weight: 400;
    display: inline;
    text-transform: none;
}

.email {
    background-image: url('../../assets/email.svg');
}

.phone {
    background-image: url('../../assets/phone.svg');
}

.company {
    background-image: url('../../assets/company.svg');
}

.edit {
    background-image: url('../../assets/edit-mimbox.svg');
}

.check {
    background-image: url('../../assets/check.svg');
}

.send {
    background-image: url('../../assets/send.svg');
}
</style>