<template>
    <div class="comp-container">
        <div v-if="!created" class="flex d-col gap-1 items-center">
            <h3>{{trans('add_manufacturer_id')}}</h3>
            <input v-model="manufacturer_id" type="text" class=""/>
            <button class="btn" @click="add_new()">{{trans('add_new')}}</button>
        </div>
        <div v-if="created" class="flex d-col gap-1 items-center">
            <h3> {{trans('created_mimbox')}} id: {{created_id}} </h3>
            <vue-qrcode :value="url" :options="{ width: 200, color: { dark: '#0068FF', light:'#FFFFFF' } }"></vue-qrcode>
            <button class="btn" @click="created = !created; manufacturer_id = ''">{{trans('add_another')}}</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.comp-container {
    padding:2rem;
}

input {
    width:300px;
    text-transform: none !important;
}

label {
    color: var(--black)
}
</style>

<script>
export default{
    name: "simpleAddNew",
    data() {
        return {
            created: false,
            manufacturer_id: "",
            created_id:"",
            url:"",
        };
    },
    watch: {
    },
    methods: {
        async add_new(){
            this.created_id = await this.addNewUnit({status: "homeless", manufacturer_id: this.manufacturer_id, access:["mimbly"]}, "api_key")
            this.url = "http://localhost:8080/#/unit/" + this.created_id
            this.created = true
        }
    },
}
</script>